@media screen and (min-width: 992px) {
    .spacing-mb-lg-5 {
        margin-bottom: 5px;
    }
    
    .spacing-mb-lg-10 {
      margin-bottom: 10px;
    }
    
    .spacing-mb-lg-15 {
      margin-bottom: 15px;
    }
    
    .spacing-mb-lg-20 {
      margin-bottom: 20px;
    }
    
    .spacing-mb-lg-25 {
      margin-bottom: 25px;
    }
    
    .spacing-mb-lg-30 {
      margin-bottom: 30px;
    }

    .spacing-mb-lg-35 {
      margin-bottom: 35px;
    }
    
    .spacing-mb-lg-40 {
      margin-bottom: 40px;
    }
    
    .spacing-mb-lg-50 {
      margin-bottom: 50px;
    }
    
    .spacing-mb-lg-60 {
      margin-bottom: 60px;
    }

    .spacing-pt-lg-60 {
      padding-top: 60px;
    }

    .spacing-pb-lg-60 {
        padding-bottom: 60px;
    }
}

@media screen and (max-width: 991px) {
    .spacing-mb-mobile-5 {
        margin-bottom: 5px;
    }

    .spacing-mb-mobile-10 {
        margin-bottom: 10px;
    }

    .spacing-mb-mobile-15 {
        margin-bottom: 15px;
    }

    .spacing-mb-mobile-20 {
        margin-bottom: 20px;
    }

    .spacing-mb-mobile-30 {
        margin-bottom: 30px;
    }

    .spacing-pt-mobile-30 {
      padding-top: 30px;
    }

    .spacing-pb-mobile-30 {
        padding-bottom: 30px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .spacing-mb-tablet-5 {
    margin-bottom: 5px;
  }

  .spacing-mb-tablet-10 {
      margin-bottom: 10px;
  }

  .spacing-mb-tablet-15 {
      margin-bottom: 15px;
  }

  .spacing-mb-tablet-20 {
      margin-bottom: 20px;
  }

  .spacing-mb-tablet-30 {
      margin-bottom: 30px;
  }

  .spacing-pt-tablet-30 {
    padding-top: 30px;
  }

  .spacing-pb-tablet-30 {
      padding-bottom: 30px;
  }
}