/* sidekick desktop */
#sidekick-desktop {
  width: 180px;
  top: 180px;
  position: fixed;
  right: 0px;
  background-color: #DDD;
  z-index: 888;
  transition: right 0.35s ease;
  padding: 0;
  .sidekick {
    padding: 0px;
    margin-bottom: 0px;
    li {
      padding: 8px 0px 10px 14px;
      border-bottom: 1px solid #88cbcb;
      background-color: #01838c;
      list-style: none;
      a {
        color: #fff;
      }
    }
    li.first {
      background-color: #004651;
    }
  }
  .sidekick-glyphicon {
    margin-right: 12px;
  }
}
#sidekick-desktop.minimized {
  right: -140px;
}
.sidekick li a i.icon-mlc-document {
  font-size:18px;
}

/* sidekick mobile */
@media(max-width: @screen-xs-max) {
  #sidekick-desktop {
    display: none;
  }
}

#sidekick-mobile {
  height: auto;
  background-color: #888;
  width: 100%;
  left: 0;
  right: 0;
  top: 35px;
  .sidekick {
    li {
      width:20%;
      float:left;
      font-size: 12px;
      text-align: center;
      background-color: #888;
      a {
        .sidekick-glyphicon {
          font-size: 22px;
          width: 100%;
          padding-top: 4px;
        }
      }
    }
  }
}


#sidekick-mobile.collapse.in {
  box-shadow: 0 15px 10px -15px #666;
}
#sidekick-mobile.collapse.collapsing {
  box-shadow: 0 15px 10px -15px #666;
}