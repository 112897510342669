//Results page bold text

.wrapperResultsPageContent {
    .roman-bold-75 {
        font-size: 16px;
    }
}
//Next steps page styles

.wrapper-next-steps {
    color: #161818;

    h2 {
        margin-bottom: 20px;
        color: @primary-color-orange;
        font-family:"Charlie-Std-Regular";
    }

    .button-lg {
        min-width: 252px;
        text-align: left;
        padding-top: 11px;
        height: 44px !important;
        font-size: 18.75px;
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 0;
        margin-top: 40px;

        &:hover,
        &:focus,
        &:visited {
            background-color: #741915;
            text-decoration: underline;
        }

        &:after {
            content: "\f054";
            font-family: "Font Awesome Pro Solid";
            font-size: 16px;
            margin-left: 20px;
            display: inline-block;
        }
    }

    .wrapper-supersizer-content {
        img {
            height: 205px;
        }
    }

    .wrapperLeftCol {
        background-color: #f0eeea;
    }

    .wrapperRightCol {
        background-color: #ffffff;

        h3 {
            margin-bottom: 10px;
            font-size: 18px;
            line-height: 26px;
        }

        .rightColLinks {
            h3 {
                &:after {
                    content: "\f054";
                    font-family: "Font Awesome Pro Solid";
                    font-size: 16px;
                    margin-left: 10px;
                    display: inline-block;
                    color: @primary-color-orange;
                }

                a {
                    color: @primary-color-orange;

                    &:hover,
                    &:focus {
                        text-decoration: underline;
                        color: #741915;
                    }
                }
            }

            h3:hover{
                &:after {
                    color: #741915;
                }
            }
        }
    }
}

//Important info page

.supersizer-important-info {
    color: #161818;
    
    p,
    .roman-bold-75 {
        font-size: 16px;
    }
}
  
//Footer styles
.supersizer-footer-bg {
    background-color: #282828;
    margin-top: 50px;

    .supersizer-footer-links {
        ul {
            padding-left: 0;

            li {
                display: inline-block;
                list-style-type: none;
                padding-left: 0;
                padding-right: 12px;

                a,
                a:focus,
                a:visited,
                a:hover {
                    color: #ffffff;
                }
            }
        }
    }
}

//Media queries

@media screen and (min-width: 992px) {
    //Next steps page styles

    .wrapper-next-steps {
        background-image: linear-gradient(to left, #ffffff, #ffffff 41.3%, #f0eeea 41.3%);
        background-image: -webkit-linear-gradient(to left, #ffffff, #ffffff 41.3%, #f0eeea 41.3%);
        background-image: -moz-linear-gradient(to left, #ffffff, #ffffff 41.3%, #f0eeea 41.3%);
        background-image: -linear-gradient(to left, #ffffff, #ffffff 41.3%, #f0eeea 41.3%);
        background-image: -o-linear-gradient(to left, #ffffff, #ffffff 41.3%, #f0eeea 41.3%);
        min-height: 450px;

        a[href^="tel:"] {
            color: #161818;
        }

        .wrapperLeftCol {
            height: 550px;
            padding: 60px 50px 0 0;

            h2 {
                font-size: 32px;
                line-height: 44px;
            }
        }

        .wrapperRightCol {
            padding: 60px 0 0 50px;
            height: 550px;
        }

        .wrapper-supersizer-content {
            height: 460px;
        }
    }

    //Important info page

    .supersizer-important-info {
        a[href^="tel:"] {
            color: #161818;
            cursor: not-allowed;
            pointer-events: none;
        }
    }
}

@media screen and (max-width: 991px) {
    //Next steps page styles
    .wrapper-next-steps,
    .supersizer-important-info {
        a[href^="tel:"] {
            text-decoration: underline;
        }
    }

    .supersizer-footer-bg {
        .supersizer-footer-links {
            ul {
                li {
                    display: block;
                }
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    //Next steps styles

    .wrapper-next-steps {
        .row {
            margin-left: -24px;
            margin-right: -24px;

            .wrapperLeftCol {
                padding: 40px 30px 40px 30px;
                -webkit-border-bottom-left-radius: 50px;
                -moz-border-bottom-left-radius: 50px;
                border-bottom-left-radius: 50px;
            }

            .wrapperRightCol {
                padding: 40px 30px 24px 30px;
            }

            .wrapper-supersizer-content {
                margin-bottom: 0;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    //Next steps styles

    .wrapper-next-steps {
        h2 {
            font-size: 26px;
            line-height: 36px;
        }
        
        .button-lg {
            margin-top: 10px;
        }

        .wrapperLeftCol {
            -webkit-border-bottom-left-radius: 50px;
            -moz-border-bottom-left-radius: 50px;
            border-bottom-left-radius: 50px;
            padding-top: 10px;
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 30px;            
        }

        .wrapperRightCol {
            padding: 30px 20px 30px 20px;
        }
        
          .wrapper-supersizer-content {
            height: 440px;

            br {
                display: none;
            }

            img {
                bottom: 0;
            }
          }
    }
}