.mlc-ltd-unit-prices {

     padding-top: 60px;
}

.mlc-ltd-unit-prices label {
     margin-bottom: 40px;
     margin-right: 20px;
}

@media (min-width: 768px) {
  .mlc-ltd-unit-prices .unit-prices { overflow-x: visible; }
}

.mlc-ltd-unit-prices .unit-prices .historical tr {
     display: none;
}

.mlc-ltd-unit-prices .unit-prices .historical td {
     display: none;
}

.mlc-ltd-unit-prices .unit-prices .historical.opened tr {
     display: table-row;
}

.mlc-ltd-unit-prices .unit-prices .historical.opened td {
     display: table-cell;
}

.mlc-ltd-unit-prices .unit-prices .historical .controls .input-group {
     display: block;
}

.mlc-ltd-unit-prices .unit-prices .historical .controls .input-group .dropdown-menu {
     padding-bottom: 30px;
}

.mlc-ltd-unit-prices .unit-prices .historical .controls .endDate {
     display: inline-block;
}

.mlc-ltd-unit-prices .unit-prices .historical .controls .startDate {
     display: inline-block;
}

.mlc-ltd-unit-prices .unit-prices .historical .controls .date {
     width: 100px;
}

.mlc-ltd-unit-prices .unit-prices .historical .controls .historicalButtons {
     padding-top: 25px;
}

.mlc-ltd-unit-prices .unit-prices .historical .controls .historicalButtons .displayPrices {
     margin-left: -10px;
}

.mlc-ltd-unit-prices .unit-prices .historical .historicalDataStatusMessage {
     margin-top: 20px;
}

.mlc-ltd-unit-prices label select {
     background-color: #ebe9e5;
     border: 0px none ! important;
     display: block;
     height: 43px;
     margin-top: 10px;
     padding: 0 0 0 10px !important;
}

.product-name {
     overflow: hidden;
     vertical-align: top;
}

.product-name h3 {
     float: left;
     margin-bottom: 0px;
     padding-bottom: 20px;
}

.product-name a {
     margin-top: 0px;
}

.mlc-ltd-unit-prices .unit-prices-table {
     min-width: 800px ! important;
}

.mlc-ltd-unit-prices .unit-prices-table tr.opened {
     background-color: #f6f3ef;
}

.mlc-ltd-unit-prices .unit-prices-table td .controls {
     padding-left: 0px;
     padding-right: 0px;
}

.mlc-ltd-unit-prices .input-group input.date {
     border-radius: 0px;
     height: 35px;
     padding-bottom: 6px ! important;
     padding-top: 6px ! important;
     width: 125px ! important;
}

.mlc-ltd-unit-prices .input-group-btn .btn {
     border-radius: 0px;
     color: #8c8a86;
     height: 35px;
     padding-bottom: 6px;
}

#unitPricesAccordion .panel-body .unit-prices table.unit-prices-table tr.opened .historicalButtons .displayPrices {
     background-color: #c95109;
     border-radius: 0px;
     border: 0px none;
     color: #ffffff;
     outline: 0px none ! important;
     padding-bottom: 8px;
     padding-top: 7px;
     position: absolute;
     right: 60%;
     top: 25px;
}

#unitPricesAccordion .panel-body .unit-prices table.unit-prices-table tr.opened .historicalButtons .btn-link {
     color: #797875;
     margin-left: 20px;
     position: absolute;
     top: -5px;
}

.historicalButtons .btn-link {
     color: #797875;
     margin-left: 20px;
     position: absolute;
     top: -5px;
}

.form-group {
     margin-bottom: 20px ! important;
}

.startDate label, .endDate label {
     margin-bottom: 5px;
     margin-right: 0px;
}

.startDate .dropdown-menu, .endDate .dropdown-menu {
     padding-left: 0px;
     width: 290px;
}

.btn-link {
     outline: 0px none ! important;
}

.dropdown-menu .btn-sm {
     outline: 0px none ! important;
}

#unitPricesAccordion .panel-body .unit-prices table.unit-prices-table tr.opened .dropdown-menu {
  position:absolute !important;
}

#unitPricesAccordion .panel-body .unit-prices table.unit-prices-table tr.opened ul.dropdown-menu li table td {

  padding-top:10px;
  padding-bottom:10px;
}

.historical table tr td {
  text-align:center;
}

.historical.opened table tr td thead td {
  width:initial;
}

.historical.opened table tr td {
  width:33%;
}

@media screen and (min-width:992px) and (max-width:1199px){
 #unitPricesAccordion .panel-body .unit-prices table.unit-prices-table tr.opened .historicalButtons .displayPrices {
   top:26px;
   left:5px;
   padding-top:7px;
   padding-bottom:6px;
   margin-left:0;
 }

  #unitPricesAccordion .panel-body .unit-prices table.unit-prices-table tr.opened .historicalButtons .btn-link {
    top:-5px;
    left:70%;
    margin-left:0;
  }

.mlc-ltd-unit-prices .input-group input.date {
  width: 120px !important;
}

}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .mlc-ltd-unit-prices label { width: 45%; margin-right: 20px ! important; }
  .mlc-ltd-unit-prices label select { width: 100%; }
  .mlc-ltd-unit-prices .unit-prices-table {
     min-width: 790px ! important;
}
  .reinvestmentLink { float: left ! important; margin-bottom: 20px; clear: both; }
  .unit-prices-table label { float: none ! important; width: 100% ! important; }
   #unitPricesAccordion .panel-body .unit-prices table.unit-prices-table tr.opened .historicalButtons .displayPrices {
     padding-bottom: 6px;
     padding-top: 7px;
     right: 100px;
     top: 26px;
}

  #unitPricesAccordion .panel-body .unit-prices table.unit-prices-table tr.opened .historicalButtons .btn-link {
      margin-left: 0px;
      top: -5px;
      right:45%
}
}

@media screen and (min-width: 601px) and (max-width: 767px) {
  .mlc-ltd-unit-prices label {
    width:45%;
  }
  .mlc-ltd-unit-prices label select { width: 100% ! important; }
  #unitPricesAccordion .panel-body .table-responsive {
     overflow-y:visible !important;
   }
  .reinvestmentLink { float: left ! important; margin-bottom: 20px; clear: both; }
  .input-group-btn .btn { padding-bottom: 5px ! important; }
  #unitPricesAccordion .panel-body .unit-prices table.unit-prices-table tr.opened .historicalButtons .displayPrices {
     padding-bottom: 6px;
     padding-top: 7px;
     right: 90px;
     top: 26px;
}

  #unitPricesAccordion .panel-body .unit-prices table.unit-prices-table tr.opened .historicalButtons .btn-link {
      margin-left: 0px;
      top: -5px;
      right:37%
}
}

@media screen and (max-width: 600px) {

  .mlc-ltd-unit-prices label {
    width:100%;
  }

  #unitPricesAccordion .panel-body .table-responsive {
    overflow-y:visible !important;
  }
  .mlc-ltd-unit-prices label select { width: 100% ! important; }
  .reinvestmentLink { float: left ! important; margin-bottom: 20px; clear: both; }
  #unitPricesAccordion .panel-body .unit-prices table.unit-prices-table tr.opened .historicalButtons .displayPrices { top: 26px; right: 90px; }
  #unitPricesAccordion .panel-body .unit-prices table.unit-prices-table tr.opened .historicalButtons .btn-link { right: 37%;}
}

/*UNIT Prices */

/*the calendar */
.unit-prices table.unit-prices-table tr.opened .dropdown-menu li {padding:10px }


/*more padding in the buttons in calendar */
.unit-prices table.unit-prices-table tr.opened .dropdown-menu .btn-sm, .btn-group-sm>.btn {padding:5px; border-radius:0}

/*button today */
.unit-prices table.unit-prices-table tr.opened .dropdown-menu .btn-info {background-color:#017780; border-color:#017780; padding-left:10px; padding-right:10px}
.unit-prices table.unit-prices-table tr.opened .dropdown-menu .btn-info .text-info {color:#fff}

/*button clear */
.unit-prices table.unit-prices-table tr.opened .dropdown-menu .btn-danger {background-color:#473f3a; border-color:#473f3a; margin-left:5px; padding-left:10px; padding-right:10px}

/*button done */
.unit-prices table.unit-prices-table tr.opened .dropdown-menu .btn-success {background-color:#c95109; border-color:#c95109; margin-left:5px; padding-left:10px; padding-right:10px}

/*disabled dates */
.unit-prices table.unit-prices-table tr.opened .dropdown-menu .btn.disabled, .btn[disabled] {opacity:0.3}

/*width of the calendar start and end date */
.unit-prices table.unit-prices-table tr.opened .startDate .dropdown-menu,
.unit-prices table.unit-prices-table tr.opened .endDate .dropdown-menu {width:320px}

/*make same width each of the date button */
.unit-prices table.unit-prices-table .historical.opened table tr td {width:50px}



@media screen and (min-width: 993px) {
/*fixc conflict with dropdown megamenu full width*/
.unit-prices table.unit-prices-table tr.opened .dropdown-menu {padding:0; margin:0}

}


@media screen and (max-width: 991px) and (min-width: 768px) {
/*ajdust the fix price table to match with accordion*/
.mlc-ltd-unit-prices .unit-prices-table {
  min-width: 700px !important;
}

}


@media screen and (min-width: 768px) and (max-width: 1999px) {

/*make the date field wider */
.mlc-ltd-unit-prices .unit-prices-table .col-sm-4{width:200px}

/*make the div button price history wider*/
.mlc-ltd-unit-prices .unit-prices-table .col-sm-4:last-child {width:120px;}


}

/*position price history button*/
#unitPricesAccordion .panel-body .unit-prices table.unit-prices-table tr.opened .historicalButtons .displayPrices
 {right:0px}

/*position price close button*/
 #unitPricesAccordion .panel-body .unit-prices table.unit-prices-table tr.opened .historicalButtons .btn-link { right :-10px}


@media screen and (max-width: 768px) {

/*position price history button*/
#unitPricesAccordion .panel-body .unit-prices table.unit-prices-table tr.opened .historicalButtons .displayPrices
 {right:80px}

/*position price close button*/
 #unitPricesAccordion .panel-body .unit-prices table.unit-prices-table tr.opened .historicalButtons .btn-link { right :70px}

}