
.business-theme .carousel-content-list-wrapper .carousel-caption h4 {
  font-family: 'Charlie-Std-SemiBold-Italic';
}
.business-theme .channel-info-directive.first {
  border-right:1px solid #666;
}
.business-theme .channel-cta-wrapper {
  background-color: #FDFBF7;
}

.business-info-directive-wrapper {
  background-color:#473f3a !important;
}

  .business-info-directive-wrapper .channel-info-directive.first {
    border-right:1px solid #7a7373;
  }