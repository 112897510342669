/* desktop */

.content-tabs-center-inline {
  .nav {
    display: flex;
    text-align: center;
    border-bottom: 0;
  }

  .nav.nav-tabs>li, 
  .nav.nav-tabs>li>*{
    background-color: transparent !important;
  }

  .nav.nav-tabs>li, 
  .nav.nav-tabs>li.active {
    flex: 1;
    float: none;
    border: none;
  }

  .nav.nav-tabs>li>a {
    border-bottom: 5px solid transparent !important;
    padding-bottom: 5px;
    color: @default-color;
    margin: 0 !important;
  }

  .nav.nav-tabs>li.active>a {
    border-color: #ddd;
  }

  &.content-tabs-center-inline-h1 {
    .nav.nav-tabs>li>a {
      .h1;
    }
  }

  &.content-tabs-center-inline-h2 {
    .nav.nav-tabs>li>a {
      .h2;
      color: @grey-inactive;
      display: inline-block;
      border-bottom-width: 2px !important;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.content-tabs-center-inline-h3 {
    .nav.nav-tabs>li>a {
      .h3;
    }
  }

  &.content-tabs-center-inline-h4 {
    .nav.nav-tabs>li>a {
      .h4;
    }
  }

  &.content-tabs-center-inline-h5 {
    .nav.nav-tabs>li>a {
      .h5;
    }
  }

  &.content-tabs-center-inline-h6 {
    .nav.nav-tabs>li>a {
      .h6;
    }
  }

  &.content-tabs-center-inline-orange {
    .nav.nav-tabs>li.active>a {
      color: @primary-color-orange;
      border-color: @primary-color-orange !important;
    }
  }

  &.content-tabs-center-inline-aqua {
    .nav.nav-tabs>li.active>a {
      color: @primary-color-aqua;
      border-color: @primary-color-aqua !important;
    }
  }

  &.content-tabs-center-inline-rubine {
    .nav.nav-tabs>li.active>a {
      color: @primary-color-rubine;
      border-color: @primary-color-rubine !important;
    }
  }
}


/* mobile */
@media screen and (max-width: 992px) {
  .content-tabs-center-inline {
    .nav {
      display: block;
    }

    .nav.nav-tabs>li, .nav.nav-tabs>li.active {
      display: block;
      float: left;
      flex: 0;
    }

    .nav.nav-tabs>li:last-of-type {
      padding-bottom: 10px;
    }

    .nav.nav-tabs>li>a {
      display: inline-block;
      margin-bottom: 5px;
    }
  }
}

