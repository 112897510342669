@import "../mixins.less";

// Media queries for Login + change password + set password + Badge sites

  @media screen and (min-width:768px) and (max-width:1199px) {
    .wrapper-change-pwd-success {
      min-height:400px !important;
    }
  }

  @media screen and (min-width:768px) and (max-width:991px) {
   .login-wrapper {
     background-position:67% 0;
   }

   .logout-page .wrapper-logout-content {
     padding-top:10px;
     padding-bottom:10px;
   }
 }

 @media screen and (max-width: 767px) {
   .container_content {
     max-width: 100%;
   }

   .login-wrapper {
     background-image: none ! important;
     background-color: #faf8f4 ! important;
     padding-left:5px;
   }

   .login-page .help-content .col-custom {
     width: 35%;
     padding-left: 20px;
   }

   //Logout page

   .wrapper-logout-content h3 {
     margin-bottom:20px;
     padding-top:20px;
   }

   .logout-page .wrapper-logout-content {
     padding-top:0;
     padding-bottom:0;
   }

   .wrapper-logout-content span.button-md {
     position:relative;
     top:0px;
     padding-top: 4px !important;
   }

   .wrapper-logout-content .col-custom {
     width: 60%;
   }

   .wrapper-logout-content .wrapper-login-btn {
     width:35%
   }

   //Forgotten password page

   .forgotten-password-help p {
     width:100%;
   }

   // Change password success page

   .wrapper-change-pwd-success {
     margin-top:-40px;
     padding-top:40px;
     min-height:200px !important;
   }

   //Bagde sites

   .dpm,
   .enevita,
   .blueprint,
   .fc-one,
   .portfolio-focus,
   .premium-choice,
   .st-andrews,
   .top-200 {
     .wrapper-subheader {
       padding-left:5px;
       padding-top:10px;
       padding-bottom:10px;
     }

     .help-content {
       margin-bottom:0px;
     }

     .help-content .col-custom {
       width: 39%;
       padding-left: 20px;
     }

     .badge-page-footer {
       padding-bottom:0;
     }

     .mlc-ltd-set-password .help-content {
       padding:30px 20px 10px;
       margin-right:10px;
     }
     .top-200-heading h2  {
       margin-bottom:0;
       width:85%;
       top:4px;
     }
     .reset-password-success {
       margin-bottom:20px;
     }

     .wrapper-change-pwd-success .button_primary {
       min-width:150px;
     }
   }
 }

 @media screen and (max-width: 599px) {
   .container_content {
     max-width: 100%;
     padding-right: 10px;
   }

   .mlc-page-header {
     padding-top: 2px;
     margin-bottom: 20px;
   }

   .mlc-ltd-set-password {
     margin-top: 20px;
   }

   .wrapper-logout-content h3 {
     text-align:left !important;
     margin-bottom:20px;
   }

   .logout-page .wrapper-logout-content {
     padding-top:0;
     padding-bottom:0;
     padding-left:10px;
   }

   //Change password page success

   .wealth-change-password {
     .wealth-page-header {
       padding-top: 2px;
       margin-bottom: 20px;
       padding-bottom:0;
     }


     .wealth-page-footer ul {
       float:left;
       padding-left:0;
       li {
         display:block !important;

       }
     }
   }

   .wrapper-change-pwd-success {
     margin-top:-20px;
   }

   //Forgotten password page

   .forgotten-password-page {
     width:100%;
     padding-right:15px;
     padding-left:15px;
   }

   .dpm,
   .enevita,
   .blueprint,
   .fc-one,
   .portfolio-focus,
   .premium-choice,
   .st-andrews,
   .top-200 {
     .badge-page-header {
       padding-top:2px;
       font-size:26px;
       padding-bottom:0;
     }
   }

   .login-page .help-content .col-custom {
     width:100%;
     padding-left:10px;
   }

   .dpm,
   .dpm,
   .enevita,
   .blueprint,
   .fc-one,
   .portfolio-focus,
   .premium-choice,
   .st-andrews,
   .top-200 {
     .login-container {
       padding-top:20px;
     }
   }

   .dpm,
   .enevita,
   .blueprint,
   .fc-one,
   .portfolio-focus,
   .premium-choice,
   .st-andrews,
   .top-200 {
     .help-content .col-custom {
        width: 100%;
        padding-left: 10px;
     }
   }

   .dpm,
   .enevita,
   .blueprint,
   .fc-one,
   .portfolio-focus,
   .premium-choice,
   .st-andrews,
   .top-200 {
     .help-content p {
       text-align: left ! important;
     }
   }

   .top-200-heading h2 {
     display:none;
   }

   .badge-page-footer ul li {
     display: block ! important;
   }
 }