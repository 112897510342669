.yamm-fw {
  .dropdown-menu {
    padding: 20px 20px 40px 20px;
    background-color: #faf9f7;
    margin-top: 0 !important;
    position: absolute;
    left: 0; /* z-index: 5; */
    border-top: 0;
    border: 0px solid rgba(0, 0, 0, .15);
    border-radius: 0px;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, .175);
    .megamenu-cta {
      margin-left: 20px;
      width: 250px;
      padding: 20px;
      background-color: @primary-color-aqua;
      color: #fff;
      h4 {
        font-size: 22px;
      }
      span.button-md {
        margin-top: 0;
        margin-left: 0;
        background-color: #fff;
        color: #004f59;
      }
      a {
        color: #fff !important;
        border-bottom: 0;
        font-size: 15px !important;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .glyphicon {
      color: @primary-color-aqua;
    }
    h5 {
      border-bottom: 0px #d1d3d4 solid;
      font-family: @font-family-heading;
      color: #bc4401;
    }
    ul {
      padding-left: 0;
      li {
        list-style: none;
        &:last-child {
          border-bottom: 0px #d1d3d4 solid;
        }
        a {
          display: block;
          list-style: none;
          font-size: 14px !important;
          font-family: @font-family-sans-serif !important;
          color: #444 !important;
          padding: 7px 0 !important;
          border-bottom: 1px #d1d3d4 solid;
          text-decoration: none;
          height: auto !important;
        }
      }
    }

  }
}

@media screen and (min-width: 993px) {
  .megamenu-primary-nav li {
    margin-bottom: -2px;
  }

  /* make the col to fit 4 cols in this media querie */
  .megamenu-col.col-sm-3 {
    width: 23%;
  }

  /* full width megamenu */
  .yamm-fw {
    .dropdown-menu {
      padding: 20px 1000em 30px 1000em;
      margin: 0 -1000em;
    }
  }
}

@media screen and (max-width: 992px) {

  /*adjust the height of the teal header left panel */
  .navbar-offcanvas.navbar-offcanvas-touch .navbar-header {
    height: 55px
  }

  /*hide cta in this media querie*/
  .megamenu-col:first-child {
    display: none;
  }

  .yamm-fw {
    .dropdown-menu h5 {
      margin-left: 15px;
    }
  }

  /*put hamburger icon more clickable in this media querie */
  .navbar-toggle {
    z-index: 999;
  }

  .navbar-brand {
    z-index: 999;
    height: inherit
  }

  .visible-xs .site-select-nav li {
    background-color: #faf8f4 !important;
  }

  #topnav-mobile .site-select-nav li a {
    color: #3b3b3b;
    border: 0;
  }

  .visible-xs .site-select-nav li a:hover {
    color: #017780 !important;
    background-color: #f0eeea !important
  }

  #topnav-mobile .site-select-nav li.active a, #topnav-mobile .site-select-nav li.active a:hover {
    background-color: #00828d !important;
    color: #fff !important
  }

  nav.navbar-default .navbar-nav {
    margin-top: 0 !important;
    float: none;
  }

  nav.navbar-default {
    background-color: #faf8f4 !important
  }

  #topnav-mobile li a {
    padding: 12px 3px 12px 15px;
  }

  nav.navbar-default .navbar-nav li a {
    height: inherit
  }

  .navbar-nav {
    margin: 0 -10px;
  }

  nav.navbar-default .navbar-nav li a:hover {
    background-color: #f0eeea;
    padding: 14px 12.5px
  }

  #topnav-mobile li {
    float: none
  }

  #topnav-mobile li {
    width: 100%;
    color: #fff;
    background-color: #3b3b3b;
  }

  .nav-pills>li+li {
    margin-left: 0
  }

  .nav-pills>li>a {
    color: #fff;
  }

  .yamm-fw {
    .dropdown-menu ul li a {
      padding: 14px 14px !important;
    }
  }

  .yamm-fw {
    .dropdown-menu {
      padding: 0
    }
  }

  .row.megamenu-row {
    margin-left: 0;
    margin-right: 0
  }

  .megamenu-col.col-sm-3 {
    padding: 0
  }

  .yamm-fw {
    .dropdown-menu li {
      border: 0
    }
  }

  .navbar-default .navbar-nav>.open>a,
  .navbar-default .navbar-nav>.open>a:hover,
  .navbar-default .navbar-nav>.open>a:focus {
    height: inherit;
    padding-bottom: 10px
  }

  nav.navbar-default .navbar-nav li a:hover {
    background-color: #f0eeea !important;
  }

  .yamm-fw {
    .dropdown-menu {
      box-shadow: none;
    }
  }

}
