.forms-nav-primary {
  padding-bottom: 30px;
  padding-top: 60px;
}

.forms-nav-primary {
  padding-bottom: 30px;
  padding-top: 60px;
}

.forms-nav-secondary .nav-tabs {
  border-bottom: 3px solid #c8c6c2;
  height: 48px;
}

.forms-nav-secondary .nav-tabs li {
  background-color: #ffffff;
  border: 0px none ! important;
  margin-bottom: 0px ! important;
  position: relative;
  top: -3px;
}

.forms-nav-secondary .nav-tabs li a {
  border: 0px none ! important;
  font-size: 17px;
  margin-bottom: -1px ! important;
  margin-right: 30px;
  padding-bottom: 10px;
  padding-left: 0px;
  padding-right: 0px;
}

.forms-nav-secondary .nav-tabs li a:hover {
  background-color: #ffffff;
}

.forms-nav-secondary .nav-tabs li.active a {
  border-bottom: 5px solid #c95109 ! important;
  font-weight: bold;
  margin-bottom: -2px ! important;
}

.formsanddocs {
  padding-bottom: 30px;
  padding-top: 30px;
}

.formsanddocs .panel .list-item-text {
  padding-bottom: 18px;
}

.formsanddocs table td {
  padding-top: 10px;
  vertical-align: top;
  padding-bottom:10px;
}

.formsanddocs .icon-mlc-login-to-secure-site, .formsanddocs .icon-mlc-document {
  font-size: 20px;
  position:relative;
  top:5px;
}

.forms-search {
  background-color: #fdfbf7;
  padding-bottom: 60px;
  padding-top: 60px;
  text-align: center;
}

.forms-search h3 {
  margin-bottom: 30px;
}

.forms-search .global_search_form .glyphicon {
  background-color: #c95019;
  color: #ffffff;
  height: 50px;
  left: -5px;
  padding-top: 12px;
  position: relative;
  top: 0px;
  width: 50px;
}

.forms-search .global_search_field {
  border-radius: 0px;
  border: 1px solid #c8c6c2;
  height: 50px;
  width: 650px;
}