.mlc-ltd-find-funds {

  .fund-search-form {
    label {
      display: block;
    }
    .go {
      margin-top: 26px;
    }

  }
  .search-divider {
    padding-bottom: 10px;
  }

  .filter-search-form {
    label {
      display: block;
    }
    .reset {
      display: block;
    }
  }

  .results-text {
    font-weight: bolder;
    padding-bottom: 10px;
  }

  .results-area {
    .panel-body {
      padding: 0;
    }
  }

}

/* Find a fund */
.wrapper-find-a-fund {
  padding-top: 60px;
  padding-bottom: 10px;
}

.fund-search-form .form-control {
  border-radius: 0px;
  height: 43px ! important;
  padding: 15px 10px ! important;
}

.form-control:focus {
  border: 1px solid #c95109 ! important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px rgba(201, 81, 19, 0.6) ! important;
}

.search-divider {
  display: block;
  margin-bottom: 10px;
  margin-top: 10px;
}

.fund-search-form .btn {
  background-color: #c95109;
  border-radius: 0px;
  border: 0px none;
  padding: 0px 0px;
  width: 150px;
  height: 43px;
}

.fund-search-form .btn:hover {
  background-color: #b13900;
}

.filter-search-form .form-group select {
  display: block;
  margin-top: 10px;
  padding: 0 10px;
  width: 380px ! important;
  height: 43px;
  background-color: #ebe9e5;
  border: 0 none !important;
}

.filter-search-form .btn-link {
  float: left;
  font-size: 18px;
  position: relative;
  left: 0;
  top: 35px ! important;
}

.fund-results table.table td {
  line-height: 22px ! important;
  padding-bottom: 13px ! important;
  padding-top: 16px ! important;
  vertical-align: middle ! important;
  border: 0 !important;
}

.results-text {
  margin-bottom: 10px;
  margin-top: 10px;
}

.results-area table.table {
  margin-top: 30px;
}

#resultsBody h4.panel-title a {
  margin-top: -1px;
}

#productHeader h4.panel-title a {
  background-color: #dcdad6 ! important;
  background-image: url("/content/dam/mlc/images/icons/icon_accordion_plus.png");
  color: #3b3b3b;
}

#productHeader h4.panel-title a[aria-expanded="true"] {
  background-color: #dcdad6 ! important;
  background-image: url("/content/dam/mlc/images/icons/icon_accordion_minus_drk.png");
  color: #3b3b3b;
}

/* Finda  fund responsive styles */

@media screen and (min-width: 993px) {

  .filter-search-form .form-group {
    width: auto ! important;
    margin-right: 10px;
  }

  .filter-search-form .btn-link {
    left: 0%;
  }
}

@media screen and (max-width: 992px) {

  .fund-search-form .btn {
    margin-left: 10px;
    margin-top: 0px ! important;
  }

  .filter-search-form .form-group {
    width: 50%;
  }

  .filter-search-form .form-group select {
    width: 100% ! important;
  }

  .filter-search-form .btn-link {
    float: left;
    left: 0%;
    top: -10px ! important;
  }

  .results-text {
    width: 100% ! important;
  }
}

@media screen and (max-width: 600px) {

  .filter-search-form .form-group {
    float: none;
  }

  .filter-search-form .form-group select {
    width: 280px ! important;
  }

  .results-text {
    width: 85% ! important;
  }

  .mlc-ltd-find-funds .filter-search-form label {
    min-width: 280px;
  }
}