@import "../../variables.less";

// blog overview component

@blog-entry-bg-color: #fff;

// need to set a reasonable container height for reloads
.nabasia-blog-overview-wrapper-full {
  min-height: 1200px;
}

.nabasia-blog-overview-wrapper {

  .blog-filter-wrapper {

    .blog-filter-categories {
      margin: 10px;
    }
    .blog-filter-tags {
      margin: 10px;
    }
  }

  .blog-results-wrapper {

    .masonry-brick {
      display: none;
    }

    .masonry-brick.loaded {
      display: block;
      margin: 10px 0px 0px 0px;

      .masonry-brick-wrapper {
        margin: 10px 0px 0px 0px;
        background-color: @blog-entry-bg-color;

        .blogentry-image-wrapper {
          .blogentry-image {
            width: 100%;
          }
        }

        .blogentry-content-wrapper {
          padding: 5px;

          .blogentry-date {
            color: #6A6A6A;
            font-weight: bold;
          }

          .blogentry-body {
            max-height: 120px;
            position: relative;
            overflow: hidden;

            .blogentry-more-overlay {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              text-align: center;
              margin: 0;
              padding: 30px 0;

              /* "transparent" only works here because == rgba(0,0,0,0) */
              background-image: linear-gradient(to bottom, transparent, @blog-entry-bg-color);

              .blogentry-more-link {
                position: absolute;
                bottom: 0px;
                right: 0px;
                background-color: @blog-entry-bg-color;
                padding-left: 10px;
              }
            }
          }

          .blogentry-tags-label {
            font-weight: bold;
            padding: 7px 1px;
          }
        }
      }
    }
  }
}

// only print the modal contents of the blog overview page.
@media print {
  .blog-overview-page * {
    visibility: hidden;
  }

  .modal, .modal * {
    visibility: visible;
  }

  .modal {
    position: absolute;
    left: 0;
    top: 0;
  }
}

// fix for editmode conflict
.cq-wcm-edit .blog-filter-categories .btn-group.btn-group-justified {
  display: table;
}

.cq-wcm-edit .blog-filter-categories .btn-group {
  display: table-cell;
}

// stack the category nav on smaller screens
@media (max-width: 767px) {
  .blog-filter-categories .btn-group {
    display: initial !important;
  }
}

