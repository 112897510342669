.customer-survey {
  .modal-header {
    background-color: @primary-color-orange;
    text-align: left;
    padding: 10px 20px;

    h1 {
      font-size: 26px;
      color: @text-color-white;
      margin-bottom: 0;
      width: 75%;
    }

    .close {
      position: absolute;
      top: 15px;
      right: 20px;

      i {
        font-size: 16px;
        line-height: 1.2;
      }
    }
  }

  .modal-body {
    text-align: center;
    padding: 30px 20px 14px 20px;

    .media-body {
      display: block;
      padding: 0;
    }

    .button-md {
      margin-bottom: 0;
      height: 42px !important;
      padding-top: 10px;
      padding-left: 20px;
      padding-right: 20px;
      min-width: 0;
      font-size: 18px !important;

      &:hover {
        background-color: #741915;
        text-decoration: underline;
      }
    }

    .button-md-dismiss {
      background-color: transparent;
      color: @default-color;
      text-decoration: underline;
      height: 42px;
      padding-top: 10px;
      padding-left: 20px;
      padding-right: 20px;
      font-size: 18px;
      font-family: @font-family-heading;
      position: relative;
      top: -1px;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .modal-footer {
    border-top: 0 !important;
    padding: 0;
  }
}

//Modal accessibility styles

.customer-survey {
  .modal-header {
    .close .fa-times {
      text-decoration: underline;
    }

    .close:focus .fa-times,
    .close:hover .fa-times {
      text-decoration: none;
    }

    button:focus {
      outline: 1px dotted #3b3b3b !important;
    }
  }

  .modal-body {
    a:focus,
    a:hover {
      text-decoration: none !important;
    }

    a:focus  .button-md-dismiss {
      text-decoration: none !important;
      outline: 1px solid #3b3b3b;
    }

    a:focus .button-md {
      text-decoration: underline;
      background-color: #741915 !important;
    }
  }
}

//Spa Form Header and Body styles

.spa-forms-header,
.spa-forms-body {
  .row {
    .col-custom {
      padding-left: 0;
    }
  }
}

.spa-forms-header {
  .row {
    .col-custom {
      padding-left: 0;
    }

    .media {
      margin-bottom: 15px;
      margin-top: 15px;
    }
  }
}

.spa-forms-body {
  .row {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

//Media queries

@media screen and (max-width: 767px) {
  .customer-survey {
    .modal {
      padding-right: 0 !important;
    }

    .modal-dialog {
      margin: 40px 10px 10px 10px;
    }

    .modal-header {
      padding: 10px 15px;

      .modal-title {
        font-size: 22px;
        line-height: 1.2;
        width: 100%;
        text-align: center;
      }

      .close {
        top: 0;
        right: 10px;
      }
    }

    .button-md {
      height: 40px !important;
      font-size: 18px;
      padding-top: 10px !important;
      font-family: @font-family-heading;
      top: 0;
    }

    .button-md-dismiss {
      height: 40px !important;
      font-size: 18px;
      padding-top: 0;
    }
  }
}
