/* Creating styles for campaigns - Tapan vashi */
.campaign-content-wrapper {
  padding-top:60px;
  padding-bottom:60px;
}

.campaign-content-cta {
  background-color:#f0eeea;
  padding-top:60px;
  padding-bottom:60px;
}

.campaign-content-cta h4 {
  margin-top:0;
  margin-bottom:0;
}


.campaign-content-cta .button-md, .campaign-content-cta .button-sm, .campaign-content-cta .button-lg {
  margin-left:20px;
  margin-bottom:0;
  margin-top:0;
}

.imp-info .panel-heading a {
  background-color: #ffffff !important;
  border: 0px none ! important;
  color:#069;
  display:block;
  background-image:url("/content/dam/mlc/images/icons/icon_plus.png");
  background-repeat:no-repeat;
  background-position:0 10px !important;
  padding-left:40px;
}

.imp-info .panel-heading a {
  outline:none;
}

.imp-info .panel-heading a:hover, .imp-info .panel-heading a:focus {
  text-decoration:none;
  background-color:#ffffff !important;
  color:#069;
  background-image:url("/content/dam/mlc/images/icons/icon_plus.png");
}

.imp-info .media .list-item-text {
     padding-top: 20px;
}

.margintop50 {
  margin-top:50px;
}

.campaign-cta {
  vertical-align:middle !important;
  background-color: #f0eeea;
  padding-bottom: 18px;
  padding-top: 40px;
  text-align: center;
}

.campaign-cta h4 {
  margin-top:0;
  margin-bottom:0;
  display:inline-block;
}

.campaign-cta .button-md {
  margin-left: 20px;
  margin-top:0;
}

.campaign-cta p {
     margin-bottom: 0px;
}

.wrapper-useful-links {
  background-color:#fdfbf7;
  padding-top:60px;
  padding-bottom:42px;
}

/* Super smart strategies */

.wrapper-smart-strategy {
  background-color: #f6f3ef;
  padding-top: 60px;
}

.smart-strategy {
  padding-bottom: 60px;
}

.smart-strategy .col-custom {
  width:20%;
}

.smart-strategy-box h4 {
  background-color: #FDFBF7;
  border-radius: 5px;
  display: block;
  font-size: 18px;
  margin-top: 0px;
  padding-bottom: 0px;
  padding-top: 0px;

}

.smart-strategy-box a {
  color: #333333;
  display: block;
  height: 160px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 30px ! important;
  position: relative;
}

.smart-strategy-box a:hover {
  text-decoration: none;
  background-color:#e9e7e3;
}

.smart-strategy-box a img {
  margin-bottom:20px;
}
.marginbottom20 {
  margin-bottom: 20px !important;
}

.marginbottom40 {
  margin-bottom: 40px !important;
}

.campaign-get-in-touch p {
  width: 190px;
}

.campaign-content hr {
  color: #c8c6c2;
  margin: 40px 0px;
}

.smart-strategy .mlc-glyph {
     color: #01838c;
     font-size: 30px;
}
.strategy-content .mlc-glyph {
  color: #01838c;
  font-size: 30px;
  margin-right:12px;
}

.smart-strategy .mlc-glyph.icon-mlc-find-your-super {
  background: rgba(255, 255, 255, 0) ! important;

}

.strategy-content .mlc-glyph.icon-mlc-find-your-super {
  background: rgba(255, 255, 255, 0) ! important;
  padding: 0px ! important;
  display:inline-block !important;
}

/* Understanding series */

.understanding-series {
  padding-top: 60px;
  padding-bottom:60px;
}

.understanding-series .col-custom {
  height: 375px ! important;
}

.understanding-series img {
  margin-bottom: 28px;
  width: 100%;
}

.understanding-series h4 {
  margin-bottom: 20px;
  margin-top: 0px;
}

.understanding-series h4 a {
  color:#3b3b3b;
}

.understanding-series h4 a:hover {
  text-decoration:none;
}

/* Accenture White Paper */

.video-list {
  padding-top: 60px;
}

.video-list .imp-info .panel-heading a {
  background-color: #f6f3ef ! important;
}

.video-list .imp-info .panel-heading a:hover, .video-list .imp-info .panel-heading a:focus {
  background-color: #f6f3ef ! important;
  background-image: url("/content/dam/mlc/images/icons/icon_plus.png");
}

.video-list .imp-info .list-item-text {
  background-color:#f6f3ef;
  padding-bottom:0;
}

.video-list .flex-video {
  margin-bottom:28px;
}

.video-list h4 {
  font-size:20px;
  line-height:24px;
  margin-bottom:24px;
}

/* MLC Inflation Plus */

.nav-inflation-plus li {
  margin-bottom: 0px;
  padding-left: 40px;
  padding-right: 40px;
}

.nav-inflation-plus li a {
  color: #3b3b3b ! important;
}
.nav-inflation-plus li a:hover {
  background-color:#f0eeea !important;
}

.nav-inflation-plus li:first-child {
  padding-left: 0px;
}

.nav-inflation-plus .active a {
  background-color: #f0eeea ! important;
  border-bottom: 5px solid #e85100;
  border-radius: 0px;
  font-weight:bold;
}

/* Misc */

.wrapper-sitemap {
  padding-bottom: 20px;
  padding-top: 60px;
}

.wrapper-sitemap .bootstrap-sitemap {
  background-color: #f6f3ef;
  margin-bottom: 40px;
  padding: 40px 40px 18px;
}

.wrapper-sitemap .bootstrap-sitemap ul li ul {
  margin-top:10px;
}

.find-super-cta {
  background-color: #f0eeea;
  padding-bottom: 24px;
  padding-top: 40px;
}


.find-super-cta p span.button-md, .find-super-cta p span.button-sm {
  margin-bottom:0;
}

.consolidate-super-cta {
  background-color: #f0eeea;
  padding-bottom: 24px;
  padding-top: 40px;
}

.consolidate-super-cta p span.button-md, .consolidate-super-cta p span.button-sm {
  margin-bottom: 24px;
  margin-top: 24px;
}

.marginbottom60 {
  margin-bottom:60px;
}

@media (max-width: 1200px) {
  nav-inflation-plus li { padding-left: 30px; padding-right: 30px; }
}


@media screen and (min-width:601px) and (max-width:991px) {

  .campaign-content, .page-content {
    padding-top:30px;
    padding-bottom:30px;
  }

  .understanding-series .col-custom {
    width:50%;
    height:370px !important;
  }

  .smart-strategy .col-custom {
    width:32%;
  }

  .smart-strategy h4 {
    font-size:16px;
  }

  .nav-tabs li.last {
      margin-bottom: 20px;
  }

    .campaign-content, .page-content, .campaign-content-wrapper {
      padding-top: 30px;
  	  padding-bottom: 10px;
   }

}

@media screen and (max-width: 600px) {
  .campaign-content, .page-content {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .nav-tabs li.last {
    margin-bottom: 20px;
  }

  .wrapper-tools img {
    margin-bottom: 30px;
  }

  .panel-title a {
    padding-right: 50px;
  }

  .panel .list-item-text, .imp-info .list-item-text {
    padding-bottom: 0px ! important;
  }

  .panel table {
    margin-bottom: 0px;
  }

  table.table {
    margin-bottom: 0px;
  }

  .box-content {
    margin-bottom: 30px;
    min-height: 170px ! important;
    }

  .campaign-cta .button-md {
    margin-bottom: 0px ! important;
  }

  .campaign-content .button-sm, .campaign-content .button-md, .campaign-content .button-lg {
    margin-bottom: 0px;
  }

  .center-cta .button-md {
    margin-left: 0px ! important;
  }

  /* Smart strategy responsive styles */

  .wrapper-smart-strategy {
    padding-top: 30px;
  }

  .smart-strategy {
    padding-top: 20px;
    padding-bottom: 10px;
    background-color: #ffffff;
  }

  .smart-strategy .col-custom {
    width: 100%;
    border-top: 1px solid #c8c6c2;
  }

  .smart-strategy .col-custom:first-child {
    border-top: 0px none;
  }

  .smart-strategy img, .smart-strategy br {
    display: none;
  }

  .smart-strategy h4 {
    font-size: 16px;
  }

  .smart-strategy a {
    height: auto ! important;
    padding: 0px ! important;
    background-color: #ffffff;
    text-align: left;
    padding-top: 10px ! important;
  }

  .smart-strategy a:hover {
      background-color:#ffffff;
    }

  /* Understanding series responsive styles */

  .understanding-series {
  	padding-top:20px;
  	padding-bottom:30px;
  }

  .understanding-series .col-custom {
    height:auto !important;
    border-top:1px solid #c8c6c2;
  }

  .understanding-series img, .understanding-series p {
      display:none;
  }

  .understanding-series h4 {
    font-size:16px;
    margin-bottom:10px;
    padding-top:10px;
  }

  .understanding-series .col-custom:first-child {
    border-top:0;
  }

  /* Advice */

  .advice-centre-content table td {
    float:left;
  }

  .comp-winner-wrapper {
    padding:30px 30px 12px 30px;
  }
}