@import "../mixins.less";

.logout-page .mlc-ltd-sidekick {
    display:none;
  }

  .logout-page .wrapper-logout-content {
    background-color:#faf8f4 !important;
    padding-top:20px;
    padding-bottom:20px;
  }

  .wrapper-logout-content .button-md {
    margin-bottom:0;
  }

  .wrapper-logout-content h3 {
    margin-bottom:0;
    padding-top:30px;
  }

  .logout-page .get-in-touch-wrapper {
    margin-top:20px;
  }