/* desktop */

.channel-quicklinks-tab-wrapper table h4 {
	margin-bottom: 5px;
}
.channel-quicklinks-tab-wrapper ul.nav-tabs li {
    width: 25%;
    border: 0 none;
    background-color: transparent;
}

.channel-quicklinks-tab-wrapper ul.nav-tabs li a {
    height: 250px;
    text-align: center;
    width: 100%;
    font-family: @font-family-heading;
    font-weight: 500;
    font-size: 23px;
    color:#3b3b3b;
}

.channel-quicklinks-tab-wrapper ul.nav-tabs li a,
.channel-quicklinks-tab-wrapper ul.nav-tabs li a:hover {
  border: none;
}
/** Removed as part of the Quick link tabs refresh
.channel-quicklinks-tab-wrapper ul.nav-tabs li.active .channel-quicklinks-tab-active {
  background-color: #ce0058;
  bottom: 0;
  display: block;
  height: 5px;
  position: absolute;
  width: 100%;
  border:0px;
}
.channel-quicklinks-tab-wrapper ul.nav-tabs li.active .channel-quicklinks-tab-active .arrow-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #ce0058;
  margin: 0 auto;
  position: relative;
  top: -7px;
} */

.channel-quicklinks-tab-wrapper ul.nav-tabs li a span {
  display: block;
  line-height: 1em;
  position: relative;
  top: 165px;
  text-align:left !important;
  margin-left:auto;
  margin-right:auto;
}
.icon-mlc-chevron {
  font-size:16px;
  margin-top:7px;
  color:#aaa8a4;
}

/**
@media screen and (min-width: 600px) {
    .channel-quicklinks-tab-wrapper li:nth-child(1) span {max-width:153px !important;}
    .channel-quicklinks-tab-wrapper li:nth-child(2) span {max-width:192px !important;}
    .channel-quicklinks-tab-wrapper li:nth-child(3) span {max-width:140px !important;}
    .channel-quicklinks-tab-wrapper li:nth-child(4) span {max-width:140px !important;}
}
*/
@media screen and (min-width: 600px) {
    .channel-quicklinks-tab-wrapper li.item_0 span {max-width:153px !important;}
    .channel-quicklinks-tab-wrapper li.item_1 span {max-width:192px !important;}
    .channel-quicklinks-tab-wrapper li:item_2 span {max-width:140px !important;}
    .channel-quicklinks-tab-wrapper li:item_3 span {max-width:140px !important;}
}

.channel-quicklinks-tab-wrapper ul.nav-tabs li a div.caret {
  margin-left: 10px;
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid;
  border-bottom: 0px;
}
.channel-quicklinks-tab-wrapper ul.nav-tabs li a .mlc-glyph {
  display: none;
}
/** replaced below, as content list items can have title background images defined now.
.channel-quicklinks-tab-wrapper ul.nav-tabs li:nth-child(1) a {
	background: url("/content/dam/mlc/images/icons/starting-out.png") no-repeat scroll 50% 0 transparent;
}
.channel-quicklinks-tab-wrapper ul.nav-tabs li:nth-child(2) a {
	background: url("/content/dam/mlc/images/icons/building-wealth.png") no-repeat scroll 50% 0 transparent;
}
.channel-quicklinks-tab-wrapper ul.nav-tabs li:nth-child(3) a {
	background: url("/content/dam/mlc/images/icons/planning-retirement.png") no-repeat scroll 50% 0 transparent;
}
.channel-quicklinks-tab-wrapper ul.nav-tabs li:nth-child(4) a {
	background: url("/content/dam/mlc/images/icons/enjoying-retirement.png") no-repeat scroll 50% 0 transparent;
}
*/
.channel-quicklinks-tab-wrapper ul.nav-tabs li a {
	background-position: 50% 0 !important;
}

.channel-quicklinks-tab-content-wrapper {
	margin-top: 30px;
}
.channel-quicklinks-tab-content-wrapper .close-tab {
	float: right;
    color:#ccc;
    &:hover {
      color:#999;
    }
    span {
      font-size:12px !important;
      color:#ccc !important;
    }
}

.channel-quicklinks-tab-wrapper .list-item-text p a {
	color: @default-link-color;
}

/* Responsive styles added - 18/3/15 */

@media screen and (min-width: 594px) and (max-width: 767px) {

  .channel-quicklinks-tab-content-wrapper ul.media-list .list-item-text table td {
    width:47% !important;
    display:inline-block !important;
    margin-bottom:20px;
    margin-right:10px;
  }
}

@media screen and (max-width: 593px) {

  .channel-quicklinks-tab-wrapper ul.nav-tabs {
    margin-top: 0px;
  }

  .channel-quicklinks-tab-wrapper ul.nav-tabs li.active {
    border-bottom: 2px solid #ce0058 ! important;
  }

  .channel-quicklinks-tab-wrapper ul.nav-tabs li a span {
    font-size: 18px ! important;
  }

  .channel-quicklinks-tab-wrapper ul.nav-tabs li a .mlc-glyph {
    right: 2px ! important;
  }

  .channel-quicklinks-tab-content-wrapper ul.media-list .list-item-text table {
    margin-top: 20px;
  }

  .channel-quicklinks-tab-content-wrapper ul.media-list .list-item-text table td {
    width: 100%;
  }

  .channel-quicklinks-tab-content-wrapper ul.media-list .list-item-text table td h4 a {
    color:#3b3b3b !important;
    font-weight:bold;
  }
}


/* mobile */


