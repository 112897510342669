.adviser-platform-campaign {
	.masthead-wrapper.masthead-sm {
		.masthead-text {
			margin-top: 7%;
		}
		h1 {
			font-size: 35px;
			width: 90%;
		}
		h2 {
			width: 90%;
		}
	}
	.masthead-mobile-only-banner {
		display: none;
	}
}
@media (max-width: 1200px) {
	.adviser-platform-campaign {
		.masthead-wrapper.masthead-sm {
			h1 {
				font-size: 32px;
				width: 90%;
			}
			.masthead-text {
				margin-top: 5%;
			}
		}
	}
}
@media (max-width: 992px) {
	.adviser-platform-campaign {
		.masthead-wrapper.masthead-sm {
			h1 {
				font-size: 32px;
				width: 90%;
			}
			.masthead-text {
				margin-top: 0px;
				margin-bottom: 30px;
			}
			background: url(/content/dam/mlc/images/mastheads/masthead-mobile-egg-matrix@2x.png);
			background-size: cover !important;
			background-position: 100% 50% !important;
		}
		.masthead-mobile-only-banner {
			display: inline-block;
		}
		.masthead-mobile-only-banner-content {
			min-height: 280px;
			//background: url(/content/dam/mlc/images/mastheads/masthead-mobile-egg-matrix@2x.png);
			background-size: cover;
			background-position: 79% center;
		}
	}
}
@media (max-width: 600px) {
	.adviser-platform-campaign {
		.masthead-mobile-only-banner-content {
			min-height: 200px;
		}
		.masthead-wrapper.masthead-sm {
			h1 {
				span {
					color: #bc4401 !important;
				}
			}
			.container.page-header-container {
				background-color: transparent;
			}
			h2 {
				font-size: 22px;
			}
		}
	}
}
