//--------------------------------------------------------------------------------------------
// Component Styles
@import "components/mlc-ltd-nav-primary.less";
@import "components/mlc-ltd-sidekick.less";
@import "components/mlc-ltd-login.less";
@import "components/mlc-ltd-forgotten-password.less";
@import "components/mlc-ltd-change-and-set-password.less";
@import "components/mlc-ltd-logout.less";
@import "components/mlc-ltd-daf-media-queries.less";
@import "components/mlc-ltd-site-selector.less";
@import "components/mlc-ltd-search-box.less";
@import "components/mlc-ltd-content-list.less";
@import "components/mlc-ltd-unit-prices-new.less";
@import "components/mlc-ltd-find-funds.less";
@import "components/mlc-ltd-wealth-source-lookup.less";
@import "mixins.less";


// Custom Styles
@import "components/mlc-ltd-content-list-custom-channel-tabs.less";
@import "components/mlc-ltd-content-list-custom-center-colored.less";
@import "components/mlc-ltd-content-list-tabs-advice.less";

// Adviser
@import "templates/mlc-ltd-adviser-theme";

// Business
@import "templates/mlc-ltd-business-theme.less";
@import "components/mlc-adviser-platform-campaign.less";

// Employer
@import "components/employer.less";
 
//--------------------------------------------------------------------------------------------   body {

html {
  width:100%;
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  color: @default-color;
  width:100%;
}
a {
  outline: none !important;
  color: @default-link-color;
}
h1, h2, h3, h4, h5, h6, p {
     margin-top: 0px ! important;
}
h1, h2, h3, h4, h5, h6 {
     font-family: @font-family-heading;
}
p {
     line-height: 22px;
     margin-bottom: 16px;
}
h1 {
    font-size:34px;
}
h2 {
     line-height: 36px;
     margin-bottom: 22px;
}
h3 {
     line-height: 32px;
     margin-bottom: 16px;
     margin-top: 0px;
}
h4 {
  margin-bottom:12px;
  line-height:24px;
}

.font-size-huge {
  font-size: @font-size-h1;
}
.font-size-big {
  font-size: @font-size-h2;
}
.font-size-regular {
  font-size: @font-size-h3;
}
.font-size-little {
  font-size: @font-size-h4;
}

.font-size-tiny {
  font-size: 16px;
}

ul, ol {
  padding-left: 15px;
  margin-bottom:22px;
  li {
    line-height: 22px !important;
    margin-bottom: 10px;
    padding-left:12px;
  }
}
ul ul {
  list-style-type: disc;
}
hr {
  margin-bottom: 30px;
  margin-top: 30px;
  color: #c8c2c6;
}
.roman-bold-75 {
  font-family: @font-family-roman-bold;
  font-size: @font-size-base;
}
* {
  outline:none !important;
}
.margin-top-0 {
  margin-top: 0px !important;
}
.margin-bottom-0 {
  margin-bottom: 0px !important;
}
.padding-top-0 {
  padding-top: 0px !important;
}

.charlie-semibold {
  font-family:@font-family-heading;
}

.charlie-medium {
  font-family:@font-family-sub-heading;
}

.charlie-regular {
  font-family:"Charlie-Std-Regular";
}

.charlie-semibold-italic {
  font-family:@font-family-semi-italic;
}

/* Shared */
.mlc-primary-nav li, .nav-pills li, .sidekick li, .nav-tabs li, .breadcrumb li {
   margin-bottom: 0px;
   padding-left: 0px;
}
.page-content, .campaign-content {
  padding-top:60px;
  padding-bottom:60px;
}
.product-page-title-tabs-container {
  padding-left:0px;
  padding-right:0px;
}

/* Buttons */
span.button-lg {
  background-color: @primary-color-orange;
  color: #ffffff;
  font-family: @font-family-heading;
  font-size: 24px;
  height: 50px ! important;
  margin-bottom: 22px;
  margin-top: 20px;
  min-width: 160px;
  padding-left: 26px;
  padding-right: 26px;
  padding-top:14px;
  text-align: center;
  display:inline-block;
  a {
      color: #fff !important;
      border-bottom: 0;
      font-size: 15px !important;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
}

span.button-md {
  background-color: @primary-color-orange;
  color: #ffffff;
  font-family: @font-family-heading;
  font-size: 20px;
  height: 43px ! important;
  margin-bottom: 22px;
  margin-top: 20px;
  min-width: 155px;
  padding-left: 21px;
  padding-right: 21px;
  padding-top:11px;
  text-align: center;
  display:inline-block;
  a {
    color: #fff !important;
    border-bottom: 0;
    font-size: 15px !important;
    cursor: pointer;
    &:hover {
      text-decoration: none;
      background-color:#bc4401;
    }
  }
}

span.button-md:hover {
  background-color:#bc4401;
}

span.button-md-aqua {
  background-color: @primary-color-aqua;
  color: #ffffff;
  font-family: @font-family-heading;
  font-size: 20px;
  height: 43px ! important;
  margin-bottom: 22px;
  margin-top: 20px;
  min-width: 155px;
  padding-left: 21px;
  padding-right: 21px;
  padding-top:11px;
  text-align: center;
  display:inline-block;
  a {
    color: #fff !important;
    border-bottom: 0;
    font-size: 15px !important;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
      background-color:#004651;
    }
  }
}

span.button-md-aqua:hover {
  background-color:#004651;
}

span.button-md-rubine {
  background-color: @primary-color-rubine;
  color: #ffffff;
  font-family: @font-family-heading;
  font-size: 20px;
  height: 43px ! important;
  margin-bottom: 22px;
  margin-top: 20px;
  min-width: 155px;
  padding-left: 21px;
  padding-right: 21px;
  padding-top:11px;
  text-align: center;
  display:inline-block;
  a {
    color: #fff !important;
    border-bottom: 0;
    font-size: 15px !important;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
      background-color:#60003a;
    }
  }
}

span.button-md-rubine:hover {
  background-color:#60003a;
}

span.button-sm {
  background-color: @primary-color-orange;
  color: #ffffff;
  font-family: @font-family-sans-serif;
  font-size: 14px;
  height: 32px !important;
  margin-bottom: 22px;
  margin-top: 20px;
  min-width: 110px;
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
  display:inline-block;
  padding-top:5px;
  a {
      color: #fff !important;
      border-bottom: 0;
      font-size: 15px !important;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
}

button.button-sm {
  background-color: @primary-color-orange;
  color: #ffffff;
  font-family: @font-family-sans-serif;
  font-size: 14px;
  height: 32px !important;
  margin-bottom: 22px;
  margin-top: 20px;
  min-width: 110px;
  padding-top: 6px;
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
  display:inline-block;
  border: 0;
  border-radius: 0;
  a {
      color: #fff !important;
      border-bottom: 0;
      font-size: 15px !important;
      cursor: pointer;
    }
  &:hover, &:active, &:focus {
    text-decoration: underline;
  }
}

span.border-button-lg {
  font-weight: normal;
    font-family: @font-family-heading;
    text-shadow: none;
    color: #bc4401;
    font-size: 24px;
    height: 50px !important;
    margin-bottom: 22px;
    margin-top: 20px;
    min-width: 160px;
    padding-left: 26px;
    padding-right: 26px;
    padding-top:14px;
    border: 3px solid #bc4401;
    text-decoration: none;
}

span.border-button-md {
  font-weight: normal;
  font-family: @font-family-heading;
  text-shadow: none;
  color: #bc4401;
  font-size: 20px;
  height: 43px ! important;
  margin-bottom: 22px;
  margin-top: 20px;
  min-width: 155px;
  padding-left: 21px;
  padding-right: 21px;
  padding-top:11px;
  padding-bottom: 11px;
  border: 3px solid #bc4401;
  text-decoration: none;
}

span.border-button-md-white-reverse {
  font-weight: normal;
  font-family: @font-family-heading;
  text-shadow: none;
  color: #fff;
  font-size: 20px;
  height: 43px ! important;
  margin-bottom: 22px;
  margin-top: 20px;
  min-width: 155px;
  padding-left: 21px;
  padding-right: 21px;
  padding-top:11px;
  padding-bottom: 11px;
  border: 3px solid #fff;
  text-decoration: none;
  &:hover {
    background-color: #fff;
    color: @default-color;
  }
}

span.border-button-md-rubine {
  font-weight: normal;
  font-family: @font-family-heading;
  text-shadow: none;
  color: #ce0058;
  font-size: 20px;
  height: 43px ! important;
  margin-bottom: 22px;
  margin-top: 20px;
  min-width: 155px;
  padding-left: 21px;
  padding-right: 21px;
  padding-top:11px;
  padding-bottom: 11px;
  border: 3px solid #ce0058;
  text-decoration: none !important;
  &:hover {
    background-color: #ce0058;
    color: #fff;
  }
}

span.border-button-md-aqua {
  font-weight: normal;
  font-family: @font-family-heading;
  text-shadow: none;
  color: #01838c;
  font-size: 20px;
  height: 43px ! important;
  margin-bottom: 22px;
  margin-top: 20px;
  min-width: 155px;
  padding-left: 21px;
  padding-right: 21px;
  padding-top:11px;
  padding-bottom: 11px;
  border: 3px solid #01838c;
  text-decoration: none !important;
  &:hover {
    background-color: #01838c;
    color: #fff;
  }
}

span.border-button-lg-white-reverse {
  font-weight: normal;
  font-family: @font-family-heading;
  text-shadow: none;
  color: #fff;
  font-size: 24px;
  height: 50px !important;
  margin-bottom: 22px;
  margin-top: 20px;
  min-width: 160px;
  padding-left: 26px;
  padding-right: 26px;
  padding-top:14px;
  border: 3px solid #fff;
  text-decoration: none;
  &:hover {
    background-color: #fff;
    color: @default-color;
  }
}

span.border-button-lg-rubine {
  font-weight: normal;
  font-family: @font-family-heading;
  text-shadow: none;
  color: #fff;
  font-size: 24px;
  height: 50px !important;
  margin-bottom: 22px;
  margin-top: 20px;
  min-width: 160px;
  padding-left: 26px;
  padding-right: 26px;
  padding-top:14px;
  border: 3px solid #ce0058;
  text-decoration: none;
  &:hover {
    background-color: #ce0058;
    color: @default-color;
  }
}

span.border-button-lg-aqua {
  font-weight: normal;
  font-family: @font-family-heading;
  text-shadow: none;
  color: #fff;
  font-size: 24px;
  height: 50px !important;
  margin-bottom: 22px;
  margin-top: 20px;
  min-width: 160px;
  padding-left: 26px;
  padding-right: 26px;
  padding-top:14px;
  border: 3px solid #01838c;
  text-decoration: none;
  &:hover {
    background-color: #01838c;
    color: @default-color;
  }
}

span.megamenu-button-sm {
  background-color: #fff;
  color: #004f59;
  font-family: @font-family-heading;
  font-size: 14px;
  height: 32px ! important;
 // margin-bottom: 22px;
 // margin-top: 20px;
  min-width: 110px;
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
  display:inline-block;
  padding-top:6px;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    color: #fff;
    background-color: #004651;
  }
}

.btn-modal-orange-md a {
  background-color: @primary-color-orange;
  border-radius:0px;
  border: 0px none;
  display: inline-block;
  font-family: @font-family-heading;
  font-size: 20px;
  height: 43px ! important;
  margin-bottom: 22px;
  margin-top: 20px;
  min-width: 155px;
  padding-top:7px;
  padding-left: 50px;
  padding-right: 50px;
  text-align: center;
  &:hover {
    background-color: #BC4401;
  }
  &:focus {
    background-color: #C95109 ! important;
  }
}

.btn-orange {
  background-color: #c95109;
    color: #fff;
    font-family: Charlie-Std-SemiBold;
    font-size: 20px;
    height: 43px!important;
    margin-bottom: 22px;
    margin-top: 20px;
    min-width: 155px;
    padding-left: 21px;
    padding-right: 21px;
    padding-top: 9px;
    text-align: center;
    display: inline-block;
    border-radius:0;
    border:0;
}

.btn-orange:hover, .btn-orange:focus, .btn-orange:active {
  background-color:#bc4401;
  color:#ffffff;
}

.btn-aqua {
  background-color:#01838c;
    color: #fff;
    font-family: Charlie-Std-SemiBold;
    font-size: 20px;
    height: 43px!important;
    margin-bottom: 22px;
    margin-top: 20px;
    min-width: 155px;
    padding-left: 21px;
    padding-right: 21px;
    padding-top: 9px;
    text-align: center;
    display: inline-block;
    border-radius:0;
    border:0;
}

.btn-aqua:hover, .btn-aqua:focus, .btn-aqua:active {
  background-color:#004651;
  color:#ffffff;
}

.btn-rubine {
  background-color: #ce0058;
    color: #fff;
    font-family: Charlie-Std-SemiBold;
    font-size: 20px;
    height: 43px!important;
    margin-bottom: 22px;
    margin-top: 20px;
    min-width: 155px;
    padding-left: 21px;
    padding-right: 21px;
    padding-top: 9px;
    text-align: center;
    display: inline-block;
    border-radius:0;
    border:0;
}

.btn-rubine:hover, .btn-rubine:focus, .btn-rubine:active {
  background-color:#60003a;
  color:#ffffff;
}

.orange {
  color:#bc4401;
}
.rubine {
  color:#ce0058;
}
.aqua {
  color:#017780;
}
.white {
  color:#fff;
}

/* charcoal-new will always be charcoal 
and will not change to other colors regardless of the screen size (unlike the original .charcoal) */
.charcoal, .charcoal-new {
  color: @default-color;
}

.page-content table .icon-mlc-document {
  font-size: 18px;
}

.white-reverse {
  color: #fff;
}
.white-reverse .breadcrumb li a {
  color: #fff;
}

/* wrappers */
.wrapper {
  background-color: #fff;
  &.wrapper-top-row {
    background-color: #f0eeea;
    .nav>li>a {
      padding: 15px 10px;
      color: @navbar-default-link-color;
    }
  }
}

.wrapper-top-row .nav-pills>li+li {
  margin-left: 0px;
}
.wrapper.wrapper-footer {
  background-color: #eee;
}
.wrapper.wrapper-dark {
  background-color: #eee;
}
.wrapper-orange {
  background-color: @primary-color-orange;
}
.wrapper-rubine {
  background-color: @primary-color-rubine;
}
.wrapper-aqua {
  background-color:@primary-color-aqua;
}

.wrapper-charcoal {
  background-color:@business-color-charcoal;
}

.wrapper-bg-faf8f4 {
  background-color:#faf8f4;
}

.wrapper-bg-f0eeea {
  background-color:#f0eeea !important;
}

.wrapper-bg-f6f3ef {
  background-color:#f6f3ef !important;
}

.wrapper-bg-fdfbf7 {
  background-color:#fdfbf7;
}

.wrapper-bg-000000 {
  background-color:#000000;
}

/* Adaptive image replacement. NOTE: Adaptive image component does not support an anchor element */
.overview-teaser-wrapper .media img,
.channel-cta-wrapper .media img {
   width: 100%;
}

/* topnav mobile */
#topnav-mobile li {
  width: 40%;
}
#topnav-mobile li a {
  padding: 5px 3px 5px 15px;
}

/* footer */
.row-footer {
  background-color: @footer-default-bg;
  .wrapper.footer-bg {
    background-color: @footer-default-bg;
    font-size:15px;
    margin: 50px auto 57px;
    ul.nav {
      li {
        a {
          color: @footer-default-link-color;
          padding: 0px 20px 0px 0px;
          &:hover, &:focus {
            background-color: transparent;
          }
        }
      }
    }
    .base-footer {
      background-color: @footer-base-bg;
      color: @footer-default-link-color;
      height: 55px;
      p {
        padding-top:17px;
        margin-bottom:0;
      }
      .media {
        img {
          margin-top: 12px;
        }
      }

      //line-height: 23px;
    }
  }
  .connect-with-us-title {
      h3 {
        color:#fff;
        font-size: 16px;
        margin: 0px 0px 15px;
      }
  }
  .wrapper.footer-base-bg {
       background-color: @footer-base-bg;
       font-size:12px;
       margin: 0px auto;
  }
}
.row-footer .nav-pills>li.active>a, .nav-pills>li.active>a:hover, .nav-pills>li.active>a:focus {background:none; }
.row-footer .nav-pills>li>a:hover  {text-decoration:underline;}

@media (min-width: @screen-sm-min){
  .nav-pills>li {
      float: left;
    }
}
@media (max-width:@screen-xs) {
  .nav-pills>li {
    float: none;
  }
}
//@media (min-width: @screen-sm-min){
//  p {
//    margin-bottom: 14px;
//  }
//  ul li, ol li {
//    margin-bottom: 10px;
//  }
//}

.caret {
    margin-left: 1px;
}

/* NOTE: The below code needs to be refactored when the Button property for the Content List becomes available */
/*
.list-item-text p a {
font-weight: normal;
font-size: 24px;
font-family: @font-family-heading;
text-shadow: none;
color: #bc4401;
min-width: 166px;
border: 3px solid #bc4401;
padding: 8px 24px;
text-decoration: none;
}*/
.list-item-text p {
margin-top:25px;
}


/* The following also needs refactoring - Michael McAleer to complete */

.nav-tabs>li>a { text-align: center;}

.carousel-caption {
  bottom: 35px;
  left: 0;
  right: 60%;
  text-shadow: none;
  text-align: left;
  h2 {
    color: #bc4401;
    width:50%;
    line-height: 1.1em;
  }
  h4 {
  	color: #3b3b3b;
  }
}


.carousel-control.left,
.carousel-control.right {
	background-image:none
}


.carousel-content-list-wrapper {
	background-color: #f7f7f7;
}
/* Channel masthead - Carousel */
.carousel-content-list-wrapper .carousel-inner .item {
	height:400px;
	background-position: 50% 0px !important;
    outline: 0;
}
.carousel-content-list-wrapper .white-reverse .carousel-caption  h2 {
  color: #fff;
}

.carousel-content-list-wrapper .rubine .carousel-caption  h2 {
  color: #ce0058;
}

.carousel-content-list-wrapper .aqua .carousel-caption  h2 {
  color: #01838c;
}

.carousel-content-list-wrapper .container {
    width: 100%;
}
.carousel-full-width {
	text-align:center;
}
.carousel-full-width img {
	height:400px;
}
.carousel-full-width .carousel-caption {
	width:920px;
	margin: 0 auto;
	left:0px;
	right:0px;
}


//GH---------


.carousel-control {display:none}

.carousel-content-list-wrapper .carousel-caption a:hover {text-decoration:none;}

.carousel-content-list-wrapper .carousel-inner .item { background-size:cover !important; background-position: center top !important; background-repeat: none;}
.carousel-content-list-wrapper .carousel-caption h2 { max-width:520px; width:50%; font-size:44px; color:#be4601; margin-bottom: 10px;}
.carousel-content-list-wrapper .carousel-caption h4 {font-size: 25px; color: #3b3b3b;
width: 50% !important; margin-left: 0%;line-height: 1.2; margin-bottom: 30px; font-family: @font-family-sub-heading;}
.carousel-content-list-wrapper .carousel-caption .list-item-text h4 {margin-bottom: 20px;}
.carousel-content-list-wrapper .carousel-caption .list-item-text h4 span.border-button-md {
	margin-top: 0;
	display: inline-block;
	line-height: 0.7em;
	padding: 15px 21px;
	height: 50px !important;
}

@media screen and (min-width: 600px) and (max-width: 1199px){
.carousel-content-list-wrapper .carousel-inner .item { height:300px;}
.carousel-content-list-wrapper .carousel-caption {max-width:90% !important;
top: 5% ; left:1%; right:1%;}
.carousel-content-list-wrapper .carousel-caption h2 {font-size:40px; width:68%; max-width:520px; }
.carousel-content-list-wrapper .carousel-caption h4 {font-size: 22px; width:65%; margin-left: 0;}
}


@media screen and (max-width: 599px){

.carousel-content-list-wrapper .carousel-inner .item { height:185px;}
.carousel-content-list-wrapper .carousel-caption {top: 0 ; max-width:100%;
background-color:rgba(0,0,0,.6);}


.carousel-content-list-wrapper .carousel-caption h2 {font-size:22px;
color:#ffffff !important; width: 90% ;margin-left: 5%; margin-bottom:10px; line-height: 1em;}

.carousel-content-list-wrapper .carousel-caption h4 {
  font-family: @font-family-sub-heading;
  color: #fff;
  width: 90% !important;
  margin-left: 5%;
  line-height: 1.2;
  margin-bottom: 30px;
  font-size:13px;
  margin-bottom:15px;
}

.carousel-content-list-wrapper .container { padding-left: 0;
padding-right: 0;}

.carousel-content-list-wrapper .carousel-caption .border-button-md {margin-left:0;


height: inherit;
 padding: 5px 10px;
 font-size: 12px;
 color: #fff;
 border: 3px solid #fff;
  text-decoration: none;
  position: relative;
    top: 10px;

}

  .carousel-content-list-wrapper .carousel-caption .border-button-md:hover {

  	color: #3b3b3b;
  border: 3px solid #fff;
    background-color:#fff;

  	}

  .carousel-content-list-wrapper .carousel-caption .list-item-text h4 {margin-bottom: 10px;}
  .carousel-content-list-wrapper .carousel-caption .list-item-text h4 span.border-button-md {
      padding: 5px 10px;
      height: 30px !important;
      line-height: 1.1em;
      min-width: 60px;
  }

}

@media (min-width:600px){
.carousel-content-list-wrapper .carousel-caption .border-button-md:hover {
  background-color:#c95109; border-color:#c95109;
 color:#fff;}
}



@media screen and (min-width: 1201px){
	.carousel-content-list-wrapper .carousel-caption { max-width:1020px !important;
	top: 15% ; left:85px; right:85px; }


	.carousel-content-list-wrapper .carousel-caption .border-button-md {margin-left:0%;}
}




      //end GH



/* END : Channel masthead  Carousel */


/* GH Masthead */

.masthead-wrapper {height: 400px;background-size: cover !important;background-position: center top !important;
  background-repeat: none;
}

.masthead-wrapper .header-italics h2 {
    font-family: @font-family-regular-italic;
}

.masthead-wrapper.cf-wrapper img {margin-top:55px; }

.masthead-wrapper h1 {font-size:44px; color:#be4601; }
.masthead-wrapper h2 { font-size:25px; color: #3b3b3b ;}

.hero-banner {
  p {
    font-size: 24px;
    line-height: 1.2;
    font-family: "Charlie-Std-Medium";
    color: @default-color;
  }
}

@media screen and (min-width: 600px) and (max-width: 1199px){
.masthead-wrapper { height:300px;}

.masthead-wrapper h1 {font-size:40px;}
.masthead-wrapper h2 {font-size: 22px; width: 50%;
margin-left: 0;}

  .masthead-wrapper.cf-wrapper img {margin-top:30px; }

}

@media screen and (max-width: 767px) {
  .hero-banner {
    p {
      font-size: 16px;
      color: #ffffff;
      line-height: 24px;
    }
  }

  .masthead-wrapper.hero-banner h1 {
    margin-left: 0;
  }
}

@media screen and (max-width: 599px) {

	.masthead-wrapper.cf-wrapper .container.page-header-container /*this is the mask tint dark background */

	{top: 0 ; max-width:100%; height:90%;
background-color:rgba(0,0,0,.5);}

	.masthead-wrapper h1 {font-size: 20px;
color:#fff !important;  margin-bottom:5px;}

.masthead-wrapper {height: 185px}

.masthead-wrapper.cf-wrapper img { width:100px !important;}

.masthead-wrapper.cf-wrapper .breadcrumb * {color:#fff !important; font-size:9px}

	.masthead-wrapper h2 {
	color: #fff !important;
	line-height: 1.2;
	margin-bottom: 30px;
	font-size:13px;  margin-bottom:15px;}


  .masthead-wrapper.cf-wrapper img {margin-top:-20px; }

  .masthead-wrapper h1 {margin-top:0 !important}

	}

/* END Masthead *  /

/* GH left top row header nav */

/*Header top row left */

.wrapper-top-row .nav>li>a:hover, .wrapper-top-row .nav>li>a:focus, .wrapper-top-row .nav-pills>li.active>a, .wrapper-top-row .nav-pills>li.active>a:hover, .wrapper-top-row .nav-pills>li.active>a:focus  {
background-color: inherit; color:#017780}

/* END GH Header top row left */


.channel-info-directive-wrapper {
	background-color: #c95109;
}
.channel-info-directive {
	color:#fff;
  padding: 30px 35px 10px;
	margin:0px;
}
.channel-info-directive .pull-right {
	margin-top:5px;
}
.channel-info-directive.first {
	border-right:1px solid #D06735;
}
.channel-info-directive .text-image h4 {
	margin-bottom: 0px;
	font-size: 25px;
}
.channel-info-directive .text-image p {
	margin-bottom:0px
}
.channel-info-directive .text-image p a {
	font-weight: normal;
	font-size: 16px;
	font-family: @font-family-heading;
	color: #fff;
}

.section-wrapper {
	padding-bottom: 40px;
}
.section-header {
	text-align:center;
}
.section-header h2 {
	margin: 60px 0 40px;
	color: #3b3b3b;
}
.section-header .media-body {
	display:inline;
}

.channel-cta-wrapper {
   padding: 40px 0;
  .channel-cta-panel {
      padding-top: 0;
  }
}
.channel-cta .media-body {
	padding: 20px 75px 0 0;
}
.channel-quicklinks-wrapper {
	background-color: #faf8f4
}
.channel-quicklinks-wrapper.section-wrapper {
  padding: 50px 0;
}
.channel-quicklinks-tab-wrapper .nav-tabs {
  border-bottom: 0px;
}
.channel-quicklinks-tab-content-wrapper {
    margin-top: 30px;
}
.channel-quicklinks {
	text-align: center
}
.channel-quicklinks .media-body {
	display:inline;
}
@media screen and (max-width: 1209px) {
  .channel-quicklinks-tab-wrapper * span { font-size: 20px ! important; }
}

@media screen and (min-width: 662px) and (max-width: 992px) {
    .channel-quicklinks-tab-wrapper li:nth-child(1) span {width: 300px ! important; }
}

.news-insights-wrapper {
  background-color: #f0eeea;
}

.get-in-touch-wrapper {
  background-color: #faf8f4;
  padding: 60px 0px 44px 0;
}
.get-in-touch-wrapper .media-body {
  width:160px;
}
.get-in-touch-wrapper .text-image h4 {
  color: #017780;
}
.adviser-theme.get-in-touch-wrapper h4 a {
  color: @primary-color-rubine;
}
.business-theme.get-in-touch-wrapper h4 a {
  color: #be4601;
}


//GH Latest News 23.02

.investment-news-insights-wrapper {
  padding:60px 0;
  background-color:#f0eeea;
  .news-insights-panel {
    background-color: #fff;
    min-height: 225px;
    padding: 15px;
  }
}

/* Padding top bottom section row  */

.channel-quicklinks-wrapper.section-wrapper {
padding: 60px 0;
}

.wrapper.channel-news-insights-wrapper.section-wrapper {

  padding:60px 0; background-color:#f0eeea;}

.media.news-insights-panel>.media-body { padding: 15px; background-color:#fff; }

.media.news-insights-panel>.media-body .small { margin-bottom:5px; color:#787878 }

.media.news-insights-panel>.media-body h4 { margin-top:0;}

.media.news-insights-panel>.pull-left {padding-right: 0 !important;}


.media.news-insights-panel>.pull-left img {display:none;}

.media.news-insights-panel>.media-body {width:100%}

.wrapper.channel-news-insights-wrapper.section-wrapper .col-custom.col-xs-12.col-sm-6.col-md-3.col-lg-3 {
  padding-left:0; padding-right:0}


.media.news-insights-panel>.media-body h4 {   height:40px;}

.media.news-insights-panel>.media-body p {    margin-bottom:5px}

.media.news-insights-panel>.media-body .blogEntryMoreLinkText {margin-top:0px;}


@media screen and (min-width: 993px) {

.media.news-insights-panel>.pull-left img { display:block; width:253px;}

.media.news-insights-panel>.media-body h4 {  height:70px;}

.media.news-insights-panel>.media-body p { height: 90px;}

  .breadcrumb {
     display:block;
   }

}


@media screen and (min-width: 768px) and (max-width: 992px) {
  .media.news-insights-panel>.media-body p {
height: 50px;}

.media.news-insights-panel>.pull-left img {display:block; width: 370px;}

.media.news-insights-panel>.media-body h4 { height:70px;}

}


@media screen and (max-width: 773px) {
  .media.news-insights-panel>.media-body p {
height: 50px;}

.media.news-insights-panel>.media-body h4 {   height:40px !important; }

  .channel-info-directive {
    padding: 30px 35px;
  }

  .channel-info-directive.first {
    border-right: 0px solid #d06735;
  }

  .important-updates-wrapper .important-updates-row .important-updates-panel {
    height: auto;
    padding: 30px 20px 30px 20px;
    margin-bottom:20px;
  }

}

@media screen and (min-width: 767px) {
.wrapper.channel-news-insights-wrapper.section-wrapper .col-custom.col-xs-12.col-sm-6.col-md-3.col-lg-3 {padding-right: 0px; padding-left: 0px; margin-bottom: 0px;}
}

@media screen and (max-width: 767px) {
.wrapper.channel-news-insights-wrapper.section-wrapper .col-custom.col-xs-12.col-sm-6.col-md-3.col-lg-3 {padding-right: 10px; padding-left: 10px; margin-bottom: 10px;}

}

 // END GH latest news.

// 24.02.15 GH nav right desktop

#site-select-desktop .nav>li>a:focus, #site-select-desktop .nav-pills>li.active>a, #site-select-desktop .nav-pills>li.active>a:hover, #site-select-desktop .nav-pills>li.active>a:focus  {
background-color: #017780; color:#fff }

#site-select-desktop .nav>li>a:hover {
color: #3b3b3b;
background-color: #e6e4e0;}

// END 24.02.15 GH nav right desktop


// GH 24.02.15 homepage channel cta, info directive, quick links


@media screen and (max-width: 773px) {
/*homepage second row, mobile & magnifying glass icons */
.wrapper.channel-cta-wrapper .col-custom.col-xs-12 {margin-bottom:20px}
.wrapper.channel-cta-wrapper .col-custom.col-xs-12:last-child {margin-bottom:0px}

/*homepage second row orange, open super account & reg online access */
.channel-info-directive.first {border-right: 0px solid #d06735; border-bottom:1px solid #d06735;}


}

/*Quick links */

.channel-quicklinks-wrapper.section-wrapper { padding : 42px 0 30px}

.channel-quicklinks-tab-wrapper ul.nav-tabs {
  height:inherit;
  li {
    &.active {
      .caret {
        border-top: 0 solid;
        border-bottom: 7px solid;
      }
    }
    a {
      span {
        text-align:center;
      }
    }
  }
}

.tab-pane.channel-quicklinks-tab-content-wrapper.active {
  margin-top:-31px;
  border-top:1px #dcdad6 solid

}

.channel-quicklinks-tab-wrapper table td {display: table-cell; padding: 0 5px;}

.channel-quicklinks-tab-content-wrapper .close-tab {margin:10px}


@media screen and (min-width: 594px)  and (max-width: 773px) {

.channel-quicklinks-tab-wrapper ul.nav-tabs li:nth-child(1) a,
.channel-quicklinks-tab-wrapper ul.nav-tabs li:nth-child(2) a,
.channel-quicklinks-tab-wrapper ul.nav-tabs li:nth-child(3) a,
.channel-quicklinks-tab-wrapper ul.nav-tabs li:nth-child(4) a {
  background-size: 75%;
  background-position: center 15%;
}

.channel-quicklinks-tab-wrapper ul.nav-tabs li a span {margin-top:-20px}

}

/* Embedded page search container */
.embedded-search-wrapper .form-group {
  width: 100%;
  text-align: center;
}
.embedded-search-wrapper .form-group .global_search_field {
  width: 75%;
}

/* Search icon global top nav desktop */
#mlc-header-search-box .global_search_form .form-group .glyphicon {margin-right:0; margin-top:-12px; padding:12px 8px 8px 12px; width: 50px;
height: 51px; background-color: #faf8f4; }


@media screen and (max-width: 593px) {
.channel-quicklinks-tab-wrapper table td {display: block}
.channel-quicklinks-tab-wrapper ul.nav-tabs li {width:100%}
.channel-quicklinks-tab-wrapper ul.nav-tabs li a span {text-align:left;}

.channel-quicklinks-tab-wrapper ul.nav-tabs li a {position: relative;width: 100%;height: 50px;}
.channel-quicklinks-tab-wrapper ul.nav-tabs li a div.caret {
  display:none;
}
.channel-quicklinks-tab-wrapper ul.nav-tabs li a .mlc-glyph {
  position: absolute;
  right: 0px;
  display: inline-block;
}

.channel-quicklinks-tab-wrapper ul.nav-tabs li:nth-child(1) a,
.channel-quicklinks-tab-wrapper ul.nav-tabs li:nth-child(2) a,
.channel-quicklinks-tab-wrapper ul.nav-tabs li:nth-child(3) a,
.channel-quicklinks-tab-wrapper ul.nav-tabs li:nth-child(4) a {
background-size: 38px !important;
background-position: left center !important;}

.channel-quicklinks-tab-wrapper ul.nav-tabs li a span {position: absolute; left:50px;  top: 17px; font-size:17px /* width: 300px; */}

.section-header h2 {margin-bottom:24px}

.channel-quicklinks-tab-wrapper ul.nav-tabs li.active .channel-quicklinks-tab-active {display:none;}

.channel-quicklinks-tab-wrapper ul.nav-tabs li {border-bottom: 1px #c8c6c2 solid;}


.channel-quicklinks-wrapper.section-wrapper { padding : 30px 0 0}

}



// END GH 24.02.15 homepage channel cta, info directive, quick links


// GH 25.02.15 quick links mobile


.channel-quicklinks-tab-wrapper table h4 a {color:#444 !important; text-decoration:none;}

@media screen and (max-width: 593px) {

.channel-quicklinks-tab-content-wrapper .close-tab {display:none;}

.tab-pane.channel-quicklinks-tab-content-wrapper.active {margin-top:-31px; }

.channel-quicklinks-tab-wrapper table h4 a {font-size:15px; font-family:@font-family-sans-serif, arial; margin-top:0;
color:#037691 !important}

.channel-quicklinks-tab-wrapper table h4 a:hover {color:#23527c !important; text-decoration:underline !important}

.channel-quicklinks-tab-wrapper table p {display:none}

.channel-quicklinks-tab-wrapper table td {padding:7px 0}

.channel-quicklinks-tab-wrapper ul.nav-tabs li:last-child {border-bottom:0}

}

// END GH 25.02.15



// GH 26.02.15


h5, h6 {font-family: @font-family-sans-serif, Arial, sans-serif; font-weight:bold; font-size: 15px}

.dropdown-menu h5 { font-size:16; font-weight: normal !important;}

#sidekick-desktop { position: absolute !important; }
#sidekick-desktop.minimized {right: -175px;}

#sidekick-desktop {width:240px;}

#sidekick-desktop .sidekick-glyphicon {
margin: -2px 18px 0 2px;
}

#mlc-header-search-box .global_search_form .form-group .form-control { color:#fff;}

// END GH 26.02.15



/* GH 02.03.15  */

/*Super overview thumbs */

.overview-teaser-wrapper .media h4 a {margin-top:10px; color:#3b3b3b; text-decoration:none;display: block;}
.overview-teaser-wrapper .media a h4:hover {color:#3b3b3b;  text-decoration:none; }

.overview-teaser-col h4 a { color: @default-link-color };
.overview-teaser-col h4 a:hover { color: @default-link-color-hover };

@media screen and (min-width:992px) {
  .overview-teaser-wrapper {padding:60px 0;}
}



@media screen and (max-width:997px) {
  .overview-teaser-wrapper {padding:30px 0;}
}


@media screen and (max-width: 773px) {
 .overview-teaser-wrapper .overview-teaser-col img {display:none;}

 .overview-teaser-wrapper .overview-teaser-col p {display:none;}

 .overview-teaser-wrapper .overview-teaser-col h4 { font-size:16px; }

 .overview-teaser-wrapper {padding: 15px 10px}

 .overview-teaser-wrapper .overview-teaser-col { border-bottom: 1px #c8c6c2 solid;  padding: 0px 10px;  }


 .overview-teaser-wrapper .overview-teaser-col:last-child {border-bottom: none;}

 }

.overview-teaser-col img {margin-bottom:8px; }

/*Masthead sm */

.masthead-wrapper.masthead-sm {height: 320px; background-size: cover !important; background-position: center top !important;}



.masthead-wrapper.masthead-sm h1 {font-size:42px; color:#ce0058;
  margin-top:40px !important; margin-bottom:10px; width:65%;}

.masthead-wrapper.masthead-sm h2 { font-size: 24px; line-height:1.2; color: #3b3b3b; width:65%;
  font-family: @font-family-sub-heading; }

@media screen and (min-width: 600px) and (max-width: 1199px){
.masthead-wrapper.masthead-sm { height:270px;}

.masthead-wrapper.masthead-sm h1 { width:65%;  font-size:40px;}


.masthead-wrapper.masthead-sm h2 {font-size: 22px; width: 65%; margin-left: 0;}

}

@media screen and (min-width: 993px) and (max-width: 1209px){

.masthead-wrapper.masthead-sm h1 {margin-top: 25px!important;}

}

@media screen and (min-width: 600px) and (max-width: 992px){

.masthead-wrapper.masthead-sm h1 {margin-top: 50px!important;}

}

@media screen and (max-width: 599px){

	.masthead-wrapper.masthead-sm .container.page-header-container /*this is the mask tint dark background */

	{top: 0; max-width:100%; height:90%; background-color:rgba(0,0,0,.5);}

	.masthead-wrapper.masthead-sm h1 { font-size: 20px; margin-bottom:5px; width:90%;}
    .masthead-wrapper.masthead-sm h1 span { color:#fff !important;}

	.masthead-wrapper.masthead-sm {height: 185px;}

	.masthead-wrapper.masthead-sm h1 {margin-top:25px !important; line-height:1.2; margin-left:2%;}


	.masthead-wrapper.masthead-sm h2 {
	color: #fff !important;line-height: 1.2; width:90%; margin-bottom: 30px; font-size:13px; margin-left:2%; margin-bottom:15px;}

	}

/* Accordion FAQs */

.campaign-content .faqs .panel-heading h3 {font-family:@font-family-sans-serif, Arial, Helvetica; font-size:17px}

.campaign-content .faqs .panel-heading a {background-color:#fff !important; }

.panel-heading a {
 background-image: url(/content/dam/mlc/images/icons/icon_accordion_minus.png);
 background-position: 98% 13px;
}


.campaign-content .panel .media-list .list-item-text {
 /*border-top:1px #c8c6c2 solid;*/
  margin-top:15px;
  padding-top: 20px;
  padding-bottom: 0px;
}

/* Carousel indicators */

.carousel-indicators li {padding-left:0; width:18px; height:18px; margin: 0px 5px;}

/* END GH 02.03.15 super overview thumbs */

//TV GH

/* GET in touch ----- */
.three-column .col-custom { width:30%; margin-left:2%;}

@media (max-width:600px) {
  .three-column .col-custom {
    width:93% !important;}

 /* .product-page-cta h4 a {display: block;}     */

}
.no-global-get-in-touch .row-footer .get-in-touch-wrapper {
  display:none;
}

.news-insights-panel .media-body p {
  /*display:none;*/
}
.news-insights-panel .media-body a,
.news-insights-panel .media-body a:hover {
  color: #3b3b3b;
  text-decoration: none;
}

.archive-wrapper {
  background-color: #faf8f4;
  padding: 50px 0px;
}

/* Overview page - campaigns and products */

.page-overview h3 {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 24px;
  margin-top: 28px ! important;
}

/* Product page */

.page-header-container {
	color: #fff;
}
.page-header-container .breadcrumb {
	background: transparent;
	font-size: 12px;
	padding: 0px;
	margin: 25px 0 0;
}
.page-header-container .breadcrumb li,
.page-header-container .breadcrumb li.active  {
	color: #fff;
}
.page-header-container .breadcrumb li.last.active  {
	font-weight: bold;
}
.page-header-container .breadcrumb a {
  color: #fff;
}
.charcoal .breadcrumb li.active,
.charcoal .breadcrumb li a {
  color: @default-color;
}
.page-header-container h1 {
	margin: 0 0 32px;
}
.wrapper-rubine {
  background-color: #ce0058;
}

.product-page-cta {
  background-color: #F0EEEA;
  padding: 40px 0 24px 0;
  text-align: center;
  h4 {
    display:inline-block;
    font-family: Charlie-Std-SemiBold;
    font-size: 22px;
    margin-bottom:18px;
    a {
      margin-left: 20px;
      margin-bottom:0px;
    }
  }
}

.product-page-cta span.button-md, .product-page-cta span.button-sm {
    margin-top:0 !important;
  }

.center-cta h4, .center-cta .button-sm, .center-cta .button-md, .center-cta .button-lg {
  text-align:center;
}

.center-cta .button-sm, .center-cta .button-md, .center-cta .button-lg {
  margin-left:20px;
}
.page-intro,
.product-page-intro,
.product-page-how-to-invest {
  background-color: #faf8f4;
}

.product-page-intro {
  font-size:17px !important;
}
.page-intro .container,
.product-page-intro .container,
.product-page-how-to-invest .container{
  padding-top: 50px;
  padding-bottom: 32px;
}

.nav-tabs {
  margin-top:30px;
  margin-bottom: 30px;
  height: 42px; /* temp fix - attempt to remove if possible */
}
.nav-tabs > li > a {
  padding:10px 25px;
  color:#3b3b3b;
  border:0px !important;
  border-radius: 0px;
  margin-right: 0px;
}
.nav-tabs > li {
  border-top:0px solid #ccc;
  border-left:1px solid #ccc;
  background-color: #f0eeea;
  border-bottom:0px solid #ccc;
}
.nav-tabs > li.last {
  border-top:0px solid #ccc;
  border-left:1px solid #ccc;
  border-right:0px solid #ccc;
}
.nav-tabs > li.last.active {
  border-top:1px solid #ccc;
  border-bottom:1px solid #fff;
  border-right:1px solid #ccc;
}
.nav-tabs > li.first,
.nav-tabs > li.active {
  border-top:1px solid #ccc;
  border-bottom:1px solid #fff;
}

.product-page-faqs {
  background-color: #faf8f4;
  border-bottom: 1px solid #ccc;
  padding: 50px 0;
}
.embedded-search-wrapper {
  background-color: #faf8f4;
  border-bottom: 1px solid #ccc;
  padding: 60px 0;
}

.important-updates-wrapper {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  padding: 60px 0;
  .important-updates-row {
    margin-bottom: 20px;
    .important-updates-panel {
      background-color: #F0EEEA;
      padding: 30px 20px;
      min-height:270px;
      .important-updates-panel p {
        padding:0px;
        margin:0px;
      }
    }
  }
}


/* Global accordions */

.panel-group {
  margin-bottom:30px;
}

.panel {
     border: 0px none;
  box-shadow:none !important;
  margin-bottom:12px !important;
}

.panel-heading {
     border-radius: 0px;
     padding: 0px;
}

.panel-title {
     font-size: 15px;
     line-height: 24px;
     margin: 0px;
}

.panel-heading a.collapsed {
     background-color: #dcdad6 ! important;
     background-image: url("/content/dam/mlc/images/icons/icon_accordion_plus.png");
     background-position: 98% center;
     background-repeat: no-repeat;
     color: #3b3b3b;
     display: block;
     padding-bottom: 10px;
     padding-left: 18px;
     padding-top: 10px;
  padding-right:50px;
}

.panel-heading a {
     background-color: #585652;
     background-image: url("/content/dam/mlc/images/icons/icon_accordion_minus_drk.png");
     background-position: 99% center;
     background-repeat: no-repeat;
     color: #ffffff;
     display: block;
     outline: medium none;
     padding-bottom: 10px;
     padding-left: 18px;
     padding-top: 10px;
     text-decoration: none;
  padding-right:50px;
}

.panel-heading a.collapsed:hover {
  background-color: #585652 ! important;
  background-image: url("/content/dam/mlc/images/icons/icon_accordion_plus_wht.png");
  background-position: 99% center;
  background-repeat: no-repeat;
  color: #ffffff;
  text-decoration: none;
}

.panel-heading a:hover {
     text-decoration: none;
}

.panel .media-list li.media {
  padding-left: 0px !important;
}

.panel .media-list .list-item-text {
  padding-bottom: 0px;
  padding-top: 40px;
}

.accordion-last .list-item-text {
  padding-bottom: 0px ! important;
}

/* Mozilla bug fix */
.bootstrap-blog-entry .media-body {
  display: inline-block !important;
}

/* About MLC styles - created by Imran */



.our-impact {
     color: #FFFFFF;
}

.our-impact * h3 {
     margin-top: 0px;
     margin-bottom: 0px;
     padding-bottom: 10px;
     padding-top: 10px;
}

.our-impact * h1 {
     margin-top: 10px !important;
     margin-bottom:0px;
}

.our-impact-header {
     background-color: #D86018;
     color: #FFFFFF;
     margin-right: auto ! important;
}

.our-impact-body {
     color:#D86018;
     background-color: #F4DCCD;
}

.our-impact-below {
     background-color: #e7e7e9;
}

.our-impact-below * .media-left {
     display: block ! important;
     text-align: center ! important;
}

.padding-bottom-30 {
     padding-bottom:30px !important;
}

.cf-3para-css div {
    -webkit-column-count: 3; /* Chrome, Safari, Opera */
    -moz-column-count: 3; /* Firefox */
    column-count: 3;
}

.cf-nav ul {
  padding-left: 15%;
  li {
       margin-bottom: 0px;
       padding-left: 10px;
       padding-right: 10px;
  }
}


.cf-nav li a {
     color: #3B3B3B ! important;
}

.cf-nav li:first-child a {
     font-weight: bold;
}

.cf-nav .active a {
     background-color: #F0EEEA ! important;
     border-bottom: 5px solid #E85100;
     border-radius: 0px;
}

/* Tables */
.table {
  border:0px;
  border-bottom:1px solid #ddd;
  tr {
    td {
      border-top:1px solid #c8c6c2 !important;
    }
  }
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
}

.table-cell-header {
  background-color: #DFDBD2;
}
.table-cell-sub-header {
  /*backgroud-color: #;*/
}
.table-cell-sub-sub-header {
  /*backgroud-color: #;*/
}

.table-cell-orange-header {
  background-color: #C95109;
  color:#fff;
}
.table-cell-orange-sub-header {
  background-color: #CE6221;
  color:#fff;
}
.table-cell-orange-sub-sub-header {
  background-color: #D3733A;
  color:#fff;
}
.table-bordered-images tr td a {
  border: 1px solid #ccc;
  border-radius: 6px;
  display: block;
  padding: 20px;
}
.table-responsive {
  border:0;
}
.faq-body table th,
.modal-body .faq-answer-content-row table th {
  padding: 10px;
}
.faq-body table td,
.modal-body .faq-answer-content-row table td {
  border-top:1px solid #c8c6c2 !important;
  padding: 10px;
}
/* Stronger Super pages */

.wrapper-stronger-super {
  .breadcrumb {
    background-color: transparent;
  }
  .mlc-glyph {
    color: @primary-color-aqua;
  }
}
.stronger-super-cta {
  border-bottom: 1px solid #c8c6c2;
  margin-bottom:21px;
  padding-bottom:21px;
}

.stronger-super-cta .button-md {
  position: relative;
  top: 18px;
}

.stronger-super-cta .glyphicon {
  margin-right:12px;
}

.wrapper-tools {
  background-color: #f0eeea;
  padding-bottom: 60px;
  padding-top: 30px;
}

.wrapper-tools .text-image {
  padding-top: 30px ! important;
}

.wrapper-tools .pull-left {
  padding-right: 20px ! important;
}

.wrapper-tools img {
  width:100%;
}

.wrapper-bg-faf8f4 {
  background-color:#faf8f4;
}

.wrapper-transparent-breadcrumb {
  .breadcrumb {
    background-color: transparent;
  }
}

//Protect My Super pages

.wrapper-protect-my-super {
  margin-bottom: 50px;
  padding-bottom: 50px;
  .wrapper-transparent-breadcrumb;
}

.wrapper-protect-my-super-how-it-can-work {
  &:extend(.wrapper-protect-my-super);
  margin-bottom: 0;
}
.protect-my-super-form {
  .protect-my-super-field {
    display: inline-block;
    input {
      font-size: 15px;
      height: 35px;
      padding: 6px 12px;
    }
  }
  .btn {
    background-color: #c95109;
    border-color: #c95109;
    border-radius: 0;
    height: 35px;
    position: absolute;
  }
}
.model-protect-my-super {
  height: 500px;
  width: 800px;
}

.model-protect-my-super .modal-body iframe {
  border:0px;
}


//Advice pages

.advice-centre-wrapper {
  padding-bottom: 30px;
  padding-top: 60px;
}

.comp-winner-wrapper {
  background-color: #faf8f4;
  margin-bottom: 40px;
  margin-top: 40px;
  padding: 60px 60px 42px 60px;
}

.advice-centre-list {
  margin-bottom:40px;
}

.advice-centre-content table.table {
  margin-bottom:0;
}

.advice-centre-content table td {
  padding-bottom: 20px;
  vertical-align: top;
  padding-right:15px;
}

.advice-centre-content table td img {
  padding-right:5px;
}

.adv-center-maps {
  margin-left: 10px;
}

.adv-center-maps iframe {
  border: 0px none;
}

//Misc

.box-content {
  background-color: #f7f6f4;
  min-height: 220px;
  padding: 40px 20px 20px 40px;
  margin-bottom:40px;

  h3 {
    margin-top:0;
  }

  h4 {
    margin-top:0;
  }

  .mlc-glyph {
    color:@primary-color-aqua;
    font-size:30px;
  }
}

.feature-copy {
  font-size:17px;
}

.business-get-in-touch a {
  color:#c95109 !important;
}

.business-get-in-touch a:hover {
  color:#bc4401 !important;
}

.adviser-get-in-touch a {
  color:#ce0058 !important;
}

.paddingtop60 {
  padding-top:60px;
}

.paddingtop40 {
  padding-top:40px;
}

.lt-ie9 img {
  width:auto !important;
}

/* ----------------------------------------------------------- */
/* GH MODIFY ------------------------------------------ */
/* ----------------------------------------------------------- */



/*11.02.15-------------- */
.navbar-nav {float: right;}



.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:hover, .navbar-default .navbar-nav>.open>a:focus {
color: #017780; background-color: #faf9f7; height:54px; outline:none; }

#megamenu-col-1 {/*margin-left:20px; width:250px; padding:20px; background-color: #01838c; color:#fff;*/}
#megamenu-col-1 > h4 { font-size:22px;}
#megamenu-col-1 a {color: #fff !important; border-bottom:0; font-size:14px !important; }
#megamenu-col-1 a:hover { text-decoration:underline; }
.megamenu-col-1 p {font-size: 14px;}

/*.yamm .dropdown.yamm-fw .dropdown-menu {padding: 20px 20px 40px 20px; background-color: #faf9f7; border-top: 0;margin-top: 0 !important;position: absolute;left: 0;z-index: 5; }*/

.yamm-fw {
  .dropdown-menu {
    border: 0px solid rgba(0, 0, 0, .15);
    margin-top: 0;
    border-radius: 0px;
    -webkit-box-shadow: 0px 4px 3px rgba(0, 0, 0, .175);
    box-shadow: 0px 4px 3px rgba(0, 0, 0, .175);
  }
  .dropdown-menu .glyphicon {
    color: #01838c
  }

  .dropdown-menu li {
    list-style: none;
    border-bottom: 1px #d1d3d4 solid;
  }
  .dropdown-menu li a {
    display: block;
    list-style: none;
    font-size: 14px !important;
    font-family: @font-family-sans-serif !important;
    color: #444444;
    padding: 7px 0 !important;
    border-bottom: 0px #d1d3d4 solid !important;
    text-decoration: none;
  }
  .dropdown-menu li:last-child {
    border-bottom: 0px #d1d3d4 solid;
  }

  .dropdown-menu h5 {
    border-bottom: 0px #d1d3d4 solid;
    font-family: @font-family-heading;
    color: #bc4401;
  }

  .dropdown-menu ul {
    padding-left: 0;
  }
}

/*12.02.15 ------------------------------------------*/


/*mlc logo padding */
nav.navbar-default .navbar-header .navbar-brand {padding: 0 10px 0 0; }


.nav-tabs>li.first { border-top: 0px solid #ccc; border-bottom: 1px solid #ddd; border-left: 0px solid #ccc;}
.nav-tabs>li.active { border-top: 1px solid #ddd; border-left: 1px solid #ddd; border-bottom: 1px solid #fff;}

.table-striped>tbody>tr:nth-child(odd) {background-color: #f6f3ef;}


td, th {
   border:none !important;
}

@media (max-width: 992px) {
.visible-xs {display: block!important;}}

#sidekick-mobile, #sidekick-mobile .sidekick li  { background-color: #01838c}
.sidekick li  { list-style:none; padding: 7px 0}

.sidekick li a  {color:#fff;}

.row-footer.wrapper.footer-base-bg, .bootstrap-row.parbase.multiple-columns.section { /*background-color: #353535;*/}

.bootstrap-addthis.parbase.bootstrap-base {margin-bottom:20px;}

.row-footer .wrapper.footer-base-bg {width:100%;}

.row-footer {background-color: #282828;}


/*13.02.15*/

#sidekick-mobile { top:60px;}



@media (max-width: 992px) {

	header {margin-top:30px;}

	.navbar-toggle {display:block;}
	.wrapper.wrapper-top-row {display:none;}

	 /*MLC logo */
  .navbar-brand { padding:10px 0 10px 20px; margin-top:0; position:fixed; left:40px}
  .nav-mobile-header a.navbar-brand img { height:38px;}

	}

/*Accordion */

@media screen and (max-width: 599px) {
  .panel-heading a.collapsed {padding-top:10px; padding-bottom:10px}

}

.nav-mobile-header { height:60px;}

#sidekick-mobile {height:68px}

#sidekick-mobile .sidekick li a .sidekick-glyphicon {margin:-5px 0 5px 0}

#sidekick-mobile .sidekick li a

{display: block; max-width: 65px;  margin: auto; font-size: 10px; line-height: 13px;margin-top: 5px;}

#sidekick-mobile .sidekick li a:hover {text-decoration:none;}

#sidekick-mobile .sidekick li a .sidekick-glyphicon.icon-mlc-call-contactus {margin-bottom:12px}
#sidekick-mobile .sidekick li a .sidekick-glyphicon.icon-mlc-login-to-secure-site {margin-bottom:12px}


#sidekick-mobile .sidekick li.first {background-color: #004651; height:68px;}

#sidekick-mobile .sidekick li { height:68px;}

#sidekick-mobile .sidekick li:hover {background-color: #00a9b2;}

#sidekick-mobile ol {padding-left:0}


/*16.02.15 */

.nav-pills>li>a {border-radius: 0;}

#sidekick-mobile .sidekick li a .sidekick-glyphicon { font-size:18px; display:block !important; }

.glyphicon { width: inherit; height: inherit; }

@media screen and (max-width: 991px) {
nav.navbar-default .navbar-nav { float:none;}

nav.navbar-default .navbar-nav li a, nav.navbar-default .navbar-nav li a:hover {
 padding: 14px 12.5px; height:inherit;}

nav.navbar-default .navbar-nav li a {
background-color: #faf8f4;}

nav.navbar-default .navbar-nav li a:hover {
background-color: #faf9f7; color:#017780;}

nav.navbar-default .navbar-nav li a:hover {
background-color: #f0eeea;}

//.visible-xs {overflow-x: hidden;}

.nav-pills>li { float:none;}

#topnav-mobile li { width:100%; background-color: #3b3b3b; color:#fff;}
#topnav-mobile li a:hover {background-color: #3b3b3b; text-decoration:underline;}

.nav-pills>li+li { margin-left: 0;}

.yamm .dropdown.yamm-fw .dropdown-menu { /*Getting rid of bottom about advice link*/ padding:0;}
.navbar-offcanvas.offcanvas-transform.in { background-color: #faf8f4; /*background color side nav left */}

#topnav-mobile li a { color:#fff;}

#topnav-mobile li a { padding: 12px 3px 12px 15px;}

.dropdown-menu ul li a { padding: 14px !important; border:0;}

nav.navbar-default .navbar-nav { margin-top: 0 !important;}

.navbar-nav {margin: 0px -10px;}

}
/*change from 600 to 992 cause there are 5 tabs */
@media screen and (max-width: 992px) {
/*tabs*/
.nav-tabs>li.active, .nav-tabs>li, .nav-tabs>li.first, .nav-tabs>li.last {
  border-bottom:1px #c8c6c2 solid; border-left:0; border-right:0px #c8c6c2 solid; width:100%;}


  .nav-tabs>li.active {border-right:0 !important; border-bottom:1px #c8c6c2 solid}

.wrapper-stronger-super .text-image {position:relative; top:20px}

  .adv-center-maps {
       margin-bottom: 30px;
  }

}

/*17/02/15---------- */

.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:hover, .navbar-default .navbar-nav>.open>a:focus {  padding-bottom: 23px; }

nav.navbar-default .navbar-nav li a:hover { background-color: #faf9f7; color:#017780; height:inherit; padding-bottom: 23px; }

nav.navbar-default .navbar-nav .open a {height:inherit;}

.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:focus { background-color: #faf9f7; color:#017780; height:inherit; }

nav.navbar-default .navbar-nav li a:hover, nav.navbar-default .navbar-nav li a:focus { height: inherit;}

/* Equal column height sizing when .equal-col-height applied to row */
.row.equal-col-height {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.row.equal-col-height:before,
.row.equal-col-height:after {
  display: block;
}

.row.equal-col-height > [class*='col-'] {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.row.equal-col-height > [class*='col-'] > * {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto; 
}

.row.equal-col-height .col-custom .bootstrap-text-image {
  background-color: @bg-f0eeea;
}

@media screen and (max-width: 991px) {
	nav.navbar-default .navbar-nav li a:hover { padding-bottom: inherit; height:inherit;}
	.dropdown-menu ul li a {padding: 14px 14px !important;}
	.dropdown-menu li {border-bottom: none;}

	nav.navbar-default .navbar-nav li a {padding: 14px 12.5px; height: inherit;}
	nav.navbar-default .navbar-nav li a:hover { padding: 14px 12.5px; height: inherit; background-color: #f0eeea !important; color:#017780;}

	.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:hover { padding-bottom:13px; height:inherit;}

}

nav.navbar-default .navbar-nav .open a { color: #444;}

.megamenu-col:first-child { width: 215px; background-color: #01838c; padding: 10px 10px 0px 20px; color:#fff; margin-right: 10px;}

.megamenu-col span.button-md {background-color:#fff; color:#004f59}

.megamenu-col a { background-color:#ffcc00;}

nav.navbar-default .navbar-nav .open .megamenu-col a {background-color:transparent !important; }

.megamenu-col li a {color:#444 !important;}
.megamenu-col li a:hover, .megamenu-col li a:focus {color:#017780 !important;}
.megamenu-col a {color:#fff !important ;}
.megamenu-col a:hover, .megamenu-col a:focus { text-transform: underline !important;}

.megamenu-col .mlc-glyph, .dropdown-menu .glyphicon {
  display: inline-block; width: 23px;}

.dropdown-menu .glyphicon {
  font-size: 14px;
  color: #017780;
}

#sidekick-mobile .sidekick li a .sidekick-glyphicon { width:100% !important;}

.super-ezy-modal-video{
  .modal-video-placeholder(310px,160px);
}

/* Global styles for FAQ, Search result pages */

.search-component-wrapper,
.faq-component-wrapper {
  padding:60px 0px;
}
.search-component-wrapper .mlc-search h1,
.faq-component-wrapper .mlc-faqs h1 {
  display:none;
}
.search-component-wrapper .input-group input,
.faq-component-wrapper .input-group input {
  border-radius: 0px;
  height: 43px;
}
.search-component-wrapper .input-group .input-group-btn button,
.faq-component-wrapper .input-group .input-group-btn button {
  background-color: #c95109;
  color: #fff;
  display: inline-block;
  font-family: @font-family-heading;
  font-size: 20px;
  height: 43px !important;
  min-width: 155px;
  padding-left: 21px;
  padding-right: 21px;
  padding-top: 11px;
  text-align: center;
  border-radius: 0;
  border: 0px;
  line-height: 20px;
}

.search-component-wrapper #site-search,
.faq-component-wrapper #faqs-search {
  margin-bottom: 60px;
  width: 70%;
}

.search-component-wrapper .search-types,
.faq-component-wrapper .faqs-browse {
  width: 100%;
  width: 100%;
  border-bottom: 1px solid #c8c6c7;
}

.faq-component-wrapper a.faq-browse.active {
  background-color: #fff;
  border-top: 1px solid #c8c6c7 !important;
}

.search-component-wrapper .btn-group button {
  border-radius: 0px;
  color: #3b3b3b;
  margin-right: 0;
  padding: 10px 25px;
  background-color: #f0eeea;
  bottom: -1px;
}

.search-component-wrapper .btn-group button.search-type-selected {
  background-color: #fff;
  font-weight: bold;
  border-bottom: 1px solid #fff;
}
.search-component-wrapper #search-container,
.faq-component-wrapper #search-container {
  margin-top: 50px;
}
.search-result-container .result-items-container .panel-heading {
  border-bottom: 0px;
}

.search-result-container .result-items-container .didyoumeantext {
  margin-bottom: 20px;
}

#faq-results-list {
  padding-top:20px;
}
#faq-results-list .panel-heading {
  margin-bottom: 12px;
}

.faq-search-pagination .pagination,
.search-pagination .pagination {
  float:right;
}
.faq-search-pagination .pagination > li > a,
.faq-search-pagination .pagination > li > span,
.search-pagination .pagination > li > a,
.search-pagination .pagination > li > span {
  border: 0px;
  color: @navbar-default-link-color;
}
.faq-search-pagination .pagination > li,
.search-pagination .pagination > li {
  padding-left: 0px;
}
.faq-search-pagination .pagination > .active > a,
.faq-search-pagination .pagination > .active > span,
.faq-search-pagination .pagination > .active > a:hover,
.faq-search-pagination .pagination > .active > span:hover,
.faq-search-pagination .pagination > .active > a:focus,
.faq-search-pagination .pagination > .active > span:focus,
.search-pagination .pagination > .active > a,
.search-pagination .pagination > .active > span,
.search-pagination .pagination > .active > a:hover,
.search-pagination .pagination > .active > span:hover,
.search-pagination .pagination > .active > a:focus,
.search-pagination .pagination > .active > span:focus {
  background-color: #f7f7f7;
}

/* FAQs results */
.faqs-results .panel-heading .panel-title a {
  background-color: #F6F3EF !important;
  padding-bottom: 15px !important;
}
.faqs-results .panel-heading .panel-title a.collapsed {
  background-color: #fff !important;
}
.faqs-results .panel-heading .panel-title  {
  font-family: @font-family-sans-serif;
  font-size: 17px;
}
.faqs-results .panel {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
.faq-helpful-links {
  border-top: 1px solid #c8c6c7;
  margin-top: 35px;
  padding-top: 20px;
}
.faq-helpful-links ul {
  list-style: none;
  padding-left: 0;
}
.faq-helpful-links ul li {
  padding-left: 0;
}
#faq-answer-feedback-no {
  margin-left: 10px;
}

.faq-answer .modal-dialog {
     width: 992px ! important;
}

.faq-answer table {
  border: 0px none;
  margin-bottom: 20px;
}

.faq-answer table th {
  border-bottom: 1px solid #c8c6c2 ! important;
  padding: 10px;
}

.faq-answer table td {
  padding: 10px;
}

.modal-body .faq-answer table td {
  border-bottom:1px solid #c8c6c2 !important;
}

.faqs .panel-group {
  border-bottom:1px solid #c8c6c7;
}

.faqs .panel {
  margin-bottom: 0px !important;
}
.faqs .panel-heading a {
  background-color: #ffffff ! important;
  border-top: 1px solid #c8c6c7;
  color: #3b3b3b;
  background-color:#faf8f4 !important;
  padding-bottom:15px !important;
  padding-top:14px !important;
  padding-right:50px;
}

.faq-results-list-contents .panel-heading a {
  border-top: 1px solid #c8c6c7;
  color: #3b3b3b;
  padding-top:14px !important;
  padding-right:50px;
  background-color: #F6F3EF !important;
  padding-bottom: 15px !important;
  background-image: url("/content/dam/mlc/images/icons/icon_accordion_minus_drk.png");
  background-position:99% 10px;
}

.faqs .panel-heading .panel-title a {
  background-color: #F6F3EF !important;
  padding-bottom: 15px !important;
  background-image: url("/content/dam/mlc/images/icons/icon_accordion_minus_drk.png");
}
.faqs .panel-heading a.collapsed, .faq-results-list-contents .panel-heading a.collapsed {
  background-color: #ffffff ! important;
  background-position:99% 10px;
  padding-bottom: 15px !important;
  background-image: url("/content/dam/mlc/images/icons/icon_accordion_plus.png");
  padding-right:50px;
}

.faqs .panel-heading a.collapsed:hover, .faq-results-list-contents .panel-heading a.collapsed:hover {
  background-image: url("/content/dam/mlc/images/icons/icon_accordion_plus.png");
  background-position:99% 10px !important;
  padding-right:50px;
}

.faqs .panel-heading a:hover, .faq-results-list-contents .panel-heading a:hover {
   background-color: #FFFFFF ! important;
   color: #3b3b3b;
}

.faqs .panel .media-list .list-item-text, .faq-results-list-contents .panel .media-list .list-item-text {
  margin-top: 0px
}
.faqs .panel-collapse.collapse.in .media-list, .faq-results-list-contents .panel-collapse.collapse.in .media-list {
  padding-left: 18px;
  padding-right: 18px;
}

.search-banner .search-types {
  outline: medium none ! important;
}

.search-banner .search-types .btn {
  outline:none;
}

.pagination li a {
  color: #037691 ! important;
  cursor: pointer ! important;
  font-size: 14px !important;
}

.pagination li.active a, .resultpaging ul.navh li a.on:hover {
  color: #3b3b3b ! important;
  background-color:#ffffff !important;
}

@media screen and (max-width: 600px) {

  .search-banner .search-types .btn {
    width: 100%;
  }

  .faq-results-list-contents .panel-heading a.collapsed {
    padding-right: 30px;
  }

  .pagination {
    float: left ! important;
    margin-top:0;
  }

  #faqs-results-heading {
    margin-top: 16px ! important;
  }

  .faqs-results .panel-heading a.collapsed, .faqs-results .panel-heading a.collapsed:hover {
    padding-right: 30px;
  }
}

@media screen and (min-width: 601px) and (max-width: 767px) {

  .search-banner .search-types .btn {
    width: 100%;
  }

  .faq-results-list-contents .panel-heading a.collapsed {
    padding-right: 30px;
  }

  .pagination {
    float: left ! important;
    margin-top:0;
  }

  #faqs-results-heading {
    margin-top: 16px ! important;
  }
}

@media screen and (min-width: 768px) {

  .faq-results-list-contents .panel-heading a.collapsed {
    padding-right: 50px;
  }

  #faqs-results-heading {
    margin-top: 16px ! important;
  }
}

/* Embedded FAQ's */
.faq-category-container {
  .faq-category-subtitle {
     font-size: 15px;
     font-weight: normal;
    font-family: @font-family-sans-serif;
  }
  .faqs-contents {
    ul {
      li {
        padding-left:0px;
      }
    }

  }
}
.faq-category-dialog {
  table {
    border:0px;
  }
}

/* Emedded search */

.embedded-search-wrapper span#search-icon.glyphicon-search:before {
  content:"";
}

.embedded-search-wrapper .global_search_form .form-group button .glyphicon-search {
  top: auto;
  width: auto;
  color: #fff !important;
  padding-left: 35px;
  padding-right: 35px;
  font-family: Charlie-Std-SemiBold;
  font-size: 18px;
  height: auto;
}
.embedded-search-wrapper .global_search_form .form-group .btn-primary:after {
  border-bottom:0px;
}
.embedded-search-wrapper .global_search_form .form-group .mlc-glyph.icon-mlc-find-your-super {
  color: #fff;
  font-size: 20px;
  top: -14px;
  margin-right: 0px;
  padding: 4px 40px;
}
.embedded-search-wrapper .form-group input {
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 0px;
  color: #555;
  display: block;
  font-size: 15px;
  height: 35px;
  line-height: 1.42857;
  padding: 6px 12px;
  display: inline;
}
.embedded-search-wrapper .form-group button {
  padding:6px 12px;
  display: inline;
  height: 35px;
  margin: 0;
  padding: 0;
}


/* GH 03.03.15     */


.megamenu-col:first-child { margin-left:10px; margin-top: 12px; width: 215px; padding: 10px 20px 0 20px; margin-right: 10px; color: #fff;
background-color: #01838c;}


.megamenu-col:first-child span.button-sm {
color: #004f59;
text-align: center;
background-color: #FFffff; cursor:pointer; }

.megamenu-col:first-child span.button-sm:hover { background-color:#004651; color:#fff; }

.masthead-wrapper {margin-top:-1px}

nav.navbar-default .navbar-nav li a:hover {background-color:#fff;}

nav.navbar-default .navbar-nav li a {padding:10px 15px}

#megamenu-col-1 a {margin-top:-11px;}

.megamenu-col:first-child span.button-sm {margin-top:10px}

.megamenu-col:first-child {margin-left:30px; min-height:220px;}

.megamenu-col:first-child h4 {margin-top:15px}

.dropdown-menu h5 {font-size:16px; margin-top:10px}

@media (min-width:997px){
.megamenu-col.col-sm-3 {
width: 23%;
padding: 0 20px;}

}


/*left menu panel */

@media screen and (max-width: 992px){
.navbar-offcanvas {
position: fixed!important;
top: 0;
left: -250px;
z-index: 999;
width: 250px;
max-width: 100%;
height: 100%;
overflow-y: auto;
-webkit-overflow-scrolling: touch;
-webkit-transition: all .15s ease-in;
-o-transition: all .15s ease-in;
transition: all .15s ease-in;
}
}

@media (max-width: 992px) {
.visible-xs {display: block!important;}

.navbar-offcanvas.navbar-offcanvas-touch .navbar-header { background-color:#004552; height:55px;}

#icon-toggle-mobile-nav {padding-top:14px; color:#fff}


nav.navbar-default .navbar-nav li a { padding:14px 12.5px;}

nav.navbar-default .navbar-nav li a { padding:14px 12.5px;}

nav.navbar-default .navbar-nav li a:focus { padding:14px 12.5px !important;}

#topnav-mobile li a {border-bottom:1px #444 solid}

#site-select-mobile li a { padding:14px 12.5px; color:#3b3b3b; }

#site-select-mobile .nav-pills>li>a {background-color:#f0eeea;}

#site-select-mobile .nav-pills>li.active>a, .nav-pills>li.active>a:hover {background-color:#00828d; color:#fff !important;}

.megamenu-col.col-sm-3 {
width: 100%;
padding: 0 10px;}


}

#site-select-mobile {margin-left:0}


/*end left menu panel */

 /* END GH 03.03.15 */


.carousel-indicators li, .carousel-indicators .active {width:18px; height:18px; border-radius:0}


@media screen and (max-width:600px) {
.carousel-indicators li, .carousel-indicators .active {width: 12px; height: 12px; border-radius:0}

.carousel-indicators {bottom:20px;}

}


/* END GH 03.03.15 \   */


/* GH 05.03.15   */

/*05.03.15 */

.panel-heading a{ font-family: @font-family-sans-serif,Arial,Helvetica; background-position: 99% center;}

.panel-heading a.collapsed:hover {background-image: url(/content/dam/mlc/images/icons/icon_accordion_plus_wht.png);}

.panel-heading a.collapsed, .panel-heading a.collapsed:hover {background-position: 99% center;}



#sidekick-desktop .sidekick { -webkit-box-shadow: 0 0 12px rgba(0,0,0,.2); box-shadow: 0 0 12px rgba(0,0,0,.2);}

@media screen and (max-width: 992px){

   #global_search_field_mobile {border-radius:0; border-color:#fff; -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.0);
box-shadow: inset 0 1px 1px rgba(0,0,0,.0); margin:10px 0 0 10px;}
  #global_search_field_mobile .input-group {display:none;}

.global_search_form .form-group .glyphicon {font-size:18px;}

#icon-search-mobile-nav {right:-5px; top:-24px;}


#topnav-mobile li a:hover { background-color:#2d2d2d; text-decoration:none;}


#site-select-mobile .nav-pills>li>a:hover {color: #3b3b3b;background-color: #e6e4e0;}

.navbar {border:0}

.navbar-offcanvas.in { box-shadow: 0px 0px 4px #333;}

}

@media screen  and (max-width: 992px){

  .navbar-nav>li { width:100% }
  .navbar-header {float: none;}
  .navbar {border-radius:0}

  .form-inline .form-control {width:100%;}

  #icon-search-mobile-nav {top:-25px; right:5px;}

 /*close button left panel */
  .navbar-offcanvas.navbar-offcanvas-touch .navbar-toggle {position:absolute; top:15px; right:5px}

  nav.navbar-default .navbar-nav li a { font-size:17px; }

}

@media (max-width:1205px) {
nav.navbar-default .navbar-nav li a {
  padding-left:13px;padding-right:13px;
  font-size:18px;
}

}



.news-insights-panel .media-body a h4 {color:#3b3b3b; text-decoration:none;}
.news-insights-panel .media-body a h4:hover {color:#3b3b3b;  text-decoration:none; }

.news-insights-panel .media-body .blogEntryMoreLinkText a  {color:#037691;}

.news-insights-panel .media-body .blogEntryMoreLinkText a:hover  { color:#23527c; text-decoration:underline;}

.blog-masthead.wrapper-aqua { background-color:#01838c;}
.blog-masthead.wrapper-rubine { background-color:#ce0058; }

.multiple-columns.column.cf .glyphicon {color: #62605c;}

/*END GH 05.03.15    */

/* GH 06.03.15   */

/*Tabs */

.panel-heading a.collapsed:hover {background-image:
url(/content/dam/mlc/images/icons/icon_accordion_plus_wht.png);}

.panel-heading a {background-image: url(/content/dam/mlc/images/icons/icon_accordion_minus.png);}

.panel .media-list .list-item-text {padding-left:18px; padding-right:18px}


#sidekick-desktop .sidekick li a:hover {text-decoration:none; background-color:#00a9b2;}


#sidekick-desktop .sidekick li { text-decoration:none; padding:0; border-bottom:0;}

#sidekick-desktop .sidekick li a {display: block; padding: 15px 15px 15px 25px;}

#sidekick-desktop .sidekick-glyphicon {margin: -2px 24px 0 -2px; display: inline-block; width: 12px;}

#sidekick-desktop .sidekick-glyphicon.icon-mlc-login-to-secure-site{ position:relative; top:1px; left:-1px; font-size:17px;}

#sidekick-desktop .sidekick-glyphicon.icon-mlc-call-contactus { position:relative; left:-2px; top:1px; font-size:17px;}

#sidekick-desktop .sidekick-glyphicon.icon-mlc-news-and-insights { position:relative; left:-2px; top:1px; font-size:17px;}

#sidekick-desktop .sidekick-glyphicon.icon-mlc-prices-and-performances { position:relative; left:-2px; top:1px; font-size:14px;}

#sidekick-desktop .sidekick-glyphicon.icon-mlc-forms-and-documents { position:relative; left:0px; top:3px; font-size:19px;}


@media screen and (max-width:992px){

  #sidekick-desktop {display:none; }

  .search-component-wrapper #site-search,
  .faq-component-wrapper #faqs-search {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }
  .search-component-wrapper .input-group .input-group-btn button, .faq-component-wrapper .input-group .input-group-btn button {
    font-size: 17px;
    min-width: 60px;
  }

}

#megamenu-col-1 h4 {padding-top:15px;}

.dropdown-menu h5 {padding-top:9px}

.megamenu-col .mlc-glyph {color:#017780;}


#sidekick-mobile .sidekick li a:hover { text-decoration:none;}

#sidekick-mobile.collapse.in {
-webkit-box-shadow: 0 1px 2px 1px rgba(0,0,0,.1);
box-shadow: 0 1px 2px 1px rgba(0,0,0,.1);
}


/* END GH 06.03.15  */

.adviser-info-directive-wrapper {
  background-color:#333;
}
.important-info-wrapper {
  padding: 40px 0;
}

.in-the-news-panel {
    background-color: #FFF;
    padding:15px;
    height:200px;
}


.channel-news-insights-wrapper.section-wrapper .media {margin-top:0px}

.save-retirement .modal-header, .save-retirement .modal-footer {
     border: 0px none;
}

.save-retirement .modal-dialog {
     width: 800px;
}

.save-retirement .modal-content {
     border-radius: 0px;
}

.save-retirement .modal-content a {
     height: 90px;
     width: 150px;
}

.save-retirement .modal-content img {
     max-height: 50px;
}

.save-retirement .modal-content img {
     max-height: 50px;
}

.save-retirement .modal-content td:nth-child(1) img {
     margin-top: 10px ! important;
}

.save-retirement .modal-content td:nth-child(4) img {
     margin-top: 5px ! important;
}

.save-retirement .modal-body h3, .save-retirement .modal-body p {
     text-align: left;
}

.save-retirement .modal-body p {
     margin-bottom: 50px;
}


@media screen and (max-width: 800px) {
  .save-retirement .modal-dialog { width: auto; }
  .save-retirement .modal-content a { height: 100%; width: 100%; }
  .save-retirement .modal-content img { height: auto; width: 100%;  }
}

@media screen and (max-width: 600px) {
  .save-retirement .modal-dialog { width: 100%; }
  .save-retirement .modal-content a { height: 100%; width: 100%; }
  .save-retirement .modal-content img { height: 100%; width: 100%; }

}


.wrapper.wrapper-top-row .form-group {margin-bottom:0 !important}

.adviser-info-directive-wrapper .channel-info-directive.first {
border-right: 1px solid #474747;}


@media screen and (max-width: 768px) {

.adviser-info-directive-wrapper .channel-info-directive.first {
border-right: 0px solid #474747;}

.adviser-info-directive-wrapper .channel-info-directive.first {
border-bottom: 1px solid #474747;}

}

.modal-align-center > div > div:nth-child(1)  {
    text-align:center;
    height:300px
}

.modal-align-center > div > div:nth-child(2)  {
    text-align:center;
    padding-bottom:20px;

}

.modal-align-center .modal-dialog {
    text-align:left;
}

@media screen and (max-width: 992px) {
    .modal-align-center > div > div:nth-child(1)  {height:350px}
}

.nav-tabs>li.active>a {font-weight:bold;}

.product-page-col-btn span.button-sm {margin-top:0; margin-bottom:0}

#mlc-header-search-box .global_search_form .form-group .btn-primary {color:#fff;}

.latest-news-cta-btn {margin-top:40px !important;}


#icon-toggle-mobile-header {position:relative; top:12px; font-size:32px; color:#3b3b3b;}

#icon-user-mobile-header {position:relative; top:5px; right:8px; font-size:32px; color:#3b3b3b;}


/*right advise tab fixed */

.choose-advice-tabs ul {margin-top:16px}

.choose-advice-tabs .nav-tabs>li>a {font-size:20px; font-family: "Charlie-Std-Medium" !important; }

.choose-advice-tabs .nav-tabs>li>a:hover {color:#037691 !important;}

.choose-advice-tabs ul.nav-tabs li.active a {
  margin-bottom: -10px !important;
}

.choose-advice-tabs ul.nav-tabs li a {
  margin-bottom: 12px !important;
}

.choose-advice-tabs ul.nav-tabs {clear:both !important;  margin-bottom:220px !important;}


.choose-advice-tabs h4 {margin-bottom: 30px !important;}


.choose-advice-tabs .table tr:nth-child(odd) { border-bottom: 2px #fff solid !important;}



.choose-advice-tabs .table tr td{ padding-top:5px !important;}

.choose-advice-tabs .table tr:nth-child(odd) td {padding-top:40px !important;}

.choose-advice-tabs .table tr:nth-child(even) td {padding-bottom:40px !important;}




@media screen and (min-width: 1209px){
.choose-advice-tabs ul.nav-tabs li:first-of-type {
padding-right: 0px !important;
}

.choose-advice-tabs ul.nav-tabs li { width:310px !important; margin-right:10px !important;}

.choose-advice-tabs ul.nav-tabs li:nth-of-type(2) {padding-right:0 !important;}

.choose-advice-tabs ul.nav-tabs li:nth-of-type(3) {padding-right:0 !important;}

.choose-advice-tabs .nav-tabs>li.active>a {text-align:center !important;}

.choose-advice-tabs .nav-tabs>li>a {text-align:center !important;}

.choose-advice-tabs ul.nav-tabs li a {margin-top: 170px !important;}

}

.choose-advice-tabs .nav>li>a:hover, .choose-advice-tabs .nav>li>a:focus {background-color:#fff !important;}

@media screen and (min-width: 992px) and (max-width: 1209px){
.choose-advice-tabs ul.nav-tabs li:first-of-type {
padding-right: 0px  !important;
}

.choose-advice-tabs ul.nav-tabs li { width:300px !important; margin-right:10px !important;}

.choose-advice-tabs ul.nav-tabs li:nth-of-type(2) {padding-right:0 !important;}

.choose-advice-tabs ul.nav-tabs li:nth-of-type(3) {padding-right:0 !important; padding-top:0 !important;}

.choose-advice-tabs .nav-tabs>li.active>a {text-align:center !important; }

.choose-advice-tabs .nav-tabs>li>a {text-align:center !important;}

.choose-advice-tabs ul.nav-tabs li:first-of-type:before,
.choose-advice-tabs ul.nav-tabs li:nth-of-type(2):before,
.choose-advice-tabs ul.nav-tabs li:nth-of-type(3):before
{width:300px !important; background-position:center !important; }

.choose-advice-tabs ul.nav-tabs {clear:both !important;  margin-bottom:200px !important;}

.choose-advice-tabs ul.nav-tabs li a {margin-top: 170px !important;}

}

@media screen and (max-width: 992px){

.choose-advice-tabs .media-list{ padding-top:50px !important}

.choose-advice-tabs ul.nav-tabs li{text-align:center !important;}

.choose-advice-tabs ul.nav-tabs li.active:after {right:0 !important}

  .choose-advice-tabs ul.nav-tabs {clear:both !important;  margin-bottom:140px !important;}

}
#global_search_form_mobile .mlc-glyph.icon-mlc-find-your-super,
#global_search_form .mlc-glyph.icon-mlc-find-your-super {display: inline-block !important; padding:15px 15px 17px 15px; margin-top:0px; font-size:20px;
 color:#bc4401; background-color:#faf8f4; cursor:pointer ; position: absolute; right: 10px; top: 0;}

/* Imran */
.modal-align-center .modal-header,
.modal-align-center .modal-footer {
     border:0px;
}
.cf-quicklinks {
   padding-bottom: 42px ! important;
}

.cf-video-header h2, .cf-video-header h3 {
    padding:0px !important;
    margin:0px !important;
}

.tabbed-blue-text li, .tabbed-blue-text ul {
     border: 0px ! important;
}

.tabbed-blue-text a {
  background-image: url("../../../../../content/dam/mlc/images/icons/icon_plus.png");
  background-color:#FFF;
  background-repeat: no-repeat;
  color: #01838C ! important;
  height: 30px;
  padding: 2px 0px 0px 40px ! important;
}

.tabbed-blue-text .media {
  padding: 0px;
}

.cf-hope-accordion .panel > div:nth-child(2){
  min-height:250px;
  padding-top:30px;
}
.cf-nav .active a {
 background-color: #F0EEEA ! important;
 border-bottom: 3px solid #E85100;
 border-radius: 0px;
}
.cf-nav ul li {
 margin-bottom: 0px;
 padding-left: 10px;
 padding-right: 10px;
 padding-top:2px;
}
.mlc-community-logo {
 padding-bottom:0px;
 padding-top:10px;
}
.centre-align-all * {
     display: block;
}
.marginbottom10 {
	margin-bottom:10px;
}


.h1self{ margin-top:55px!important}

@media screen and (min-width: 600px) and (max-width: 992px){

.h1self{ margin-top:95px!important}

}


.wide-txt .carousel-caption h2 {margin-bottom:40px;}


@media screen and (min-width: 600px) and (max-width: 670px) {

  .wide-txt .carousel-caption h2 {font-size:36px;}

}

@media screen and (max-width: 600px){
.wide-txt .orange{color:#fff;}

.charcoal {color:#fff;}
.persistent-charcoal .charcoal {
    color: #3b3b3b;
}
}

@media screen and (min-width: 768px) {
    .tiles .icon-mlc-chevron {display:none !important;}
}

.tiles .icon-mlc-chevron {
     color: #AAA8A4;
     display: inline-block;
     font-size: 16px;
     position: absolute;
     right: 0px;
     text-align:right;
     width: 100%;
}

#faqs-browse .nav-tabs li + li + li + li +li a.active {
  border-right:1px solid #c8c6c2 !important;
}

.findadviser_banner_search input:focus {
     border: 1px solid #c95109 ! important;
     box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px rgba(201, 81, 19, 0.6) ! important;
}

.box-content p {font-size:14.5px;}

@media screen and (min-width: 600px) and (max-width: 992px){
.channel-cta-wrapper .cf {margin-bottom:10px}
}

@media (max-width: 992px){
.nav-pills>li.active>a,  .nav-pills>li.active>a:hover, .nav-pills>li.active>a:focus {background-color:#2a2a2a}
}

/*Left panel issue with megamenu */

@media (max-width: 992px){
.hidden-xs { display: none !important;}
.dropdown-menu {float:none !important;}
.dropdown-menu { position: static !important;}
}

/*carousel no padding left and right */

.wrapper.carousel-content-list-wrapper .col-xs-12 { padding-left:0; padding-right:0;}

// Survey Modal Styles

.customer-survey .modal-header {
  background-color: @primary-color-aqua;
  padding: 20px;
  text-align:center;
}

.customer-survey .modal-header .modal-title, .customer-survey .modal-header .close {
  color: #ffffff;
  opacity: 1 ! important;
  font-size:26px;
}

.customer-survey .modal-content {
  border-radius:0 !important;
}

.customer-survey .modal-body .col-custom .media {
  padding-top:15px;
}

.customer-survey .modal-footer {
  border-top: 1px solid #c8c6c2 ! important;
  margin-left: 20px;
  margin-right: 20px;
  padding: 20px;
}

.customer-survey .modal-footer .disclaimer-button {
  background-color: #ffffff;
  border: 0px none;
  color: @default-link-color;
  display: block;
  margin: 0px auto;

}

.customer-survey .modal-footer .disclaimer-button:hover {
  background-color: #ffffff;
}

.super-ezy-modal-video{
  .modal-video-placeholder(310px,160px);
}

#global_search_form_mobile .form-group .global_search_field {width:98.8%; padding-left:20px; height:54px}
#global_search_form_mobile .form-group .input-group .input-group-btn button {right:0;height: 54px;}

@media screen and (max-width: 992px){
#global_search_form_mobile .form-group .input-group .input-group-btn button {padding-bottom:23px !important;}
}

#global_search_form_mobile .mlc-glyph.icon-mlc-find-your-super {background-color:#fff;}

.wrapper.wrapper-dark #global_search_form .mlc-glyph.icon-mlc-find-your-super {display:none !important;}

//Phone an adviser styles added - 09/02/2016 //

.phone-adviser .panel-group {
  margin-bottom: -10px !important;
}

.phone-adviser .panel-heading a {
  background-color: #ffffff ! important;
  background-image: none;
  color: #3b3b3b;
  padding-left: 0px;
  padding-top:0;
}

.phone-adviser .panel-heading a:hover {
  background-color: #ffffff ! important;
  background-image: none;
  text-decoration: underline;
}

.phone-adviser .panel-heading a.collapsed:hover {
  color: #3b3b3b;
}

.phone-adviser .panel .media-list {
  margin-bottom:0;
}

.phone-adviser .media-list .media-left {
  padding-right: 0px;
}

.phone-adviser .media-list .media-body .list-item-text {
  padding-left: 0px;
  padding-top: 10px;
}

#lpChat img {
    max-height: none;
}

//Styles to fix button (button0md) title

.page-header-container span.button-md {
  padding-top:10px;
}

/************* Silo uplift ****************/
.masthead-wrapper {
	.masthead-adviser {
		h1 {
			font-size: 34px;
			font-family: Charlie-Std-Medium;
		}
	}
}
.panel-default.panel-single.dark-panel-single {
	.panel-heading {
		background-color: #181816;
		color: #fff;
		h3 {
			font-family: Charlie-Std-Medium;
			color: #fff;
			font-size: 20px;
			text-align: center;
			padding: 15px 0;
			margin: 0;
			border: 0px;
		}
	}
	.panel-body {
		border: 1px solid #99948a;
		border-top: 0px;
		text-align: center;
		padding-top: 40px;
		padding-bottom: 20px;
		hr {
			border-top: 1px solid #99948a;
		}
	}
}
.border-button-r-chevron {
	.border-button-md {
		&:after {
			border-style: solid;
			border-width: 0.1em 0.1em 0 0;
			content: '';
			display: inline-block;
			height: 0.45em;
			position: relative;
			top: 0.25em;
			vertical-align: top;
			width: 0.45em;
			left: 10px;
			transform: rotate(45deg);
		}
		padding: 11px 35px 11px 21px !important;
	}
}
.adviser-tabs-justified {
	border-bottom: 1px solid #99948A;
	.bootstrap-nav-secondary {
		.nav-tabs {
			margin-top: 0px;
			margin-bottom: 0px;
			>li {
				border: 0px;
				border-bottom: 1px solid #99948A;
				background-color: #fff;
				>a {
					color: #000;
				}
			}
			>li.active {
				border: 0px;
				border-bottom: 2px solid #C95109;
			}
		}
	}
}
.masthead-xs {
	height: 136px;
}
.background-size-cover {
	-webkit-background-size: cover !important;
	background-size: cover !important;
}
.full-width-hr {
	.media-body {
		display: block;
	}
}
.bg-color-161818 {
	background-color: #161818 !important;
}
.bg-color-faf8f4 {
	background-color: #faf8f4 !important;
}
.deep-orange {
	color: #741915;
}
.subheading-partial-underline {
	display: block;
	margin-bottom: 20px;
	&:after {
		display: block;
		clear: both;
		content: "";
		position: relative;
		left: 0;
		bottom: 0;
		height: 1px;
		width: 55px;
		border: 1px solid #c95109;
		margin-top: 15px;
	}
}
.portfolio-col-callout {
	padding-bottom: 40px;
	margin-bottom: 60px;
	border-bottom: 1px solid #CBCBCB;
	.portfolio-left-col-callout {
		h2 {
			font-family: Charlie-Std-Medium;
			font-size: 38px;
			margin-bottom: 10px;
			line-height: 1.2;
		}
		h4 {
			font-family: Charlie-Std-Medium;
			font-size: 18px;
			line-height: 1.5;
		}
	}
}
.portfolio-col-callout.last {
	margin-bottom: 0px;
	border-bottom: 0px;
}
@media (min-width: 768px) {
	.vertical-align {
		display: flex;
		align-items: center;
	}
}
@media (min-width: 1200px) {
	.masthead-wrapper {
		.masthead-adviser {
			h1 {
				margin-top: 95px !important;
			}
		}
	}
}
@media (min-width: 600px) and (max-width: 1199px) {
	.masthead-wrapper {
		.masthead-adviser {
			h1 {
				margin-top: 35px !important;
			}
		}
	}
}
@media (max-width: 993px) {
	.adviser-tabs-justified {
		.nav-tabs {
			height: auto;
		}
		border-bottom: 0px;
	}
	.masthead-xs {
		h1 {
			padding-top: 30px;
		}
	}
}
@media (min-width: 768px) and (max-width: 992px) {
	.nav-tabs.nav-justified {
		>li {
			display: inline-block;
			width: 100%;
			float: left;
		}
	}
}


// Custom spacing
.spacing-pt-50 {
  padding-top: 50px !important;
}
.spacing-pr-50 {
  padding-right: 50px !important;
}
.spacing-pb-50 {
  padding-bottom: 50px !important;
}
.spacing-pl-50 {
  padding-left: 50px !important;
}
.spacing-mt-50 {
  margin-top: 50px !important;
}
.spacing-mr-50 {
  margin-right: 50px !important;
}
.spacing-mb-50 {
  margin-bottom: 50px !important;
}
.spacing-ml-50 {
  margin-left: 50px !important;
}
.spacing-pt-45 {
  padding-top: 45px !important;
}
.spacing-pr-45 {
  padding-right: 45px !important;
}
.spacing-pb-45 {
  padding-bottom: 45px !important;
}
.spacing-pl-45 {
  padding-left: 45px !important;
}
.spacing-mt-45 {
  margin-top: 45px !important;
}
.spacing-mr-45 {
  margin-right: 45px !important;
}
.spacing-mb-45 {
  margin-bottom: 45px !important;
}
.spacing-ml-45 {
  margin-left: 45px !important;
}
.spacing-pt-40 {
  padding-top: 40px !important;
}
.spacing-pr-40 {
  padding-right: 40px !important;
}
.spacing-pb-40 {
  padding-bottom: 40px !important;
}
.spacing-pl-40 {
  padding-left: 40px !important;
}
.spacing-mt-40 {
  margin-top: 40px !important;
}
.spacing-mr-40 {
  margin-right: 40px !important;
}
.spacing-mb-40 {
  margin-bottom: 40px !important;
}
.spacing-ml-40 {
  margin-left: 40px !important;
}
.spacing-pt-35 {
  padding-top: 35px !important;
}
.spacing-pr-35 {
  padding-right: 35px !important;
}
.spacing-pb-35 {
  padding-bottom: 35px !important;
}
.spacing-pl-35 {
  padding-left: 35px !important;
}
.spacing-mt-35 {
  margin-top: 35px !important;
}
.spacing-mr-35 {
  margin-right: 35px !important;
}
.spacing-mb-35 {
  margin-bottom: 35px !important;
}
.spacing-ml-35 {
  margin-left: 35px !important;
}
.spacing-pt-30 {
  padding-top: 30px !important;
}
.spacing-pr-30 {
  padding-right: 30px !important;
}
.spacing-pb-30 {
  padding-bottom: 30px !important;
}
.spacing-pl-30 {
  padding-left: 30px !important;
}
.spacing-mt-30 {
  margin-top: 30px !important;
}
.spacing-mr-30 {
  margin-right: 30px !important;
}
.spacing-mb-30 {
  margin-bottom: 30px !important;
}
.spacing-ml-30 {
  margin-left: 30px !important;
}
.spacing-pt-25 {
  padding-top: 25px !important;
}
.spacing-pr-25 {
  padding-right: 25px !important;
}
.spacing-pb-25 {
  padding-bottom: 25px !important;
}
.spacing-pl-25 {
  padding-left: 25px !important;
}
.spacing-mt-25 {
  margin-top: 25px !important;
}
.spacing-mr-25 {
  margin-right: 25px !important;
}
.spacing-mb-25 {
  margin-bottom: 25px !important;
}
.spacing-ml-25 {
  margin-left: 25px !important;
}
.spacing-pt-20 {
  padding-top: 20px !important;
}
.spacing-pr-20 {
  padding-right: 20px !important;
}
.spacing-pb-20 {
  padding-bottom: 20px !important;
}
.spacing-pl-20 {
  padding-left: 20px !important;
}
.spacing-mt-20 {
  margin-top: 20px !important;
}
.spacing-mr-20 {
  margin-right: 20px !important;
}
.spacing-mb-20 {
  margin-bottom: 20px !important;
}
.spacing-ml-20 {
  margin-left: 20px !important;
}
.spacing-pt-15 {
  padding-top: 15px !important;
}
.spacing-pr-15 {
  padding-right: 15px !important;
}
.spacing-pb-15 {
  padding-bottom: 15px !important;
}
.spacing-pl-15 {
  padding-left: 15px !important;
}
.spacing-mt-15 {
  margin-top: 15px !important;
}
.spacing-mr-15 {
  margin-right: 15px !important;
}
.spacing-mb-15 {
  margin-bottom: 15px !important;
}
.spacing-ml-15 {
  margin-left: 15px !important;
}
.spacing-pt-10 {
  padding-top: 10px !important;
}
.spacing-pr-10 {
  padding-right: 10px !important;
}
.spacing-pb-10 {
  padding-bottom: 10px !important;
}
.spacing-pl-10 {
  padding-left: 10px !important;
}
.spacing-mt-10 {
  margin-top: 10px !important;
}
.spacing-mr-10 {
  margin-right: 10px !important;
}
.spacing-mb-10 {
  margin-bottom: 10px !important;
}
.spacing-ml-10 {
  margin-left: 10px !important;
}
.spacing-pt-5 {
  padding-top: 5px !important;
}
.spacing-pr-5 {
  padding-right: 5px !important;
}
.spacing-pb-5 {
  padding-bottom: 5px !important;
}
.spacing-pl-5 {
  padding-left: 5px !important;
}
.spacing-mt-5 {
  margin-top: 5px !important;
}
.spacing-mr-5 {
  margin-right: 5px !important;
}
.spacing-mb-5 {
  margin-bottom: 5px !important;
}
.spacing-ml-5 {
  margin-left: 5px !important;
}
.spacing-pt-0 {
  padding-top: 0px !important;
}
.spacing-pr-0 {
  padding-right: 0px !important;
}
.spacing-pb-0 {
  padding-bottom: 0px !important;
}
.spacing-pl-0 {
  padding-left: 0px !important;
}
.spacing-mt-0 {
  margin-top: 0px !important;
}
.spacing-mr-0 {
  margin-right: 0px !important;
}
.spacing-mb-0 {
  margin-bottom: 0px !important;
}
.spacing-ml-0 {
  margin-left: 0px !important;
}


/* CTA nav button */
.nav-cta-aqua li.last, .nav-cta-aqua li.last:hover {
    background-color: @primary-color-aqua !important;
    margin-bottom:0px;
    height:41px;
}
.nav-cta-aqua li.last a {
    color:#fff !important
}
nav.navbar-default.nav-cta-aqua .navbar-nav li.last a:hover, nav.navbar-default.nav-cta-aqua .navbar-nav li.last a:focus {
    background-color: @primary-color-aqua-hover;
}

.nav-cta-orange li.last, .nav-cta-orange li.last:hover {
    background-color: @primary-color-orange !important;
    margin-bottom:0px;
    height:41px;
}
.nav-cta-orange li.last a {
    color:#fff !important
}
nav.navbar-default.nav-cta-orange .navbar-nav li.last a:hover, nav.navbar-default.nav-cta-orange .navbar-nav li.last a:focus {
    background-color: @primary-color-orange-hover;
}

.nav-cta-rubine li.last, .nav-cta-rubine li.last:hover {
    background-color: @primary-color-rubine !important;
    margin-bottom:0px;
    height:41px;
}
.nav-cta-rubine li.last a {
    color:#fff !important
}
nav.navbar-default.nav-cta-rubine .navbar-nav li.last a:hover, nav.navbar-default.nav-cta-rubine .navbar-nav li.last a:focus  {
    background-color: @primary-color-rubine-hover;
}

@media screen and (max-width: 992px) {
  .cta-nav li.last {
    margin-left: 0px !important;
  }
  .cta-nav li.last, .cta-nav li.last:hover {
    height: auto !important;
  }
  nav.navbar-default.nav-cta-aqua .navbar-nav li.last a,
  nav.navbar-default.nav-cta-aqua .navbar-nav li.last a:hover, nav.navbar-default.nav-cta-aqua .navbar-nav li.last a:focus {
      background-color: @primary-color-aqua !important;
  }
  nav.navbar-default.nav-cta-orange .navbar-nav li.last a,
  nav.navbar-default.nav-cta-orange .navbar-nav li.last a:hover, nav.navbar-default.nav-cta-orange .navbar-nav li.last a:focus {
      background-color: @primary-color-orange !important;
  }
  nav.navbar-default.nav-cta-rubine .navbar-nav li.last a,
  nav.navbar-default.nav-cta-rubine .navbar-nav li.last a:hover, nav.navbar-default.nav-cta-rubine .navbar-nav li.last a:focus {
      background-color: @primary-color-rubine !important;
  }
}

//Value of advice form styles

.voa-hero-banner p {
  font-size: 24px;
  font-family: "Charlie-Std-Medium";
}

.voa-lists {
  li {
    list-style-type: none;
    margin-left: 10px;

    &:before {
      content: "";
      width: 22px;
      height: 22px;
      background-image: url('/content/dam/mlc/images/content/advice/value-of-advice/check-circle.png');
      display: block;
      background-repeat: no-repeat;
      position: absolute;
      left: 10px;
      margin-top: 2px;
    }
  }
}

.wrapper-voa-form {
  padding-top: 60px;
}

.value-of-advice {
  .row-body {
    p:last-child {
      margin-bottom: 0;
    }
  }

  .wrapper-voa-content {
    padding-top: 60px;
    padding-bottom: 60px;

    h3 {
      font-size: 20px;
      width: 90%;
    }
  }

  .voa-button {
    background-color: @primary-color-orange;
    color: #ffffff;
    font-family: @font-family-heading;
    font-size: 20px;
    height: 43px ! important;
    margin-top: 20px;
    min-width: 155px;
    padding-left: 21px;
    padding-right: 21px;
    text-align: center;
    display:inline-block;
    border-radius: 0;
    border: 0;
    padding-top: 8px;
  }
  
  .voa-button:hover {
    background-color: @primary-color-orange-hover;
    color: #ffffff;
    text-decoration: underline;
  }

  .teaser-content {
    margin-top: 20px;

    h3 a {
      color: @default-color;
    }
  }
}

@media screen and (max-width: 991px) {
  .value-of-advice {
    .wrapper-voa-content {
      padding-top: 30px;
      padding-bottom: 30px;

      h3 {
        width: 100%;
      }
    }
  }
  
  .wrapper-voa-form {
    padding-top: 30px;
  }
}

@media screen and (max-width: 767px) {
  .voa-hero-banner p {
    font-size: 16px;
  }

  .media.spacing-mt-30-mobile {
    margin-top: 30px;
  }

  .teaser-content {
    margin-bottom: 30px;
  }

  .voa-button {
    min-width: 110px;
    padding: 8px;
  }

  .hide-on-mobile {
    display: none;
  }
}

@media screen and (max-width: 599px) {
  .masthead-sm .voa-button {
    background-color: transparent;
    border: 3px solid #ffffff;
    min-width: 60px;
    padding: 5px 8px;
  }
}

// MLC tabs style tweak

.tabs {
  .nav-tabs {
    margin-top: 0;
    margin-bottom: 60px;

    li:last-child.active {
      border-right: 1px solid #dddddd;
    }
  }

  .media {
    padding-left: 0;
  }
}

@media screen and (max-width: 991px) {
  .tabs {
    .nav-tabs {
      height: 100%;
      margin-bottom: 30px;
      border-bottom: 0;

      li {
        &.active {
          border-top: 0;
          border-bottom: 1px solid #dddddd;
        }

        &:first-child.active {
          border-top: 1px solid #dddddd !important;
        }
      }

      li:last-child.active {
        border-right: 0;
      }
    }
  }
}

// Custom styles

@import "components/mlc-ltd-campaign.less";
@import "components/fandb.less";
@import "components/mlc-ltd-search.less";
@import "templates/mlc-ltd-community-foundation.less";
@import "components/mlc-ltd-megamenu.less";
@import "components/mlc-day2-lists.less";
@import "components/embedvigform.less";
@import "components/carousel.less";
@import "components/mlc-ltd-find-an-adviser.less";
@import "components/akamai-video.less";
@import "components/campaign-unchanging.less";
@import "components/retirement-hub.less";
@import "components/mysuper-tabs.less";
@import "components/nps-form.less";
@import "components/supersizer.less";
@import "fontawesome.less";
@import "components/spacer.less";
@import "components/direct-super-ssf.less";
@import "components/super-to-pension.less";
@import "theme-mlc-brand.less";


.test-theme-less h5 {
  color:red;
}


