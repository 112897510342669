.wrapper-adviser-search {
     padding-bottom: 40px ! important;
}

form#advsearch {
     padding: 40px 0 0 0;
}
#advsearchsub {
  margin-top:10px;
}

.advcol1{
  width:150px !important;
  line-height:28px;
}

#advsearchsub span.ui-button-text {
  background: none repeat scroll 0% 0% #c95109 ! important;
  border-radius: 0px ! important;
  box-shadow: 0px 0px 0px 0px ! important;
  text-shadow: 0px 0px;
  width: 150px;
  padding-top:9px;
  padding-bottom:9px;
  margin-left:10px;
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#c95109',endColorstr='#c95109') !important;
}

.advlabel {
     font-size: 15px ! important;
}

.findadviser_banner_search input {
  border-radius: 0px;
  padding: 8px 6px ! important;
  font-size:15px !important;
}

.findadviser_banner_search select {
  height:35px !important;
  border-radius:0;
  font-size:15px !important;
}

.findadviser_banner_search input#loc, .findadviser_banner_search input#q {
  font-size:15px !important;
}

.advcol5 {
  width: 200px ! important;
}

.didyoumeantext {
     display: none !important;
}

.resultcontainerwrap {
     padding-left: 0px;
}

.resultpaging {
     background-color: #ffffff ! important;
  font-size:14px !important;
}

.resultpaging p {
     font-weight: bold;
     margin-bottom: 5px;
     margin-top: 5px ! important;
}

.resultpaging ul.navh {
     margin: -35px 0px 0px ! important;
}

.resultpaging ul.navh li a {
     color: #037691;
     font-size: 12px;
}

.resultpaging ul.navh li a.on, .resultpaging ul.navh li a.on:hover {
     background-color: #ffffff;
     color: #3b3b3b ! important;
}

ol.searchresults {
     margin: 0px ! important;
     padding: 0px;
}

.resultpage ol.searchresults li {
     border-bottom: 0 ! important;
     border-top: 0;
     margin-bottom: 0px ! important;
     padding-left: 10px;
     padding-top: 20px;
}

.resultpage ol.searchresults li:first-child {
     border-bottom: 0px none ! important;
}

.resultitem {
  .advisertitle, .adviseraddress, .adviserdetail, .phoneAdviserButton {
    font-size:15px;
  }
}

a.searchresultlink {
     color: #037691;
     text-decoration: none;
}

a.searchresultlink:hover {
     text-decoration: underline;
}

.hoverAdviser {
  background-color:#faf8f4 !important;
}

//Misc

.find-adv-search .global_search_form .form-group label {
  border: 1px solid red;
  display: none;
  width: 0px;
}

.find-adv-search .global_search_form .form-group .mlc-glyph.icon-mlc-find-your-super {
  background-color: #faf8f4;
  color: #bc4401;
  cursor: pointer;
  display: block;
  font-size: 20px;
  margin-top: 0px ! important;
  padding: 15px 15px 16px;
}

.find-adv-search .global_search_form .form-group .form-control {
  margin-right: 0px ! important;
}

.find-adv-search .global_search_form .form-group .search-icon {
  position:relative !important;
  left:-1px;
}

@media screen and (min-width: 601px) and (max-width:991px) {

  .resultpaging ul.navh {
     margin: -30px 0px 0px ! important;
  }
}

// Updated styles for Find an adviser added 28/01/2016 //

.wrapper-adviser-search .col-custom .container {
  margin-bottom: 40px;
}

#findadviserinterface {
  padding-left: 0px;
  padding-right: 0px;
}

#findadviserinterface .row {
  margin-left: 0px;
  margin-right: 0px;
}

form#advsearch .advlabel {
  font-size: 15px;
}

.findadviser_banner_search_inputs input {
  border-radius: 0px ! important;
  padding: 8px 6px ! important;
}

.findadviser_banner_search .findadviser_banner_search_inputs input#loc,
.findadviser_banner_search .findadviser_banner_search_inputs input#q {
  font-size: 15px ! important;
}

.findadviser_banner_search_inputs select {
  border-radius: 0px ! important;
  font-size: 15px ! important;
  height: 35px ! important;
}

form#advsearch #advsearchsub {
  margin-top: 0;
  height:36px !important;
}

form#advsearch .advanced {
  padding-bottom:40px;
}

.wrapper-adviser-search  form#advsearch .advanced a {
 background-image: url("/content/dam/mlc/images/icons/icon-advoptions.png");
 background-position: 0px 1px;
 background-repeat: no-repeat;
 padding-left: 20px;
}

.wrapper-adviser-search form#advsearch .advanced a[aria-expanded="true"] {
  background-image: url("/content/dam/mlc/images/icons/icon-advoptionsdown.png");
  background-position: 0px 2px;
  background-repeat: no-repeat;
  padding-left: 20px;
}

.wrapper-adviser-search form#advsearch .advanced .advancedOptions {
  border-top: 2px solid #d8d6d2;
  margin-top: 20px;
  padding-top: 20px;
}
#quickSearch {
  padding-top:20px !important;
}

.searchcontainer {
  background-color: #faf8f4;
  margin-bottom: 20px;
}

.licenseefilter {
  margin-bottom: 0px ! important;
  padding-bottom: 40px;
}

.licenseefilter .licFilterTitle, .licenseefilter .licenseeFilterBox {
  margin-left: -10px;
}

.licenseefilter .licFilterTitle {
  background-color: #ffffff;
  margin-bottom: 0px;
  margin-right: -10px;
  padding-bottom: 22px;
}

.licenseefilter .licenseeFilterBox {
  border: 0px;
  height: auto ! important;
  margin-left: 10px;
  padding-top: 40px;
}

.licenseefilter .licenseeFilterBox .licenseeFilterCheckbox label.licFilterLabel {
  margin-left: 0px;
}

.didyoumeantext {
  font-size: 15px ! important;
}

.resultcontainerwrap {
  padding-left: 0px ! important;
}

.resultpaging {
  background-color: #ffffff ! important;
  font-size: 14px ! important;
}

.resultpaging p {
  font-weight: 700;
  margin-bottom: 5px;
  margin-top: 5px ! important;
}

.resultpaging ul.navh {
  margin: -50px 0px 0px ! important;
}

.resultpaging ul.navh li a {
  color: #037691;
  font-size: 12px;
}

.resultpaging ul.navh li a.on, .resultpaging ul.navh li a.on:hover {
  background-color: #ffffff;
  color: #3b3b3b ! important;
}

ol.searchresults {
 margin-left: 0px;
 padding-left: 0px;
}

.adviserResult .adviseraddress, .adviserResult .adviserdetail {
  font-size: 15px;
}

.adviserResult .adviserdetail {
  margin-top: 10px;
}

.adviserResult .adviserdetail br {
  margin-bottom: 10px;
}

@media screen and (min-width: 768px) {
 .wrapper-adviser-search .container {
   padding-left: 0px;
 }

  form#advsearch {
    background-color: #faf8f4 ! important;
    padding-left: 9999px;
    padding-right: 9999px;
    margin-right: -9999px;
    margin-left: -9999px;
  }

  form#advsearch fieldset {
   min-height:120px !important;
  }

  form#advsearch .advanced {
   margin-top:20px !important;
  }
}

@media screen and (max-width: 767px) {

  .wrapper-adviser-search .container {
    padding-left: 0px;
    padding-right: 0px;
  }

  form#advsearch {
    background-color: #faf8f4;
    padding: 20px 10px 10px 10px;
    margin-bottom: 0px;
  }

  form#advsearch .advcol1, form#advsearch .advcol3 {
    width: 100% ! important;
  }

  form#advsearch .advcol2 {
    width: 100% ! important;
    margin-bottom: 10px;
  }

  .findadviser_banner_search .findadviser_banner_search_inputs input,
  .findadviser_banner_search .findadviser_banner_search_inputs select {
    width: 300px ! important;
  }

  form#advsearch .advcol3 {
    margin-left: 0px ! important;
  }

  form#advsearch .advanced {
    margin-bottom: 10px;
    margin-top: 40px;
  }
  .searchcontainer .licenseefilter .col-sm-12 {
    width: 100%;
  }
  .searchcontainer .licenseefilter h2.licFilterTitle {
    padding-top: 20px;
    padding-left: 10px;
  }

  form#advsearch .advcol5 {
    margin-left: 0px ! important;
    top: 200px;
  }
}

@media screen and (min-width: 601px) and (max-width: 767px) {

  .findadviser_banner_search .findadviser_banner_search_inputs input,
  .findadviser_banner_search .findadviser_banner_search_inputs select {
    width: 580px ! important;
  }

}

// Glenn styles


@-moz-document url-prefix() {

   .wrapper-adviser-search form#advsearch fieldset {display: table-cell;}
	body {font-family: "Helvetica Neue", Helvetica,Arial,sans-serif; font-weight: normal !important;}
}

.wrapper-adviser-search {padding-bottom:0 !important}


.wrapper-adviser-search #findadviserinterface {padding-left:10px; padding-right:10px;}

.wrapper-adviser-search #ausMap, .wrapper-adviser-search #ausMap svg:not(:root) {width:100% !important;}


.wrapper-adviser-search #findadviserinterface #quickSearch {background-color: #fff!important;}

.wrapper-adviser-search .findadviser_banner {height:inherit !important;}


.wrapper-adviser-search .advcol1 {width: 150px !important;}


.wrapper-adviser-search .resultpage ol.searchresults li {border-top:1px #D8D6D2 solid;}
.wrapper-adviser-search .resultpage ol.searchresults li:last-child {border-bottom: 1px #D8D6D2 solid !important}

.wrapper-adviser-search #map_canvas {float:right;}

.wrapper-adviser-search form#advsearch {padding-top:30px;padding-bottom: 5px;}

.wrapper-adviser-search form#advsearch .advanced {margin-top: 0 !important;padding-bottom: 5px;}
.wrapper-adviser-search form#advsearch .advanced .collapsed {padding-bottom: 15px !important;}

.wrapper-adviser-search form#advsearch .advanced .form-horizontal .form-group {margin-bottom:0 !important}

.wrapper-adviser-search .licenseefilter .licenseeFilterBox .licenseeFilterCheckbox label.licFilterLabel {font-weight:700; margin-left: -10px; width: 220px;}

.wrapper-adviser-search .licenseeFilterCheckbox .licFilter.checkbox-inline {margin-left:0;}

.wrapper-adviser-search h4.adviserresulttitle { float: left;}
.wrapper-adviser-search h4.adviserresulttitle img { float: left; margin-top:-5px; margin-right: 5px; margin-left: -5px;}
.wrapper-adviser-search .adviseraddress {clear:both}

.wrapper-adviser-search .licenseefilter .licenseeFilterBox {padding-top:15px}
.wrapper-adviser-search .licenseefilter  {padding-bottom:0;}

.wrapper-adviser-search .searchcontainer {margin-top: 40px;}

@media screen and (max-width: 991px) {

.wrapper-adviser-search .resultpage ol.searchresults li:first-child {border-top:0px #D8D6D2 solid;}
.wrapper-adviser-search #map_canvas, .wrapper-adviser-search .resultcontainer {width:100% !important}
.wrapper-adviser-search #map_canvas {float: none;}

.wrapper-adviser-search form#advsearch {padding-bottom:10px}

}


@media screen and (min-width: 768px) {

.wrapper-adviser-search form#advsearch, .wrapper-adviser-search #findadviserinterface #quickSearch { background-color: #faf8f4!important; padding-left: 9999em; padding-right: 9999em; margin-right: -9999em; margin-left: -9999em; }

.wrapper-adviser-search .map_canvas_wrap.col-lg-7 {padding-right:0}
.wrapper-adviser-search #map_canvas {margin-right:0;}

.wrapper-adviser-search #findadviserinterface #quickSearch {background-color: #fff!important; padding-bottom: 40px !important;padding-top: 40px !important;margin-bottom: -5px;}

.wrapper-adviser-search .searchcontainer .col-sm-4:last-child {margin-left:-5px}
.wrapper-adviser-search .searchcontainer .col-sm-4:nth-child(2) {margin-left:-10px}

}


@media screen and (max-width: 767px) {

.wrapper-adviser-search #findadviserinterface {padding-left:0; padding-right:0}

.wrapper-adviser-search .findadviser_banner {width:100% !important}
.wrapper-adviser-search .findadviser_banner_search {padding-right:0 !important;}
.wrapper-adviser-search form#advsearch .advcol5 {margin-left: 0!important; top:0;position: relative; float:none;z-index: 0;}
.wrapper-adviser-search form#advsearch #advsearchsub {margin-top:16px; z-index:0}


.wrapper-adviser-search form#advsearch {padding-top:20px;}
.wrapper-adviser-search form#advsearch .advanced {margin-top: 50px !important;padding-bottom:10px !important}

.wrapper-adviser-search #findadviserinterface #quickSearch {margin-left: -15px;margin-right: -15px; padding-bottom: 40px;padding-top: 40px; margin-bottom:-15px}
.wrapper-adviser-search #findadviserinterface #quickSearch h2 {padding-left:15px;}

.wrapper-adviser-search .findadviser_banner_search .findadviser_banner_search_inputs input
{width: 100% !important;}

.wrapper-adviser-search .findadviser_banner_search .findadviser_banner_search_inputs select {width: auto !important;}

.wrapper-adviser-search .licenseefilter .licenseeFilterBox {padding-top:20px; padding-bottom:30px; border-bottom: 1px #fff solid}

.wrapper-adviser-search .licenseefilter {padding-bottom:0;}

}

// Adviser detail styles added - 09/02/2016 //

.wrapper-adviser-search .principleName {font-weight:700;margin-top: 10px;}
.wrapper-adviser-search .principleImage {width: 70px;display: block;margin-right: 10px;}
.wrapper-adviser-search .principleTitle {display:block; margin-bottom:10px }

.wrapper-adviser-search .btn-link.otherAdvisersButton {display:block; padding-left:0;  margin-bottom:5px;}

.wrapper-adviser-search .adviserenquiry {margin-top: 10px !important; display: block;}

@-moz-document url-prefix() {

 .wrapper-adviser-search .adviserenquiry {margin-top: 0px !important;}

}


.wrapper-adviser-search .btn-link.phoneAdviserButton {display:block;margin-top:10px !important;margin-bottom:15px;}

.wrapper-adviser-search .searchresultlinkedin {background: url("/content/dam/mlc/images/icons/icn-linkedin.png") no-repeat scroll 50% 50% #0073b2 !important;
width:30px; height:30px; border-radius:50%; }

.wrapper-adviser-search .searchresultfacebook {background: url("/content/dam/mlc/images/icons/icn-facebook.png") no-repeat scroll 50% 50% #0073b2 !important;
width:30px; height:30px; border-radius:50%; }

.wrapper-adviser-search .searchresulttwitter {background: url("/content/dam/mlc/images/icons/icon-twitter.png") no-repeat scroll 50% 50% #0073b2 !important;
width:30px; height:30px; border-radius:50%; }

.wrapper-adviser-search .adviserSnsALink {margin-bottom:15px;}

.wrapper-adviser-search .resultpaging p {font-weight:normal;}

.wrapper-adviser-search .resultpaging ul.navh {float:none; margin-top:-20px !important; padding-left:0;}

.wrapper-adviser-search .resultpaging ul.navh li:first-child {padding-left:0;}


@media screen and (max-width:991px) {

.wrapper-adviser-search .resultcontainer {float:none !important;}

}

//End of Glenn styles //
.findadviser_banner .advcolmessage {
  width: auto;
}
// End of updated Find an adviser styles //

// Updated styles for Our network of advisers page - 04/09/2019
.wrapper-adviser-search .phoneAdviser a.phone {
  cursor: pointer;
  pointer-events: auto;
}
.wrapper-advice-centre-cta {
  padding-top: 40px;
  padding-bottom: 18px;

  h2 {
    font-size: 20px;
  }

  .button-sm {
    margin-top: 0;
    margin-bottom: 0;
  }

  h3 {
    margin-bottom: 0 !important;
    font-size: 20px;
  }
}

.wrapper-advice-centre-intro {
  border-bottom: 1px solid @bg-lightgrey;
  padding-bottom: 44px;
  margin-bottom: 60px;
  margin-top: 60px !important;
}
.wrapper-advice-centre-advisers {
  margin-top: 24px;
}

.wrapper-advice-centre-content p:last-child {
  margin-bottom: 44px;
}

.wrapper-advice-centre {
  margin-top: 60px;
  margin-bottom: 60px;
}

.wrapper-advice-centre-heading {
  padding-bottom: 24px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.align-img-center .media-left {
  display: block;
  text-align: center;
}

.wrapper-advice-centre-adviser-access {
  background-color: @grey-bg;
  padding-top: 60px;
  padding-bottom: 44px;
}

.wrapper-advice-centre-content {
  margin-bottom: 60px;
}

@media screen and (max-width: 767px) {
  .wrapper-adviser-search .searchcontainer {
    margin-top: 0px;
  }
  .wrapper-advice-centre-cta {
    text-align: center;
    padding-top: 0;
    padding-bottom: 4px;

    .button-sm {
      margin-top: 20px;
    }

    h2 {
      margin-bottom: 0;
    }
  }

  .wrapper-advice-centre-intro {
    margin-bottom: 30px;
    padding-bottom: 4px;
    margin-top: 30px !important;
  }

  .wrapper-advice-centre-advisers {
    margin-top: 4px;
  }

  .wrapper-advice-centre-content p:last-child {
    margin-bottom: 16px;
  }

  .wrapper-advice-centre-adviser-access {
    padding-top: 30px;
    padding-bottom: 14px;
  }

  .wrapper-advice-centre-heading {
    padding-bottom: 8px;
    width: 100%;

    h2 {
      margin-top: 0 !important;
    }
  }

  .spacing-mobile-mt-20 {
    margin-top: 20px !important;
  }

  .wrapper-advice-centre {
    margin-top: 30px;
    margin-bottom: 14px;
  }

  .wrapper-advice-centre-content {
    margin-bottom: 30px;
  }
}

//find-an-adviser fix the spacing 
span#loc-description,
span#q-description {
     margin-top: 10px;
     display: inline-block;
}
