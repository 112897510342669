.hero-banner {
    p {
        font-size: 24px;
        font-family: "Charlie-Std-Medium";
    }
}

.wrapper-direct-super-landing-pg {
    font-size: 16px;

    h2 {
        line-height: 28px;
        margin-top: 0 !important;
    }

    .roman-bold-75 {
        font-size: 16px;
        line-height: 24px;
    }

    .btn-orange {
        margin-top: 0;
        margin-bottom: 0;

        &:hover {
            background-color: #741915;
        }
    }

    .wrapper-super-benefits {
        p {
            margin-bottom: 10px;
        }

        h2 {
            margin-bottom: 0;
        }

        h3 {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 10px;
            font-family: @font-family-sans-serif;
        }
    }
}

/* Direct super modal styles */

.padding20 {
    padding: 20px;
}

.wrapper-std-risk-measure {
    border-bottom: 1px solid #c8c6c2;
    margin-bottom: 30px;
    padding-bottom: 10px;

    .std-risk-measure-heading h2 {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 20px;
        font-family: @font-family-sans-serif;
    }
}

.direct-super-thankyou-wrapper .direct-super-thankyou-pg-heading {
    h2, h3, h4, h5 {
        font-size: 16px;
        font-family: @font-family-sans-serif;
        margin-bottom: 15px;
        margin-top: 0;
    }
}

@media screen and (min-width: 992px) {
    //Benchmark asset allocation table

    .benchmark-asset-allocation-table {
        th:nth-child(1),
        td:nth-child(1) {
            width: 6%;
        }

        th:nth-child(2),
        td:nth-child(2) {
            width: 32% !important;
        }

        th:nth-child(3),
        td:nth-child(3) {
            width: 45%;
            text-align: right;
        }

        td:nth-child(4) {
            text-align: right;
        }

        th:nth-child(3),
        th:nth-child(4) {
            text-align: left;
        }

        td:nth-child(3),
        td:nth-child(4) {
            padding: 8px 15px 8px 8px;
        }
    }
}

@media screen and (max-width: 991px) {
    //Asset allocation table

    .asset-allocation-table {
        tr {
            border-bottom: 1px solid #c8c6c2;

            &:first-child {
                border-bottom: 0;
                position: absolute;
                margin-left: -9999px;
                margin-right: -9999px;
            }
        }

        tbody td {
            display: block;
            border-top: 0 !important;
            border: 0 !important;

            &:nth-child(1) {
                width: 100%;
                padding-top: 15px;
            }

            &:nth-child(2),
            &:nth-child(3) {
                width: 100%;
                text-align: right;
                padding-bottom: 15px;
            }

            &:nth-child(2):before {
                display: block;
                content: "Minimum";
                text-align: left;
                width: 50%;
                float: left;
            }

            &:nth-child(3):before {
                display: block;
                content: "Maximum";
                text-align: left;
                width: 50%;
                float: left;
            }
        }
        
    }
    
    //Benchmark asset allocation table
    .benchmark-asset-allocation-table {
        tbody  {
            tr:first-child {
                position: absolute;
                margin-left: -9999px;
                margin-right: -9999px;
            }

            tr {
                border-bottom: 1px solid #c8c6c2;
            }

            td {
                display: block;
                border-top: none !important;
                padding: 0 !important;
                height: 100%;
                margin-bottom: 15px;

                &:nth-child(1) {
                    width: 5%;
                    float: left;
                    margin-right: 10px;
                    margin-top: 15px;
                }

                &:nth-child(2) {
                    width: 100%;
                    margin-top: 15px;
                }

                &:nth-child(3) {
                    width: 100%;
                    text-align: right;
                    height: 45px;
                    margin-top: 15px;
                    margin-bottom: 15px;

                    &:before {
                        display: block;
                        width: 60%;
                        content: "Benchmark asset allocation (%)";
                        margin-bottom: 0;
                        text-align: left;
                        float: left;
                    }
                }
                
                &:nth-child(4) {
                    text-align: right;
                    width: 100%;
                    margin-bottom: 15px;

                    &:before {
                        content: 'Ranges (%)';
                        display: block;
                        width: 60%;
                        text-align: left;
                        float: left;
                    }
                }

                &.table-cell-sub-header:nth-child(1) {
                    display: none;
                }

                &.table-cell-sub-header:nth-child(3):before,
                &.table-cell-sub-header:nth-child(4):before {
                    font-weight: bold;
                }
            }
        }
    }      
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .hero-banner {
        height: auto;
    }
}

@media screen and (max-width: 767px) {
    .hero-banner {
        height: auto;

        h1 {
            margin-left: 0 !important;
        }

        p {
            font-size: 16px;
        }
    }

    .hide-on-mobile {
        display: none;
    }
}