

.get-in-touch-wrapper {
	border-top: 1px #c8c6c2 solid;
}
@media (min-width: 992px) {
	.form-inline {
		.input-group>.form-control {
			width: 300px;
		}
	}
}


.global_search_form {
 .form-group {
   .input-group {
     display:none;
     position: absolute;
     right: 0;
     top: 52px;
     width: 250px;
     z-index: 1;
   }
   .glyphicon {
     color: darkorange;
     cursor: pointer;
     font-size: 24px;
     top: 13px;
   }
 }
}


// GH changes ----//


.global_search_form .form-group .glyphicon {color: #bc4401;}

.global_search_form .form-group .btn { border-radius: 0;}

.global_search_form .form-group .btn-primary { position:relative; background-color: #c95109; border-color: #c95109; padding:14.5px 20px}

.get-in-touch-wrapper {margin-top: -1px; border-top: 1px #c8c6c2 solid;}

.global_search_form .form-group .btn-primary:after { position:absolute; content:'';
top:-11px; right:29px; width: 0px; height: 0px; border-left: 10px solid transparent;
border-right: 10px solid transparent; border-bottom: 10px solid #c95109;}


.global_search_form .form-group .btn-primary:hover { background-color: #bc4401; border-color: #bc4401;  }
.global_search_form .form-group .btn:active, .global_search_form .form-group .btn.active {-webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,0);box-shadow: inset 0 3px 5px rgba(0,0,0,0);}

.global_search_form .form-group .btn-primary:hover:after {border-bottom: 10px solid #bc4401;}

#global_search_form_mobile .form-group .form-control,
#mlc-header-search-box .global_search_form .form-group .form-control {
  height: auto;
  padding: 14.5px 12px;
  background-color: #312e2b;
  border: 1px solid #312e2b;
  border-radius: 0;
  color:#fff;
}

#mlc-header-search-box .global_search_form .form-group .form-control {
  height: 52px;

}

#mlc-header-search-box .global_search_form .form-group .icon-mlc-find-your-super {display:block}

.mlc-ltd-search-box .form-group .icon-mlc-find-your-super { display:none !important; }

.global_search_form .form-group .form-control:focus {
border-color: #312e2b; -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(201,81,19,.6); -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(201,81,19,.6); box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(201,81,19,.6);}

.global_search_form .form-group .input-group { width:90%; display: inline-block; position: relative;}

#global_search_form_mobile .form-group .input-group,
#mlc-header-search-box .global_search_form .form-group .input-group {
  width:390px;
  display: none;
  position: absolute;
  top: 52px;
  right: 0;
}
#mlc-header-search-box .form-group .icon-mlc-find-your-super {
  display: block !important;
}

.embedded-search-wrapper .global_search_form .form-group button {
  padding: 0 30px;
}

.global_search_form .form-group .btn:focus {outline: 0}

#global_search_form_mobile .form-group .input-group {
    width: 100%;
}

#global_search_form_mobile .form-group .input-group .input-group-btn button {
    position: absolute;
    right: 10px;
    top: -18px;
    z-index: 10;
}

#global_search_form_mobile .form-group .global_search_field {
    margin: 3px 0px 0px !important;
}

.mlc-ltd-nav-primary .nav-mobile-header .col-xs-5 {
    position: absolute;
    top: 0;
    width: 100%;
}

.global_search_form .form-group .btn-primary:after {
    right: 17px !important;
}
#global_search_form_mobile .form-group .btn-primary:after {
    right: 16px;
}

@media (min-width: 992px) {
  .form-inline .input-group>.form-control {
    width: 300px
  }
}
@media (max-width: 991px) {
  .embedded-search-wrapper .global_search_form .form-group button {
    padding: 0 20px;
  }
  .embedded-search-wrapper .form-group .global_search_field {
    width: 60% !important;
  }
}



 //end GH



