/* CSS for MySuper landing page - tabs
  Added in theme.less
*/

.mysuper-tab-wrapper {
  .nav-tabs {
    >li {
      >a {
        padding: 196px 0 0;
        background-color: #fff !important;
        background-position: center top !important;
        font-size: 32px;
        font-family: Charlie-Std-SemiBold;
        display: inline-block;
        width: 100%;
        height: 351px;
        vertical-align: middle;
        background-position: center bottom !important;
        background-repeat: no-repeat;
        outline: none !important;
      }
      width: 33%;
      border: 0px;
    }
    height: auto;
    margin-bottom: 0px;
    margin-bottom: 0px;
    position: relative;
    top: 1px;
    border-bottom: 0px;
    >li.item_0 {
      a {
        color: #037691;
      }
    }
    >li.item_1 {
      a {
        color: #ce0058;
      }
    }
    >li.item_2 {
      a {
        color: #c95109;
      }
    }
    >li.active {
      >a {
        background-position: center 0px !important;
      }
    }
  }
  .tab-pane {
    .list-item-text {
      >p {
        margin-bottom: 0px;
        .style1 {
          border-left: 1px solid #ccc;
          border-right: 1px solid #ccc;
          border-top: 1px solid #ccc;
          display: block;
          padding: 30px;
        }
      }
    }
    table {
      color: #fff;
      caption {
        color: #fff;
        font-size: 26px;
        font-family: Charlie-Std-SemiBold;
        padding: 30px 30px 0px;
      }
      tr {
        td {
          padding: 30px;
        }
        td.cellS1 {
          img {
            float: left;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
.tab-pane.mysuper {
  background-color: #fff;
  ul.media-list {
    >li {
      padding-left: 0px;
    }
  }
}
.mysuper-under-55 {
  table {
    background-color: #01838c;
    caption {
      background-color: #01838c;
    }
  }
}
.mysuper-55-65 {
  table {
    background-color: #ce0058;
    caption {
      background-color: #ce0058;
    }
  }
}
.mysuper-over-65 {
  table {
    background-color: #c95109;
    caption {
      background-color: #c95109;
    }
  }
}
@media screen and (max-width: 992px) {
  .mysuper-tab-wrapper {
    .nav-tabs {
      >li {
        >a {
          padding: 200px 0px 0px;
          font-size: 26px;
          height: 351px;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .mysuper-tab-wrapper {
    .tab-pane {
      table {
        tr {
          td {
            width: 100%;
            display: block;
            &:not(:last-child) {
              padding-bottom: 0px;
            }
          }
        }
      }
    }
    .nav-tabs {
      >li {
        >a {
          padding: 109px 0px 0px;
          font-size: 20px;
          height: 197px;
          background-size: 428px !important;
        }
      }
    }
  }
  .mysuper-landing {
    .table-responsive {
      >.table {
        >tbody {
          >tr {
            >th {
              white-space: normal;
            }
          }
        }
      }
    }
  }
}
