
.wrapper-blog-overview {
padding-top:40px;
padding-bottom:40px;
}

.group-nabasia-blog-overview {
  margin-top:30px;
}

.nabasia-blog-overview-wrapper .blog-filter-wrapper .blog-filter-categories {
  margin: 10px;
}

.blog-wrapper {
     background-position: center top;
     background-size: cover;
     height: 290px;
}

.blog-filter-categories .btn-group {
     margin-bottom: 40px;
}

.blog-filter-categories .btn-group.btn-group-justified {
  border-bottom: 1px solid #3b3b3b;
}

.blog-filter-categories .btn-group .btn-default {
     background-color: #ffffff;
     border-radius: 0px;
     border: 0px none;
     color: #3b3b3b;
     font-weight: bold;
     margin-bottom: -1px;
     width: auto;
}

.blog-filter-categories .btn-group .btn-default.active {
     border-bottom: 5px solid #c95109;
     box-shadow: none;
     color: #000000;
}

.blogentry-content-wrapper {
     background-color: #ffffff;
}

.blogentry-content-wrapper .blogentry-date {
  color: #3b3b3b ! important;
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: 14px;
}

.blogentry-content-wrapper .blogentry-title h3 {
  color: #3b3b3b;
  margin-top: 0px !important;
  margin-bottom: 0px;
  font-size:22px;
}

.blogentry-content-wrapper .blogentry-more-overlay {
     //background-image: none ! important; commented this out to get the fade to
     //#fff to work again - see me if issues Tapan
}

.blogentry-content-wrapper .blogentry-more-overlay .blogentry-more-link {
  background:transparent !important;
}

.blogpost .blogBackLink {
  padding-left: 0;
}

.blogpost .entrytitle {
    margin-bottom: 20px;
    margin-top: 30px;
    text-align: left;
    display: inline-block;
}

.blogpost .entrytitle small {
     color: #b2b2b2;
     font-weight: bold;
}

.blogpost .entrytext {
     margin: 20px auto;
}

.blog-author {
     border-bottom: 1px solid #e5e5e5;
     margin-bottom: 20px;
     padding-bottom: 10px;
}

.blog-author img {
     margin-right: 10px;
}

hr {
     border-color: #e5e5e5;
}

.blog-abt-author {
     background-color: #f3f4f8;
     margin-bottom: 40px;
     padding: 40px 30px 30px 0px;
}

.blog-abt-author-title {
     margin-bottom: 20px;
}

.blog-abt-author-title p {
     color: #666666;
}

.addthis_toolbox {
     margin-bottom: 40px;
}

.common-blog-overview-wrapper {
    margin: 50px 0px 30px;
}

.blog-filter-wrapper {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 5px;
}

.masonry-brick-wrapper {
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 50px;
}
.common-blog-overview-wrapper-basic {
    margin-top: 20px;
}
.common-blog-overview-wrapper-basic .masonry-brick {
    position: relative !important;
    width: 100%;
    left: inherit !important;
    top: inherit !important;
}
.blog-rhs-column .masonry-brick {
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
}
.common-blog-overview-wrapper-basic .masonry-brick-wrapper {
  padding-right: 0px;
  padding-left: 0px;
  padding-bottom: 40px;
}
.blog-wrapper-container {
   margin-top:15px
}

.blog-masthead-wrapper .blog-masthead {
  background-color: #333;
  opacity: 0.85;
  padding: 0px 25px;
  height: 320px;
}
.blog-masthead-wrapper .blog-masthead h1 {
  color:#fff;
  font-size: 34px;
  width: 100%;
}
.blog-masthead-wrapper .blog-masthead a {
  color:#fff;
}
.blog-masthead-wrapper .blog-masthead a:hover {
  text-decoration: underline;
}
.blog-rhs-column h3 {
  margin-top: 0px !important;
  margin-bottom: 0px;
}
.blogpost .blog-entry-title {
  color: #3b3b3b;
  font-size: 34px;
}
.blogpost .entrytitle small {
    color: #3b3b3b;
}

.blog-subscribe-wrapper {
  background-color: #faf8f4;
  padding: 60px 0 44px 0;
  .blog-subscribe-text {
     h3 {
       text-align: right
     }
  }
  .blog-subscribe-form {
      .text-image {
        text-align: left;
        span.button-md {
          margin-top:0px;
        }
      }
  }
}

.blog .navigation {
  display:none
}

.bootstrap_simpleform form {
    display: inline-block;
  margin-top: 50px;
  background: transparent
}
.bootstrap_simpleform  .submitbutton {
    display: inline-block;
}
.bootstrap_simpleform .step.ui-formwizard-content br {
                display: none
}
.bootstrap_simpleform  .submitbutton a {
    background-color: #c95109;
    color: #fff;
    font-family: Charlie-Std-SemiBold;
    font-size: 20px;
    padding: 11px 21px;
    cursor: pointer;
    text-decoration: none;
}
.bootstrap_simpleform .ui-formwizard {
    border: none
}
.bootstrap_simpleform {
  text-align: center;
}
.bootstrap_simpleform .step.ui-formwizard-content .stepname {
  display: block;
  font-size: 30px;
  position: absolute;
  top: 0px;
  left: 0px;
  font-family: Charlie-Std-SemiBold;
  width: 100%;
}
.bootstrap_simpleform .step.ui-formwizard-content .input .formfield input {
    border: 1px solid #ccc;
    background: #fff;
    padding: 11px;
}
.bootstrap_simpleform .step.ui-formwizard-content .input .formlabel {
    display:none
}

.bootstrap-page-blog .wrapper-body {
  margin-bottom: 70px;
}

.blog-rhs-column:before {
    content: "Related articles";
    font-family: Charlie-Std-SemiBold;
    font-size: 26px;
}

@media screen and (min-width: 992px) and (max-width: 1205px){
  .blog-masthead-wrapper .blog-masthead {
    height: auto;
  }
}
@media screen and (max-width: 992px) {
  .blog-subscribe-wrapper .blog-subscribe-text h3 {
      text-align: center;
  }
  .blog-subscribe-form .bootstrap-simpleform {
    text-align: center;
  }
  .blog-rhs-column {
    padding-top: 30px;
  }
}

@media screen and (max-width: 767px) {
  .blog-masthead-wrapper .blog-masthead h1 {
    font-size: 24px;
  }
  .blog-filter-categories .btn-group .btn-default {
    width: 100%
  }
  .blog-subscribe-wrapper {
    .blog-subscribe-text {
      h3{
        text-align: center
      }
    }
    .blog-subscribe-form {
        .text-image {
          text-align: center
        }
        span.button-md {
           margin-top:20px !important;
        }
    }
  }
}

@media screen and (max-width: 599px) {
  .blog-subscribe-wrapper {
    .blog-subscribe-form {
      span.button-md {
        color: #fff !important;
        background-color: #c95109 !important;
        font-family: @font-family-heading;
        font-size: 18px;
        padding-top: 5px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .bootstrap_simpleform .step.ui-formwizard-content .stepname {
      font-size: 22px;
  }
}

@media screen and (max-width: 400px) {
  .bootstrap_simpleform form {
      width: 100%;
  }
  .bootstrap_simpleform .step.ui-formwizard-content .input .formfield input {
      width: 100%;
  }

  .bootstrap_simpleform .submitbutton {
      width: 100%;
      padding: 10px 0px;
  }
  .bootstrap_simpleform .submitbutton a {
      width: 100%;
      display: inline-block;
  }
}


//additional style for new Blog


.wrapper-bg-f0eeea.nav0 {padding-top:14px;}

.wrapper-bg-f0eeea.nav0 a {color:#3b3b3b; text-decoration:none;}

.wrapper-bg-f0eeea.nav0 a:hover {color:#23527c; }

.blog.wrapper.wrapper-primary-nav {padding: 18px 0;}

.blog.mlclogo {border-right: 1px solid #e5e5e5;}

.blogsocialIcn {float:right !important;}

.hatchCt h4 {font-family:'Charlie-Std-Medium', helvetica, arial;}

.blog-masthead-wrapper .blog-masthead {background-color:#C95109; opacity:0.95}

.blog-masthead-wrapper .blog-masthead h4 { margin-top:30px !important; }

.blog-masthead-wrapper .blog-masthead h4 a {font-family:'Charlie-Std-Medium'; padding:10px 20px; border: 3px solid #fff;}

.blog-masthead-wrapper .blog-masthead h4 a:hover {border:3px solid #fff; background-color:#fff; color:#C95109;}

.blog-masthead-wrapper .blog-masthead h4 a:focus {text-decoration:none;}

@media screen and (min-width:992px) and (max-width:1199px) {
.blog-masthead-wrapper .blog-masthead {height:270px}

}

@media screen and (min-width: 1200px) {
.blog-masthead-wrapper .blog-masthead {height: 320px;}

.blog-masthead-wrapper .blog-masthead h1 {margin-top:40px !important;}

}


@media screen and (min-width:992px) {

.blog.mlclogo {width:120px;}

.hatchlogo {width: 100px;margin-top:12px;margin-bottom:0;margin-left: 15px;margin-right: 5px;}

.blogsocialIcn { margin-top:10px; margin-bottom:-10px}

.hatchCt h4 { margin-top:21px !important;}

}


@media screen and (max-width:991px) {

.wrapper-bg-f0eeea.nav0 {display:none;}

.blog-masthead-wrapper .blog-masthead {background-color:rgba(0,0,0,0.65);}

.blog-masthead-wrapper .blog-masthead h4 a {border:2px solid #fff;font-size: 15px;padding: 7px 20px;}

.blog-masthead-wrapper .blog-masthead h4 a:hover {color:#3b3b3b;}

}

@media screen and (min-width:768px) and (max-width:991px) {

.blog.mlclogo {width:90px;}

.blog.mlclogo img {height:40px;}

.hatchlogo {width: 80px;margin-top:7px;margin-bottom:0;margin-left: 10px;margin-right: 15px; }

.hatchlogo h1 {font-size:30px;}

.blogsocialIcn {width:210px; margin-top:10px; margin-bottom:-10px}

.blogsocialIcn img {height:30px}

.hatchCt {width:310px}

.hatchCt h4 {font-size:20px; margin-top:13px !important;}

.blog-masthead-wrapper .blog-masthead {height:270px}

.blog-masthead-wrapper .blog-masthead h1 {margin-top:25px !important;}

}



@media screen and (max-width:767px) {

.blog.mlclogo {margin-bottom:15px; border-right: 0;}

.blog.mlclogo img {height:38px;}

.hatchlogo h1{font-size:26px;}

.blogsocialIcn img {height:26px;}

.blog-masthead-wrapper .blog-masthead {height:auto; padding-left:15px; padding-right:15px; }

.blog-masthead-wrapper .container.page-header-container {background:none !important;}

.blog-masthead-wrapper .blog-masthead h4 { margin-top:0px !important; margin-bottom:30px }

.blog-masthead-wrapper.masthead-wrapper.masthead-sm {height:auto !important}

.blog-masthead-wrapper .blog-masthead h1 {margin-left:0}

.blog-filter-wrapper {margin-bottom:30px;margin-top: -30px;}

.blog-results-wrapper .masonry-brick, .blog-results-wrapper .masonry-brick img {width:100% !important;}

}


@media screen and (min-width:600px) and (max-width:767px) {

.blog-masthead-wrapper .blog-masthead h1 {margin-top:20px !important;}

}



@media screen and (min-width:280px) and (max-width:767px) {

.blogsocialIcn { position:absolute; top:70px; right:15px;}

}


@media screen and (max-width:279px) {

.blogsocialIcn { float:left; margin-bottom:-10px; }

.blogsocialIcn p {text-align:left !important; }

}
//end additional style for neew Blog

