
/*--------------------------------------------*/

/*quicklinks */
@media screen and (min-width: 1210px) {

/*quicklinks for no 3 and 4 */
.channel-quicklinks-tab-wrapper .nav-tabs>li:nth-child(3)>a, .channel-quicklinks-tab-wrapper .nav-tabs>li:nth-child(4)>a {padding:10px 50px}

/*quicklinks for no 2 */
.channel-quicklinks-tab-wrapper .nav-tabs>li:nth-child(2)>a {padding:10px 25px}

/*quicklinks for no 1*/
.channel-quicklinks-tab-wrapper .nav-tabs>li:nth-child(1)>a {padding:10px 45px}

}

@media screen and (min-width: 993px) and (max-width: 1209px) {

/*quicklinks for no 3 and 4 */
.channel-quicklinks-tab-wrapper .nav-tabs>li:nth-child(3)>a, .channel-quicklinks-tab-wrapper .nav-tabs>li:nth-child(4)>a {padding:10px 50px}

/*quicklinks for no 2 */

/*quicklinks for no 1 */
.channel-quicklinks-tab-wrapper .nav-tabs>li:nth-child(2)>a {padding:10px 10px 10px 40px}
.channel-quicklinks-tab-wrapper .nav-tabs>li:nth-child(1)>a {padding:10px 25px 10px 45px}

}

@media screen and (min-width: 702px) and (max-width: 992px) {

/*quicklinks for no 3 and 4 */
.channel-quicklinks-tab-wrapper .nav-tabs>li:nth-child(3)>a, .channel-quicklinks-tab-wrapper .nav-tabs>li:nth-child(4)>a {padding:10px 20px}

/*quicklinks for no 1 */
.channel-quicklinks-tab-wrapper .nav-tabs>li:nth-child(1)>a {padding:10px 0px 10px 35px }

/*quicklinks for no 2 */
.channel-quicklinks-tab-wrapper .nav-tabs>li:nth-child(2)>a {padding:10px 40px }

/*reduce font size */
.channel-quicklinks-tab-wrapper span {font-size:18px !important}
}


@media screen and (min-width: 600px) and (max-width: 701px) {

/*quicklinks for no 3 and 4 */
.channel-quicklinks-tab-wrapper .nav-tabs>li:nth-child(3)>a, .channel-quicklinks-tab-wrapper .nav-tabs>li:nth-child(4)>a {padding:10px 10px}

/*quicklinks for no 1 */
.channel-quicklinks-tab-wrapper .nav-tabs>li:nth-child(1)>a {padding:10px 0px 10px 15px }

/*quicklinks for no 2 */
.channel-quicklinks-tab-wrapper .nav-tabs>li:nth-child(2)>a {padding:10px 20px }

/*quicklinks reduce font size */
.channel-quicklinks-tab-wrapper span {font-size:18px !important}
}


/*quicklinks actually just change the max-width to 600px */
@media screen and (max-width: 600px){.channel-quicklinks-tab-wrapper table td{display:block}
.channel-quicklinks-tab-wrapper ul.nav-tabs li{width:100%}
.channel-quicklinks-tab-wrapper ul.nav-tabs li a span{text-align:left}
.channel-quicklinks-tab-wrapper ul.nav-tabs li a{position:relative;width:100%;height:50px}
.channel-quicklinks-tab-wrapper ul.nav-tabs li a div.caret{display:none}
.channel-quicklinks-tab-wrapper ul.nav-tabs li a .mlc-glyph{position:absolute;right:0;display:inline-block}
.channel-quicklinks-tab-wrapper ul.nav-tabs li:nth-child(1) a,.channel-quicklinks-tab-wrapper ul.nav-tabs li:nth-child(2) a,.channel-quicklinks-tab-wrapper ul.nav-tabs li:nth-child(3) a,.channel-quicklinks-tab-wrapper ul.nav-tabs li:nth-child(4) a{background-position:left center!important;-webkit-background-size:38px 38px!important;background-size:38px!important}
.channel-quicklinks-tab-wrapper ul.nav-tabs li a span{position:absolute;top:17px;left:50px;font-size:17px}
.section-header h2{margin-bottom:24px}
.channel-quicklinks-tab-wrapper ul.nav-tabs li.active .channel-quicklinks-tab-active{display:none}
.channel-quicklinks-tab-wrapper ul.nav-tabs li{border-bottom:1px #c8c6c2 solid}
.channel-quicklinks-wrapper.section-wrapper{padding:30px 0 0}
}

@media screen  and (max-width: 600px) {

/*quicklinks fixed margin top */
.channel-quicklinks-tab-wrapper ul.nav-tabs li a span {
  margin-top: 0px;
}
}


@media screen and (max-width: 767px) and (min-width: 601px) {
.channel-quicklinks-tab-content-wrapper ul.media-list .list-item-text table td {
  display: inline-block!important;
  width: 47%!important;
  margin-right: 10px;
  margin-bottom: 20px;
}
}

@media screen and (max-width: 600px){
.channel-quicklinks-tab-content-wrapper ul.media-list .list-item-text table {margin-top: 20px;}
.channel-quicklinks-tab-wrapper table p {display: none;}
.channel-quicklinks-tab-content-wrapper ul.media-list .list-item-text table td h4 a {font-weight: 700;color: #3b3b3b!important;}
.channel-quicklinks-tab-wrapper table h4 a {
  margin-top: 0;
  font-family: HelveticaNeueLTStd-Roman,Helvetica,Arial,sans-serif,arial;
  font-size: 15px;}

.channel-quicklinks-tab-content-wrapper ul.media-list .list-item-text table td { width: 100% !important;}
.channel-quicklinks-tab-wrapper table td {display: block;}

.channel-quicklinks-tab-content-wrapper .close-tab {display: none;}

}

@media screen and (min-width:601px) and (max-width:767px){.channel-quicklinks-tab-content-wrapper ul.media-list .list-item-text table td{display:inline-block!important;width:47%!important;margin-right:10px;margin-bottom:20px}
}
@media screen and (max-width:600px){.channel-quicklinks-tab-wrapper ul.nav-tabs{margin-top:0}
.channel-quicklinks-tab-wrapper ul.nav-tabs li.active{border-bottom:2px solid #ce0058!important}
.channel-quicklinks-tab-wrapper ul.nav-tabs li a span{font-size:18px!important}
.channel-quicklinks-tab-wrapper ul.nav-tabs li a .mlc-glyph{right:2px!important}
.channel-quicklinks-tab-content-wrapper ul.media-list .list-item-text table{margin-top:20px}
.channel-quicklinks-tab-content-wrapper ul.media-list .list-item-text table td{width:100%}
.channel-quicklinks-tab-content-wrapper ul.media-list .list-item-text table td h4 a{font-weight:700;color:#3b3b3b!important}
}

/*--------------------------------------------*/

/* Carousel less than 600px screen */

@media screen and (max-width: 599px) {

/*make 100% height in the tint background */
.carousel-caption {height:100%;}

/*adjust the content to middle */
h2 {margin-top: 20px !important;}

/* adjust the indicator to more bottom */
.carousel-indicators {opacity:0.3; bottom: -20px}

/*create smaller indicators */
.carousel-indicators li, .carousel-indicators li .active {height:10px !important; width:10px !important; margin:0 3px }

 }


/*--------------------------------------------*/

/* fixed business page with no sidekick top on mobile */

.business-theme .nav-mobile-header {height:0}

/*--------------------------------------------*/

/*for personal, adviser and business cta */

@media screen and (min-width: 993px) {

/*cta adjust content to sit vertical-align middle */
.channel-info-directive, .wrapper adviser-info-directive-wrapper .channel-info-directive, .business-theme .channel-info-directive {height:130px; display:table-cell; vertical-align:middle; padding:0 35px }


}

@media screen and (min-width: 768px) and (max-width: 992px) {

/*cta  adjust content to sit vertical-align middle */
.channel-info-directive, .wrapper adviser-info-directive-wrapper .channel-info-directive, .business-theme .channel-info-directive {height:160px; display:table-cell; vertical-align:middle; padding:0 35px }

/* cta  put border right */
.channel-info-directive.first {border-right: 1px solid #d06735;}
.wrapper adviser-info-directive-wrapper .channel-info-directive.first {border-right: 1px solid #d06735;}
.business-theme .channel-info-directive.first {border-right: 1px solid #7a7373}
}

@media screen and (max-width: 767px) {

/* cta put border right */
.channel-info-directive.first {border-right: 0px solid #d06735;}
.wrapper adviser-info-directive-wrapper .channel-info-directive.first {border-right: 1px solid #d06735;}
.business-theme .channel-info-directive.first {border-right: 0px solid #7a7373; border-bottom: 1px solid #7a7373}

}

@media screen and (max-width: 600px) {

/* cta put border right on mobile */
.channel-info-directive .text-image h4, .wrapper adviser-info-directive-wrapper .channel-info-directive .text-image h4, .business-theme .channel-info-directive .text-image h4 {font-size:20px;}

/* cta less padding on mobile */
.channel-info-directive, .wrapper adviser-info-directive-wrapper .channel-info-directive, .business-theme .channel-info-directive { padding:15px}
}

/*--------------------------------------------*/

@media screen and (max-width: 600px) {

/* global change make the md button to small button */
/*make the button center*/
.product-page-cta h4 a {margin-left:0;}

.product-page-cta span.button-md {top:20px;}

/* Contact us search */
.wrapper.wrapper-dark.embedded-search-wrapper .form-group input {height: 50px;}

/*Forms and docs search */
.embedded-search-wrapper .global_search_form .form-group .input-group {top: 0;}

/*contact us search */
.embedded-search-wrapper .global_search_form .form-group .input-group {top: 0px; margin-left:0}

}


@media screen and (min-width: 600px) {
/* Contact us search */
.wrapper.wrapper-dark.embedded-search-wrapper .form-group .global_search_field {
  margin-right: 0px;}

.embedded-search-wrapper .form-group .global_search_field {
  margin-right: 0px;}
}

@media screen and (min-width: 600px) and (max-width: 767px) {

.embedded-search-wrapper .global_search_form .form-group button {width: 100%;}
}

@media screen and (min-width: 768px) and (max-width: 992px) {

.embedded-search-wrapper .global_search_form .form-group button {width: 100%;}

/*Forms and docs search */
.wrapper.wrapper-dark.embedded-search-wrapper .form-group .global_search_field { margin-right:0;  margin-left:0%;}

/*contact us search */
.embedded-search-wrapper .form-group .global_search_field { margin-right:0;  margin-left:5%; width:95% }
}

/* Contact us search */
.wrapper.wrapper-dark.embedded-search-wrapper .form-group input {height: 50px;}


@media screen and (max-width: 991px) {
/*contact us search */
.wrapper.wrapper-dark.embedded-search-wrapper .global_search_form .form-group .input-group {top: 52px; margin-left:0}
}


@media screen and (min-width: 992px) {

/* Contact us search */
.wrapper.wrapper-dark.embedded-search-wrapper .form-group .global_search_field {
  width: 60%!important;}


/*Forms and docs search */
.embedded-search-wrapper .form-group .global_search_field { margin-right:0;  margin-left:10%; width:90% }

/* Contact us search */
.wrapper.wrapper-dark.embedded-search-wrapper .form-group .global_search_field { margin-left:0%; }

/*Forms and docs search */
.embedded-search-wrapper .global_search_form .form-group button {width:auto;}

/* Contact us search */
.wrapper.wrapper-dark.embedded-search-wrapper .global_search_form .form-group .input-group {top:52px;}

}

/*--------------------------------------------*/

/*Important updates personal homepage, uniform min heights in desktop */
.important-updates-wrapper .important-updates-row .important-updates-panel {min-height:285px}

/*Important updates personal homepage, no need min heights in less than 768 */
@media screen and (max-width: 768px) {
.important-updates-wrapper .important-updates-row .important-updates-panel {min-height:0px}
}


/*--------------------------------------------*/

/*Latest news Adviser homepage*/
@media screen and (max-width: 991px) {
.investment-news-insights-wrapper .news-insights-panel {margin-bottom:20px;}
}

@media screen and (max-width: 767px) {
/*Latest news Adviser homepage, no need min heights in less than 768 */
.investment-news-insights-wrapper .news-insights-panel {min-height:0}
}


/*--------------------------------------------*/

/* fix nab logo alignment in footer  */
.row-footer .wrapper.footer-bg .base-footer .pull-right img {margin-top:16px}

@media screen and (max-width: 991px) {
.row-footer .wrapper.footer-bg .base-footer .pull-right { float: left !important; padding-left:0; padding-bottom:10px;}
}


/*--------------------------------------------*/

/*put more margin right from the glyphicon inside accrodion in contact us page*/
.page-content .panel-group .panel p .mlc-glyph {margin-right:10px}



/* LATEST NEWS & INSIGHTS  ----------------- */

@media screen and (min-width: 993px) {

/*fixed height issue with 4 lines */
.media.news-insights-panel>.media-body h4 {height:100px;}

/*adjust the space with read more link */
.media.news-insights-panel>.media-body p {height: 70px;}

}


/* MAKE GET IN TOUCH WRAPPER NO BORDER TOP -------- */

.get-in-touch-wrapper { border : none}

@media screen and (min-width: 767px) and (max-width: 991px) {
.wrapper.channel-news-insights-wrapper.section-wrapper .col-custom.col-xs-12.col-sm-6.col-md-3.col-lg-3:nth-child(odd) {clear:both}

}


/* FIXED in VM change height 773px to 768px -----------*/

@media screen and (max-width: 773px){
.media.news-insights-panel>.media-body h4 {height: 70px !important;}
.media.news-insights-panel>.media-body p {height: inherit !important;}
}

@media screen and (max-width: 768px){

/* make no height in h4 and p in small screen */
.media.news-insights-panel>.media-body h4 {height: inherit !important;}
.media.news-insights-panel>.media-body p {height: inherit !important;}
}




/* FIXED IMPORTANT UPDATES HEIGHT ISSUES AND RESPONSISVE  ---------- */

.wrapper.important-updates-wrapper h2 {margin-bottom:40px}

.important-updates-wrapper .important-updates-row {margin-bottom:40px}

.important-updates-wrapper .important-updates-row .important-updates-panel {padding: 30px 20px 10px 20px;}

@media screen and (min-width: 768px){

  .important-updates-wrapper .important-updates-row .important-updates-panel {min-height:300px;}

}

@media screen and (max-width: 767px){

  .important-updates-wrapper .important-updates-row .important-updates-panel {min-height:inherit;}

}



/* MLC FAMILY LICENCESS LOGOS ALIGNMENT ISSUES ------------- */


@media screen and (min-width: 768px){

.wrapper.wrapper-family-licensees {padding-bottom:30px}

.wrapper.wrapper-family-licensees .imglicensee1 img {margin-left:60px; width:40%}

.wrapper.wrapper-family-licensees .imglicensee2 img {margin-top:10px}

.wrapper.wrapper-family-licensees .imglicensee3 img {margin-top:15px; margin-left:10px;}

.wrapper.wrapper-family-licensees .imglicensee4 img { margin-left:20px;}

.wrapper.wrapper-family-licensees .imglicensee5 img { margin-top:-10px;}

}


@media screen and (max-width: 767px){

.wrapper.wrapper-family-licensees {padding-bottom:30px}

.wrapper.wrapper-family-licensees .imglicensee1,
.wrapper.wrapper-family-licensees .imglicensee2,
.wrapper.wrapper-family-licensees .imglicensee3,
.wrapper.wrapper-family-licensees .imglicensee4,
.wrapper.wrapper-family-licensees .imglicensee5 {display: block; position: relative; left: 50%;}

.wrapper.wrapper-family-licensees .imglicensee1 {margin-left: -29px;}
.wrapper.wrapper-family-licensees .imglicensee2 {margin-left: -68px; margin-bottom:30px}
.wrapper.wrapper-family-licensees .imglicensee3 {margin-left: -70px; margin-bottom:30px}
.wrapper.wrapper-family-licensees .imglicensee4 {margin-left: -40px; margin-bottom:30px}
.wrapper.wrapper-family-licensees .imglicensee5 {margin-left: -65px; margin-bottom:20px}

}

.aqua-tabs li.active {
  border-bottom-color: #017780 ! important;
}

.aqua-tabs li.active::after {
  border-bottom-color: #017780 ! important;
}

.rubine-tabs li.active {
  border-bottom-color: #ce0058 ! important;
}

.rubine-tabs li.active::after {
  border-bottom-color: #ce0058 ! important;
}

.aqua-nav li.active a {
     border-bottom-color: #017780 ! important;
}

.rubine-nav li.active a {
     border-bottom-color: #ce0058 ! important;
}

@media screen and (max-width: 599px){
.carousel-content-list-wrapper .carousel-caption .list-item-text h4 span.border-button-md,
.carousel-content-list-wrapper .carousel-caption .list-item-text h4 span.border-button-md-rubine,
.carousel-content-list-wrapper .carousel-caption .list-item-text h4 span.border-button-md-aqua {
  position: relative;
  top: 10px;
  height: inherit;
  padding: 5px 10px;
  margin-left: 0;
  font-size: 12px;
  color: #fff;
  text-decoration: none;
  border: 3px solid #fff;
  min-width: 60px;
  height: 30px!important;
  padding: 5px 10px;
  line-height: 1.1em;
}
}

.wrapper.news-insights-wrapper .nav>li>a {padding:10px}

.carousel-full-width .button-md {
   padding-top:10px;
}

.masthead-wrapper .button-md {
   padding-top:3px;
}

.carousel-full-width a:hover,
.masthead-wrapper a:hover {
  text-decoration:none !important;
}

.banner-text-white-italics h2 {
  color:#ffffff !important;
  font-family:Charlie-Std-SemiBold-Italic !important;
}

.banner-text-white-italics h4 {
  color:#ffffff !important;
}


@media screen and (max-width: 992px){
.wrapper.news-insights-wrapper .nav-inflation-plus li {padding-left:0}
}

//Styles for Aqua and Rubine button added 11/02/2016 //
  @media screen and (max-width: 767px) {
    span.button-md-aqua,
    span.button-md-rubine,
    span.button-md {
      min-width: 110px;
      position: relative;
      top: -20px;
      height: 32px ! important;
      padding-top: 7px !important;
      padding-right: 16px;
      padding-left: 16px;
      margin-top: 20px;
      margin-bottom: 22px;
      font-family: "HelveticaNeueLTStd-Roman",Helvetica,Arial,sans-serif;
      font-size: 14px;
    }

    .masthead-wrapper.masthead-sm h1 {
      font-size: 32px;
    }

  }

@media screen and (min-width:600px) and (max-width:767px) {
.carousel-full-width {
        span.button-md,
        span.button-md-aqua,
        span.button-md-rubine {
          padding-top:7px;
        }
   }
}

    @media screen and (max-width: 599px) {

      .masthead-wrapper.masthead-sm .container.page-header-container {
        height: 100%;
      }

      .masthead-wrapper.masthead-sm h1 {
        font-size: 20px;
      }

      span.button-md,
      span.button-md-aqua,
      span.button-md-rubine {
        border:none !important;
        padding-top:4px !important;
      }

      //Updated styles for banner buttons added on 11/03/2016 by Tapan //

      .carousel-full-width {
        span.button-md,
        span.button-md-aqua,
        span.button-md-rubine,
        span.border-button-md,
        span.border-button-md-aqua,
        span.border-button-md-rubine,
        span.border-button-md-white-reverse
        {
          background-color: transparent ! important;
          border: 3px solid #ffffff ! important;
          color: #ffffff ! important;
          padding: 5px 8px ! important;
          height: 30px ! important;
          line-height: 1.1em ! important;
          min-width: 60px ! important;
          font-size: 12px ! important;
          margin-left: 0px !important;
          font-weight: normal !important;
          margin-top:30px;
        }
      }

      .carousel-full-width {
        span.border-button-md,
        span.border-button-md-aqua,
        span.border-button-md-rubine,
        span.border-button-md,
        span.border-button-md-aqua,
        span.border-button-md-rubine,
        span.border-button-md-white-reverse {
         font-weight:normal !important;
        }
      }

      .carousel-full-width {
        span.button-md:hover,
        span.button-md-aqua:hover,
        span.button-md-rubine:hover,
        span.border-button-md:hover,
        span.border-button-md-aqua:hover,
        span.border-button-md-rubine:hover,
        span.border-button-md-white-reverse:hover {
          color:#3b3b3b !important;
          background-color:#ffffff !important;
          font-weight:normal !important;
         }
       }

      .masthead-sm a:hover {
        text-decoration:none !important;
      }

      .masthead-sm {
        span.button-md,
        span.button-md-aqua,
        span.button-md-rubine,
        span.border-button-md,
        span.border-button-md-aqua,
        span.border-button-md-rubine,
        span.border-button-md-white-reverse {
          background-color: transparent ! important;
          border: 3px solid #ffffff ! important;
          color: #ffffff ! important;
          padding: 5px 8px ! important;
          height: 30px ! important;
          line-height: 1.1em ! important;
          min-width: 60px ! important;
          font-size: 12px ! important;
          margin-left: 10px;
          font-weight: normal !important;
          margin-top:30px;

        }
      }

      .masthead-sm {
        span.button-md:hover,
        span.button-md-aqua:hover,
        span.button-md-rubine:hover,
        span.border-button-md:hover,
        span.border-button-md-aqua:hover,
        span.border-button-md-rubine:hover,
        span.border-button-md-white-reverse {
          color:#3b3b3b !important;
          font-weight:normal;
          background-color:#ffffff !important;
        }
      }



    }  //End of styles //

