.bg-white {
    background-color: #fff;
}
body .employer-theme {
    font-size: 16px;
}
body .employer-theme a {
    color: #D4743A;
}
.employer-theme ol li, ul li {
    margin-bottom: 20px;
}
.employer-theme ul li::marker 
{
    font-size: 10px;
    color: #434343;
}
.employer-theme .sidekick {
    font-size: 15px;
} 
.employer-theme .sidekick ol li,
.employer-theme .sidekick ul li {
    margin-bottom: 0px;
}
.employer-theme .h3, h3 {
    font-size: 30px;
    margin-bottom: 25px;
}
.employer-theme .overlapped-col-left-bg {
    height: 100%;
    background: linear-gradient(90deg, #f0eeea 30%, #ffffff 0);
}
.employer-theme .overlapped-col-right-bg {
    height: 100%;
    background: linear-gradient(90deg, #ffffff 70%, #f0eeea 30%);
}
.employer-theme .overlapped-col h2 {
    font-family: 'Charlie-Std-Medium';
    font-size: 34px;
}

.employer-theme nav.navbar {
    min-height: 100px;
}
.employer-theme nav.navbar-default .navbar-nav li a {
    font-family: 'HelveticaNeueLTStd-Roman';
    font-size: 18px;
    color: #181816;
}
.employer-theme .wrapper.wrapper-top-row {
    background-color: #181816;
}
.employer-theme #site-select-desktop .nav-pills>li>a {
  color:#fff;
}
.employer-theme #site-select-desktop .nav-pills>li.active>a, .employer-theme #site-select-desktop .nav-pills>li.active>a:focus, .employer-theme #site-select-desktop .nav-pills>li.active>a:hover, .employer-theme #site-select-desktop .nav>li>a:focus {
    background-color: transparent;
    color: #C95109;
    box-shadow: 0px -3px 0px #C95109 inset;
}
.employer-theme .wrapper-top-row .nav-pills>li.active>a, 
.employer-theme .wrapper-top-row .nav-pills>li.active>a:focus, 
.employer-theme .wrapper-top-row .nav-pills>li.active>a:hover, 
.employer-theme .wrapper-top-row .nav>li>a:focus, 
.employer-theme .wrapper-top-row .nav>li>a:hover {
    color: #fff;
}
.employer-theme #site-select-desktop .nav>li:not(.active)>a:hover {
    color: #fff;
    background-color: transparent;
}
.employer-theme .masthead-homepage {
    height: 400px;
    outline: 0;
    -webkit-background-size: cover!important;
    background-size: cover!important;
    background-position: center top!important;
    background-repeat: none;
}
.employer-theme .masthead-standard {
    height: 320px;
    outline: 0;
    -webkit-background-size: cover!important;
    background-size: cover!important;
    background-position: center top!important;
    background-repeat: none;
    padding-top: 20px;
}
.employer-theme .masthead-homepage .inner h2,
.employer-theme .masthead-standard .inner h2 {
    max-width: 520px;
    width: 50%;
    font-size: 46px;
    color: #181816;
    margin-bottom: 10px;
    font-family: 'Charlie-Std-Medium';
    padding-bottom: 10px;
    line-height: 1em;
}
.employer-theme .masthead-homepage .inner h4 {
    font-size: 24px;
    color: #181816;
    width: 50%!important;
    margin-left: 0;
    line-height: 1.2;
    margin-bottom: 25px;
    font-family: 'Charlie-Std-Medium';
}
.employer-theme .masthead-standard .inner h4 {
    font-size: 24px;
    color: #181816;
    width: 50%!important;
    margin-left: 0;
    line-height: 1.2;
    margin-bottom: 5px;
    font-family: 'Charlie-Std-Medium';
}
.employer-theme #global_search_form .mlc-glyph.icon-mlc-find-your-super, 
.employer-theme #global_search_form_mobile .mlc-glyph.icon-mlc-find-your-super {
    color: #fff;
    background-color: transparent;
}
.employer-theme .masthead-homepage .inner p,
.employer-theme .masthead-homepage .inner p a span,
.employer-theme .masthead-standard .inner p,
.employer-theme .masthead-standard .inner p a span {
  margin-bottom: 0;
}
.employer-theme .black-white-col .col-custom:first-child {
  background-color: #181816;
  color: #fff
}
.employer-theme .black-white-col.black-white-br-radius .col-custom:first-child {
  border-bottom-right-radius: 40px;
}
.employer-theme .black-white-col .col-custom:nth-child(2) {
  background-color: #fff;
  color: #000;
}
.employer-theme .black-white-col h2 {
  font-family: "Charlie-Std-Medium";
  font-size: 42px;
  padding-bottom: 20px;
}
.employer-theme .black-white-col h4 {
  font-family: 'Charlie-Std-Regular';
  font-size: 30px;
  line-height: 40px;
  font-weight: lighter;
  padding-bottom: 40px;
  width: 95%;
}
.employer-theme .caret:after {
  content: ' ';
  display: inline-block;
  border-bottom: 1px solid #181816;
  border-right: 1px solid #181816;
  height: 8px;
  position: absolute;
  top: 15px;
  width: 8px;
  transform: rotate(45deg);
}
.employer-theme .caret {
    display: inline-block;
    width: 0;
    height: 0;
    border-top: none;
    border-right: 4px solid transparent;
    border-left: 8px solid transparent;
}
.employer-theme .row-footer {
    color: #fff;
    background-color: #181816;
}

.employer-theme .row-footer .row-footer-container > div:nth-child(2) {
    border-top: 1px solid rgba(153, 148, 138, .25);
    margin-top: 26px;
    padding-top: 10px;
}
.employer-theme .row-footer .wrapper.footer-bg {
    background-color: #181816;
}
.employer-theme .row-footer a {
    color: #D4743A;
}
.employer-theme .row-footer h3 {
    font-size: 24px !important;
}
.employer-theme .row-footer .wrapper.footer-bg ul.nav li a {
    color: #D4743A;
}
.employer-theme .row-footer .bootstrap-nav-secondary {
    right: 0px;
    position: absolute;
    bottom: 16px;
}
.employer-theme .breadcrumb {
    background-color: transparent;
    padding: 8px 0px;
    margin-bottom: 10px;
}
.employer-theme .breadcrumb a {
    color: #3B3B3B;
}
.employer-theme .dark-bg {
    background-color: #181816;
    color: #fff;
}
.employer-theme .cta-copy h3 {
    font-size: 20px;
    margin-bottom: 0px;
    font-family: 'Charlie-Std-Medium';
}
.employer-theme .numbered-card h3 {
    color: #D4743A;
    font-family: 'Charlie-Std-SemiBold';
    font-size: 35px;
}
.employer-theme .breadcrumb li, 
.employer-theme .mlc-primary-nav li, 
.employer-theme .nav-pills li, 
.employer-theme .nav-tabs li, 
.employer-theme .sidekick li {
    margin-bottom: 0;
}
@media (max-width:750px){
	.employer-theme .overlapped-col-left-bg {
    		height: 100%;
    		background: #fff;
	}
	.employer-theme .overlapped-col-right-bg {
    		height: 100%;
    		background: #fff;
	}
	.employer-theme .row-footer .bootstrap-nav-secondary {
    		left: 0px;
    		position: relative;
    		bottom: 16px;
	}
}
@media screen and (min-width: 1201px) {
	.employer-theme .inner {
    		max-width: 1020px!important;
 	}
}
.row.equal {
    display: flex;
    flex-wrap: wrap;
}
.vcenter-item {
    display: flex;
    align-items: center;
}
.spacing-pt-60 {
    padding-top: 60px;
}
.spacing-pb-60 {
    padding-bottom: 60px;
}
.spacing-pt-70 {
    padding-top: 70px;
}
.spacing-pb-70 {
    padding-bottom: 70px;
}
.spacing-pt-80 {
    padding-top: 80px;
}
.spacing-pb-80 {
    padding-bottom: 80px;
}
.employer-theme #mlc-header-top-nav {
  float: left;
}

@media (min-width: 768px){
  .employer-theme #mlc-header-top-nav {
      right: 8.33333333%;
      width: 50%;
  }
  .employer-theme #mlc-header-search-box {
      left: 50%;
      width: 8.33333333%;
  }
}

span.chevron-link {
  font-family: 'Charlie-Std-SemiBold';
  font-size: 18px;
  color: #C95109;
}
span.chevron-link:before {
    content: "\e605";
    margin-right: 20px;
    font-size: 12px;
    color: #C95109;
    font-family: icomoon;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    text-decoration: none; 
}
span.chevron-link:hover {
    text-decoration: none;
}
.employer-theme .left-bordered-row {
	border-left: 4px solid #741915;
	padding: 30px 20px 20px;
}
.employer-theme .left-bordered-row.cols {
	padding: 0px 20px;
}
.employer-theme .left-bordered-row h3 {
	color: #D4743A;
	font-size: 26px;
	margin-bottom: 0px;
}
.employer-theme .left-bordered-row p {
	margin-bottom: 0px;
}
.employer-theme .left-bordered-heading h3 {
	color: #D4743A;
	border-left: 4px solid #741915;
	padding-left: 15px;
}
.employer-theme .left-bordered-heading p {
	padding-left: 20px;
}
.employer-theme .img-content-tile .img-content-tile-text {
    background-color: #000;
    color: #fff;
    padding: 35px 30px 20px;
}
.employer-theme .masthead-homepage.masthead-overlay .container:before {
    background-color: #000;
    height: 400px;
    width: 50%;
    position: absolute;
    display: block;
    top: 0px;
    opacity: 0.7;
    left: 0px;
    border-bottom-right-radius: 40px;
}
.employer-theme .masthead-standard.masthead-overlay .container:before {
    background-color: #000;
    height: 320px;
    width: 50%;
    position: absolute;
    display: block;
    top: 0px;
    opacity: 0.7;
    left: 0px;
    border-bottom-right-radius: 40px;
}
.employer-theme .masthead-standard span.button-md {
  line-height: 20px;
}
.employer-theme .wrapper.masthead-overlay .inner h1,
.employer-theme .wrapper.masthead-overlay .inner h2,
.employer-theme .wrapper.masthead-overlay .inner h3,
.employer-theme .wrapper.masthead-overlay .inner h4,
.employer-theme .wrapper.masthead-overlay .inner h5,
.employer-theme .wrapper.masthead-overlay .inner h6 {
  color: #fff;
}
.employer-theme .masthead-overlay .breadcrumb a {
    color: #fff;
}
.employer-theme .masthead-overlay .breadcrumb li, .employer-theme .masthead-overlay .mlc-primary-nav li, .employer-theme .masthead-overlay .nav-pills li, .employer-theme .masthead-overlay .nav-tabs li, .employer-theme .masthead-overlay .sidekick li {
    color: #fff;
}
.employer-theme .masthead-overlay .breadcrumb .last.active {
    font-weight: bold;
}
.employer-theme .megamenu-row {
    padding-top: 30px;
}
.employer-theme .megamenu-col span.button-md {
    background-color: #c95109;
    color: #fff;
    margin-top: 0px;
    margin-bottom: 0px;
}
.employer-theme .megamenu-row #megamenu-col-1 .chevron-link a,
.employer-theme .megamenu-row #megamenu-col-2 .chevron-link a,
.employer-theme .megamenu-row #megamenu-col-3 .chevron-link a,
.employer-theme .megamenu-row #megamenu-col-4 .chevron-link a {
  display: inline-block;
  color: #c95109 !important;
  font-size: 16px !important;
}
.employer-theme .megamenu-col table {
  background-color: #C95109;
  padding: 20px;
  border-collapse: inherit;
  color: #fff;
}
.employer-theme .megamenu-col table h4 {
  font-size: 21px !important;
  margin-bottom: 25px;
}
.employer-theme .yamm-fw .dropdown-menu {
  box-shadow: none;
}
.employer-theme .navbar-default .navbar-nav>.open>a, .employer-theme .navbar-default .navbar-nav>.open>a:focus, .employer-theme .navbar-default .navbar-nav>.open>a:hover {
    padding-bottom: 27px;
    height: auto;
}
.employer-theme .megamenu-col:first-child {
  background-color: transparent;
  margin-top: 0px;
  margin-right: 0px;
  margin-left: 0px;
  color: inherit;
}
.employer-theme .megamenu-col {
  color: #434343;
}
.employer-theme .megamenu-col h4 {
  font-family: 'Charlie-Std-Medium';
  font-size: 24px !important;
  padding-top: 0px !important;
}
.employer-theme .megamenu-row .megamenu-col ul li {
  padding-left: 13px;
  font-size: 14px;
  border: none;
  margin-bottom: 10px;
}
.employer-theme .megamenu-row .megamenu-col ul li a {
  font-size: 16px !important;
  padding-top: 0px !important;
  padding-bottom: 10px !important;
}
.employer-theme .megamenu-row .megamenu-col ul li::marker {
  content: "\e605";
  margin-right: 20px;
  font-size: 12px;
  font-family: icomoon;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: none;
}
.employer-theme .megamenu-col .chevron-link {
  font-family: 'HelveticaNeueLTStd-Roman';
}
.employer-theme .megamenu-primary-nav .dropdown.open .caret:after {
    transform: rotate(225deg);
    top: 20px;
}
.employer-theme .megamenu-col table {
    background-color: none;
    padding: 0px;
    border-collapse: none;
    color: inherit;
}
.employer-theme .megamenu-col table td[headers="orange"] {
    background-color: #c95109;
    padding: 20px;
    color: #fff;
}
.employer-theme .megamenu-col table td[headers="dark"] {
    background-color: #181816;
    padding: 20px;
    color: #fff;
}
.employer-theme .media-left, .media>.pull-left {
    padding-right: 0!important;
}
.employer-theme .mlc-primary-nav.in .megamenu-row {
  padding: 15px;
}
.employer-theme .mlc-primary-nav.in .megamenu-col:first-child {
    min-height: auto;
}
.employer-theme .mlc-primary-nav.in .dropdown-menu ul {
    padding-left: 10px;
}
.employer-theme .mlc-primary-nav.in .megamenu-row .megamenu-col ul li {
    padding-left: 6px;
}
.employer-theme .mlc-primary-nav.in .caret:after {
    bottom: 22px;
    top: auto;
}
.employer-theme .mlc-primary-nav.in .dropdown.open .caret:after {
    bottom: 22px;
    top: auto;
}
@media screen and (max-width: 992px) {
    .employer-theme .wrapper.masthead-overlay .container:before {
        width: 100%;
    }
    .employer-theme #sidekick-mobile .sidekick li {
        width: 50%;
    }
    .employer-theme .masthead-homepage .container:before {
      background-color: #000;
      height: 400px;
      width: 100%;
      position: absolute;
      display: block;
      top: 0px;
      opacity: 0.7;
      left: 0px;
      border-bottom-right-radius: 40px;
    }
    .employer-theme .masthead-homepage .inner h1,
    .employer-theme .masthead-homepage .inner h2,
    .employer-theme .masthead-homepage .inner h3,
    .employer-theme .masthead-homepage .inner h4,
    .employer-theme .masthead-homepage .inner h5,
    .employer-theme .masthead-homepage .inner h6 {
        color: #fff;
        width: 100% !important;
    }
    
}
@media screen and (min-width: 599px) and (max-width: 750px) {
  .employer-theme .masthead-standard .inner h2 {
    width: 90%;
  }
  .employer-theme .masthead-standard .inner h4 {
    width: 90% !important;
  }
}
@media screen and (max-width: 599px) {
  .employer-theme .masthead-standard,
  .employer-theme .wrapper.masthead-standard.masthead-overlay .container:before {
    height: 185px;
  }
  .employer-theme .masthead-standard .inner h2 {
    font-size: 22px;
    width: 90%;
    margin-top: 0px !important;
    line-height: 1.2em;
  }
  .employer-theme .masthead-standard .inner h4 {
    font-size: 14px;
    width: 90% !important;
  }
  .employer-theme .masthead-standardh span.border-button-md, 
  .employer-theme .masthead-standard span.border-button-md-aqua, 
  .employer-theme .masthead-standard span.border-button-md-rubine, 
  .employer-theme .masthead-standard span.border-button-md-white-reverse, 
  .employer-theme .masthead-standard span.button-md, 
  .employer-theme .masthead-standard span.button-md-aqua, 
  .employer-theme .masthead-standard span.button-md-rubine {
     background-color: transparent!important;
     border: 3px solid #fff!important;
     color: #fff!important;
     padding: 5px 8px!important;
     height: 30px!important;
     line-height: 1.1em!important;
     min-width: 60px!important;
     font-size: 12px!important;
     margin-left: 0!important;
     font-weight: 400!important;
     margin-top: 30px;
  }
}
@media screen and (max-width: 767px) {
  .employer-theme .masthead-standard span.button-md, .employer-theme .masthead-standard span.button-md-aqua, .employer-theme .masthead-standard span.button-md-rubine {
    top: 0px;
  }
}
@media screen and (max-width: 582px) {
  .employer-theme .masthead-standard span.button-md, .employer-theme .masthead-standard span.button-md-aqua, .employer-theme .masthead-standard span.button-md-rubine {
    margin-top: 10px;
  }
}
@media screen and (min-width: 993px){
  .employer-theme .megamenu-col.col-sm-3 {
    width: 25%;
    padding: 0 15px;
  }
}
@media screen and (max-width: 992px) {
    .employer-theme .megamenu-col:first-child {
        display: block;
    }
}
/*Copied from Ensighten*/

@media (min-width: 768px){

  .employer-theme #mlc-header-top-nav {
	right: 0% !important;

  }

  .employer-theme #mlc-header-search-box {
	left: 0px !important;

  }

}
@media screen and (max-width: 992px) {
	.employer-theme #sidekick-mobile .sidekick li {
	width: 33.33% !important;

  }

}
.employer-theme #mlc-header-top-nav {
	float: left !important;

}

.employer-theme .wrapper.wrapper-top-row .nav>li>a {	
color: #fff !important;

}
.employer-theme #sidekick-desktop.minimized {

    right: -215px !important;

}

.employer-theme #sidekick-desktop {

    width: 280px !important;

}

.employer-theme .img-content-tile-text span.icon-mlc-chevron {

    float: left !important;

    display: inline-block !important;

    padding-right: 10px !important;

}

.employer-theme .row.equal.equal-col-ht > div {

    background-clip: content-box;

    background-color: #000;

}

.employer-theme .megamenu-col a:hover {

  text-decoration: underline !important;

}
