@import "../mixins.less";
@import "../variables.less";

@first-tab-background-image: url("/content/dam/mlc/images/content/advice/general_advice_and_info.jpg");
@first-tab-background-width: 310px;
@first-tab-background-height: 160px;

@second-tab-background-image: url("/content/dam/mlc/images/content/advice/focused_advice.jpg");
@second-tab-background-width: 310px;
@second-tab-background-height: 160px;

@third-tab-background-image: url("/content/dam/mlc/images/content/advice/comprehensive_advice.jpg");
@third-tab-background-width: 310px;
@third-tab-background-height: 160px;

.choose-advice-tabs {
  ul.nav-tabs {
    margin-bottom: 60px;
    border-bottom: 0;

    @media screen and (min-width: @screen-md-min) {
      margin-bottom: 190px;
    }
    @media screen and (min-width: @screen-md-min) and (max-width: @screen-lg-min) {
      margin-bottom: 400px;
    }
    @media screen and (min-width: @screen-lg-min) {
      margin-bottom: 190px;
    }

    li {
      background-color: transparent;
      border-left: 0;
      border-top: 0;
      border-bottom-width: 4px;
      a {
        font-weight: bold;
      }
      //Active bar
      &.active {
        border-bottom-color: @primary-color-orange;
        //Active triangle
        &:after {
          content: "";
          top: -6px;
          right: -50%;
          position: relative;
          .triangle-up(@primary-color-orange, 0 6px 9px 6px);
        }
        a {
          margin-bottom: -22px;
        }
      }
      //Images above the tabs when at or above md view port size
      @media screen and (min-width: @screen-md-min) {
        a {
          margin-top: 160px;
        }
        &:first-of-type {
          padding-right: 20px;
          .pseudo-before-background-image(@first-tab-background-width, @first-tab-background-height, @first-tab-background-image);
        }

        &:nth-of-type(2) {
          padding-right: 165px;
          .pseudo-before-background-image(@second-tab-background-width, @second-tab-background-height, @second-tab-background-image);
        }

        &:nth-of-type(3) {
          padding-right: 110px;
          .pseudo-before-background-image(@third-tab-background-width, @third-tab-background-height, @third-tab-background-image);
        }

      }
      @media screen and (min-width: @screen-md-min) and (max-width: @screen-lg-min) {
        &:nth-of-type(3) {
          padding-top: 20px;
        }
      }
    }
  }
}