 .nab-akamai-video-section .btn-vod-play:focus {
   background-image:url(/content/dam/mlc/images/icons/video-bt-play-active.png);
   width: 15px;
   height: 20px;
  }

.nab-akamai-video-section .btn-vod-pause:focus {
   background-image:url(/content/dam/mlc/images/icons/video-bt-pause-active.png);
   width: 15px;
   height: 17px;
  }

.nab-akamai-video-section .btn-vod-stop:focus {
   background-image:url(/content/dam/mlc/images/icons/video-bt-stop-active.png);
   width: 15px;
   height: 15px;

  }

.nab-akamai-video-section .btn-vod-forward:focus {
   background-image:url(/content/dam/mlc/images/icons/video-bt-forward-active.png);
   width: 23px;
   height: 16px;
   background-color:transparent;
  }

.nab-akamai-video-section .btn-vod-back:focus {
   background-image:url(/content/dam/mlc/images/icons/video-bt-back-active.png);
   width: 23px;
   height: 16px;
  }

.nab-akamai-video-section .btn-vod-volup:focus {
   background-image:url(/content/dam/mlc/images/icons/video-bt-vol-up-active.png);
   width: 25px;
   height: 19px;
  }

.nab-akamai-video-section .btn-vod-voldown:focus {
   background-image:url(/content/dam/mlc/images/icons/video-bt-vol-down-active.png);
   width: 17px;
   height: 19px;
  }

.nab-akamai-video-section .btn-vod-transcript:focus {
   background-image:url(/content/dam/mlc/images/icons/video-bt-trasncript-active.png);
   width: 25px;
   height: 21px;
}

//Styles to hide the stop button

.nab-akamai-video-section .btn-vod-stop,
.nab-akamai-video-section .btn-vod-stop:hover,
.nab-akamai-video-section .btn-vod-stop:focus {
  display:none;
}