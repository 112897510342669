/* Author: Imran Sabaroedin */

.padding-bottom-30 {
     padding-bottom:30px !important;
}

.cf-3para-css div {
    -webkit-column-count: 3; /* Chrome, Safari, Opera */
    -moz-column-count: 3; /* Firefox */
    column-count: 3;
}

@media (max-width: 992px) {
  .cf-nav ul { padding-left: 0%; }
  .cf-findoutmore-div > div, .cf-findoutmore-div h4  {text-align: center ! important; }
}

@media (max-width: 768px) {
    .cf-3para-css div {
   	 -webkit-column-count: 2; /* Chrome, Safari, Opera */
   	 -moz-column-count: 2; /* Firefox */
   	 column-count: 2;
    }
}

@media (max-width: 480px) {
    .cf-3para-css div {
    	-webkit-column-count: 1; /* Chrome, Safari, Opera */
    	-moz-column-count: 1; /* Firefox */
    	column-count: 1;
    }
}

.cf-nav ul {
     padding-left: 15%;
}

.cf-nav li {
     margin-bottom: 0px;
     padding-left: 10px;
     padding-right: 10px;
}

.cf-nav li a {
     color: #3B3B3B ! important;
}

.cf-nav li:first-child a {
     font-weight: bold;
}

.cf-nav .active a {
     background-color: #F0EEEA ! important;
     border-bottom: 5px solid #E85100;
     border-radius: 0px;
}

.cf-findoutmore-div h4 {
    margin-top: 30px;
}

.cf-findoutmore-div P {
    margin: 0;
}

.border-bottom-1px {
    border-bottom: 1px solid;
}

.cf-oursuccess-list td {
     vertical-align:top;
}

.cf-hope-accordion > div > div > div > div:nth-last-child(1) {
     padding: 10px;
}

.cf-hope-accordion > div > div > div > div:nth-last-child(1) div {
     padding-top: 0px !important;
}

.masthead-wrapper {
     background-image: url("/content/mlc/en/personal/home/_jcr_content/par-col-body-2/bootstrap_row/col1/bootstrap_content_li_0/entries/bootstrap_content_li_0/image.img.jpg/1423116780746.ManBanner.jpg");
}

.masthead-wrapper h1 {
     color: #be4601 ;
     margin-bottom:0px;
     margin-top:10px !important;
}

.masthead-wrapper h2 {
     color: #3b3b3b ;
}

.masthead-wrapper .breadcrumb * {
     color: #333333;
}

.get-in-touch div > div > div > div > div h4 {
     margin-top: 0px ! important;
     padding-top: 0px ! important;
}


//gh ------

/* 19.02 --------- */

.news-insights-wrapper .cf-nav ul { padding-left: 0;}
.news-insights-wrapper .cf-nav li { text-align:center; width:16.4%;}


@media screen and (min-width: 993px) {
.news-insights-wrapper .cf-nav .nav-pills>li { text-align:center; float: left;}
}

@media screen and (max-width: 992px) {
.news-insights-wrapper .cf-nav.nav-pills>li {float: none;}
.news-insights-wrapper .cf-nav .nav-pills>li { width:100% !important; text-align:left;}
}
