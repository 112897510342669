.retirement-product-page-intro {
    background-color: @grey-bg;
    padding-top: 60px;
    padding-bottom: 60px;
}

.border-button-large {
    border: 3px solid @primary-color-orange;
    border-radius: 0;
    font-size: 18px;
    font-family: @font-family-heading;
    color: @primary-color-orange;
    height: 44px;

    &:hover,
    &:focus {
        background-color: @primary-color-orange;
        color: #ffffff;
    }
}

//MasterKey Pension Fundamental product page

.product-award {
    .media-left {
        padding-right: 0;
        width: 103px;

        img {
            width: 83px;
        }
    }
}

.mkpf-forms-and-documents {
    .fa-file-pdf {
        font-size: 16px;
        padding-right: 10px;
    }
}
  
  
  @media screen and (max-width: 991px) {
    .retirement-product-page-intro {
        padding-top: 60px;
        padding-bottom: 44px;
        
        .media-left {
        text-align: center;
        padding-right: 0;
        display: block;
        width: 100%;

        img {
            margin-bottom: 60px;
        }
        }
    }
}