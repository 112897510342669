@import "../../../../bootstrap-base-design/bootstrap-TEMP/clientlibs/css/scaffolding.less";

//Custom Mixins

// Bootstrap includes a number of Mixins: see bootstrap-TEMP/clientlibs/css/mixins.less

/*
* Expands the width and height to a specified value for use on a placeholder for modal video.
* ie, creates a clickable area to launch the modal, while using the button text for screen readers.
* Should be used in combination with a background image, either through styling or (preferably) the
* Bootstrap Extra tab in components.
*
* Usage:
*
*  .class-name {
*   .modal-video-placeholder(310px,160px);
*  }
*/
.modal-video-placeholder(@width, @height) {
  width: @width;
  height: @height;
  a {
    width: @width;
    height: @height;
    .button-text {
      // Link text will be announced to screen readers, but will not be visible.
      .sr-only;
    }
  }
}
/*
* Adds a pseudo :before class with a background image + specified width/height
*/
.pseudo-before-background-image(@width, @height, @background-image) {
  &:before {
    position: absolute;
    content: "";
    width: @width;
    height: @height;
    background-image: @background-image;
  }
}

/*
* Creates a triangle pointing up
*/
//noinspection CssOptimizeSimilarProperties
.triangle-up(@color, @width) {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: @width;
  border-color: transparent transparent @color transparent;
}

// SSO and white label pages

.pageTitle {
  font-size:18px;
  font-family:Verdana, sans-serif !important;
  color:#333333 !important;
  padding-top:15px !important;
}

.toponeTitle {
  background-image: url("http://mlc-mlcd1.non.whu.nab.com.au/content/dam/mlc/images/sso/icon-information.png");
  background-position: 0px 0px;
  background-repeat: no-repeat;
  font-family:Verdana, sans-serif !important;
  font-size: 12px ! important;
  font-weight: bold;
  line-height: 14px;
  margin-bottom: 10px ! important;
  padding-bottom:  5px ! important;
  padding-top: 3px ! important;
  padding-left:30px;
}

.toponeContent {
  line-height:16px;
  margin-bottom:10px;
}

.formHeader {
  color: #ffffff;
  left: 0%;
  margin-bottom: 0px;
  padding-bottom: 7px;
  padding-top: 7px;
  position: relative;
  width: 480px;
  padding-left:15px;
  padding-right:15px;
  font-size:12px !important;
  margin:0 auto;
  line-height:14px;
  font-weight:bold;
}

.formContainer {
  border-bottom: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  border-top:0;
  padding: 15px;
  margin:0 auto 20px;
  margin-bottom:20px;
  width:480px;
}

.formContainerInput {
  border-radius: 0px;
  border: 1px solid #d8d8d8;
  height: 35px ! important;
}

.formContainerInputFocus {
  border: 1px solid #067861 ! important;
  box-shadow: none ! important;
}

.submitbutton {
  margin: 0px auto 15px ! important;
  height:auto;
  font-size:11px !important;
  font-family:Verdana, sans-serif !important;
  font-weight:bold;
  padding:5px 10px;
  width:auto;
  border-radius:4px;
  -moz-border-radius:4px;
  -webkit-border-radius:4px;
}

.fieldset {
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 10px;
  margin-top: 10px;
  padding-bottom: 20px;
}

.links {
  font-size:11px;
  color:#666666 !important;
  text-decoration:underline;
  font-family:Verdana, sans-serif !important;
}

.formgrouplabel {
  font-size:11px;
  font-weight:normal;
  font-family:Verdana, sans-serif !important;
}

.outageMessage {
  padding-bottom: 4px;
  padding-top: 15px;
  padding-left:20px;
  padding-right:20px;
  background-color:#feface;
  margin-bottom:20px;
}

.banner-btn-mobile {
  background-color: transparent ! important;
  border: 3px solid #ffffff ! important;
  color: #ffffff ! important;
  padding: 5px 8px ! important;
  height: 30px ! important;
  line-height: 1.1em ! important;
  min-width: 60px ! important;
  font-size: 12px ! important;
  margin-left: 30px;
  font-weight: normal !important;
  margin-top:30px;
}

// Mixins for Badge sites

.badge-page-bg() {
  min-height:100vh;
  background-color:@badge-page-bg;
}

.badge-header() {
  background-color:#3b3b3b;
  color:#ffffff;
  padding-top:20px;
  padding-bottom:0px;
}

.badge-footer() {
  background-color:@badge-page-bg;
  padding-top:40px;
  padding-bottom:40px;
}

//Mixins for Unchanging Camapign

.button-small() {
  color: #ffffff;
  font-family: @font-family-sans-serif;
  font-size: 14px;
  height: 32px !important;
  margin-bottom: 22px;
  margin-top: 20px;
  min-width: 110px;
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
  display:inline-block;
  padding-top:5px;
}

.campaign-nav-stages() {
  background-repeat: no-repeat;
  padding-left: 35px;
  background-position: 0 18px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.campaign-nav-stages-active() {
  border-bottom: 2px solid @campaign-nav-active-border;
  margin-bottom: -2px;
  width: 245px;
}

.campaign-nav-mob-stages() {
  background-repeat: no-repeat;
  background-size: 13px 18px;
  background-position: 0 6px;
  position: relative;
  left: 22%;
}

.provo-body-content-3col() {
  background-color:#ffffff;
  padding: 20px 20px 0 20px;
  height: 265px;
}

.provo-body-content-2col() {
  background-color:#ffffff;
  padding: 20px 20px 0 20px;
  height: 240px;
}

.get-in-touch-provo-mob() {
  background-repeat: no-repeat;
  padding-left: 45px;
  background-position: 0 5px;
}



