/* Contact us Page & Forms and Docs The Search style */



.embedded-search-wrapper .form-group {text-align:left;} /* to push this to left aLIGN */
.wrapper.wrapper-dark.embedded-search-wrapper .form-group {margin-left:0;}

.embedded-search-wrapper .form-group input {height:50px;} /*the height of the input text field */

.embedded-search-wrapper .form-group button {height:50px; font-size: 20px;
font-family:Charlie-Std-Medium;} /*the height & font properties for the submit button */



@media screen and (max-width: 599px) {

.global_search_form .form-group .input-group {display: block; width:100%} /* responsive input to full width */

.embedded-search-wrapper .form-group {text-align:center;} /* to push this to center aLIGN */
.embedded-search-wrapper .form-group .global_search_field {width: 100% !important} /* responsive to full width */

.embedded-search-wrapper .global_search_form .form-group button {margin-top: 10px; width:100%} /*responsive button to full width*/

.wrapper.wrapper-dark.embedded-search-wrapper {padding:0px} /* adjusting the padding between the search form and the wrapper */

.embedded-search-wrapper .form-group {margin-top:-70px; padding-bottom:45px} /* where the search form positioning in mobile*/


.embedded-search-wrapper .input-group-btn, .wrapper.faq-component-wrapper .input-group-btn, .wrapper.search-component-wrapper .input-group-btn {display:initial;}


}


@media screen and (min-width: 600px) and (max-width: 992px) {

.global_search_form .form-group .input-group {display:inline-block; width:90%} /* responsive input to this width */


.wrapper.wrapper-dark.embedded-search-wrapper {padding:10px 0}

.embedded-search-wrapper .form-group {margin-top:-55px; padding-bottom:70px} /* where the search form positioning in mobile*/


.embedded-search-wrapper .form-group .global_search_field { width: 55%; } /*responsive width of the search */

.embedded-search-wrapper .form-group .global_search_field {
  width: 70%!important;}

.embedded-search-wrapper .form-group {margin-left:9%;}

}

@media screen and (min-width: 992px) {

  .global_search_form .form-group .input-group {display:inline-block; width:90%} /* responsive input to this width */

.embedded-search-wrapper .form-group .global_search_field {
  width: 60%!important;}

.wrapper.wrapper-dark.embedded-search-wrapper {padding: 0px 0;}  /* adjusting the padding between the search form and the wrapper */

.embedded-search-wrapper .form-group {margin-top: -50px; padding-bottom:40px}  /* adjusting the padding between the search form and the wrapper */

.embedded-search-wrapper .form-group .global_search_field { width: 55%; }  /*responsive width of the search */

.embedded-search-wrapper .form-group {margin-left:16%;}


}


@media screen and (max-width: 767px) and (min-width: 601px){
.embedded-search-wrapper .input-group-btn .btn {
  padding-top: 5px!important; /* search button padding top */
}
}


.embedded-search-wrapper .global_search_form .form-group button { min-width:140px;} /*min width 140px button */

.wrapper.wrapper-dark {background-color: #fdfbf7;} /*change background color to this */


/* FAQs page for Search style and Tabs issue  */

.search-component-wrapper #site-search, .faq-component-wrapper #faqs-search {margin-bottom:30px;}

/*restyle the button */
.search-component-wrapper .input-group .input-group-btn button,
.faq-component-wrapper .input-group .input-group-btn button {
  min-width:140px;
  height:50px !important;
  padding-top:8px;
}

.search-component-wrapper .input-group input, .faq-component-wrapper .input-group input {
  height:50px; /*height of the input */

}

.faq-component-wrapper .input-group .input-group-btn button:hover {background-color:#bc4401}  /*hover color */

@media screen and (max-width:599px) {

.search-component-wrapper #site-search, .faq-component-wrapper #faqs-search .input-group {display:block} /*responsive to full width */

/*responsive to full width */
.search-component-wrapper .input-group .input-group-btn button,
.faq-component-wrapper .input-group .input-group-btn button {
  margin-top:10px;
  height:50px; width:100%; }

}

@media screen and (max-width:992px) {
.wrapper.faq-component-wrapper #faqs-browse {float:none} /* fixed issue accordion width at the moment not full width in this window size */
}


/*Border top none in content when the accordion panel is open or - */
.panel-group .panel-heading+.panel-collapse>.panel-body, .panel-group .panel-heading+.panel-collapse>.list-group {border-top:0}

/* Contact us Page & Forms and Docs The Search style */



.embedded-search-wrapper .form-group {text-align:left;} /* to push this to left aLIGN */
.wrapper.wrapper-dark.embedded-search-wrapper .form-group {margin-left:0;}

.embedded-search-wrapper .form-group input {height:50px;} /*the height of the input text field */

.embedded-search-wrapper .form-group button {height:50px; font-size: 20px;
font-family:Charlie-Std-Medium;} /*the height & font properties for the submit button */



@media screen and (max-width: 599px) {

.global_search_form .form-group .input-group {display: block; width:100%} /* responsive input to full width */

.embedded-search-wrapper .form-group {text-align:center;} /* to push this to center aLIGN */
.embedded-search-wrapper .form-group .global_search_field {width: 100% !important} /* responsive to full width */

.embedded-search-wrapper .global_search_form .form-group button {margin-top: 10px; width:100%} /*responsive button to full width*/

.wrapper.wrapper-dark.embedded-search-wrapper {padding:0px} /* adjusting the padding between the search form and the wrapper */

.embedded-search-wrapper .form-group {margin-top:-70px; padding-bottom:45px} /* where the search form positioning in mobile*/

}


@media screen and (min-width: 600px) and (max-width: 992px) {

.global_search_form .form-group .input-group {display:inline-block; width:90%} /* responsive input to this width */


.wrapper.wrapper-dark.embedded-search-wrapper {padding:10px 0}

.embedded-search-wrapper .form-group {margin-top:-55px; padding-bottom:70px} /* where the search form positioning in mobile*/


.embedded-search-wrapper .form-group .global_search_field { width: 55%; } /*responsive width of the search */

.embedded-search-wrapper .form-group .global_search_field {
  width: 70%!important;}

.embedded-search-wrapper .form-group {margin-left:9%;}

}

@media screen and (min-width: 992px) {

  .global_search_form .form-group .input-group {display:inline-block; width:90%} /* responsive input to this width */

.embedded-search-wrapper .form-group .global_search_field {
  width: 60%!important;}

.wrapper.wrapper-dark.embedded-search-wrapper {padding: 0px 0;}  /* adjusting the padding between the search form and the wrapper */

.embedded-search-wrapper .form-group {margin-top: -50px; padding-bottom:40px}  /* adjusting the padding between the search form and the wrapper */

.embedded-search-wrapper .form-group .global_search_field { width: 55%; }  /*responsive width of the search */

.embedded-search-wrapper .form-group {margin-left:16%;}


}


@media screen and (max-width: 767px) and (min-width: 601px){
.embedded-search-wrapper .input-group-btn .btn {
  padding-top: 5px!important; /* search button padding top */
}
}


.embedded-search-wrapper .global_search_form .form-group button { min-width:140px;} /*min width 140px button */

.wrapper.wrapper-dark {background-color: #fdfbf7;} /*change background color to this */


/* FAQs page for Search style and Tabs issue  */

.search-component-wrapper #site-search, .faq-component-wrapper #faqs-search {margin-bottom:30px;}

/*restyle the button */
.search-component-wrapper .input-group .input-group-btn button,
.faq-component-wrapper .input-group .input-group-btn button {
  min-width:140px;
  height:50px !important;
  padding-top:8px;
}

.search-component-wrapper .input-group input, .faq-component-wrapper .input-group input {
  height:50px; /*height of the input */

}

.faq-component-wrapper .input-group .input-group-btn button:hover {background-color:#bc4401}  /*hover color */

@media screen and (max-width:599px) {

.search-component-wrapper #site-search, .faq-component-wrapper #faqs-search .input-group {display:block} /*responsive to full width */

/*responsive to full width */
.search-component-wrapper .input-group .input-group-btn button,
.faq-component-wrapper .input-group .input-group-btn button {
  margin-top:10px;
  height:50px; width:100%; }

/*Search result page */
.search-component-wrapper .input-group {display:block}


}

@media screen and (max-width:992px) {
.wrapper.faq-component-wrapper #faqs-browse {float:none} /* fixed issue accordion width at the moment not full width in this window size */
}


/*Border top none in content when the accordion panel is open or - */
.panel-group .panel-heading+.panel-collapse>.panel-body, .panel-group .panel-heading+.panel-collapse>.list-group {border-top:0}


/*hide unwanted icon search in forms and docs */
.embedded-search-wrapper #global_search_form .mlc-glyph.icon-mlc-find-your-super {display:none !important}


/*Search results */

@media screen and (max-width:767px) {

/*Search result page fixed padding*/
.search-component-wrapper {padding:60px 15px;}

/*Search result page fixed tabs border*/
.search-component-wrapper .search-banner .search-types .btn {border-left:0; border-right:0;}
.search-component-wrapper .btn-group button.search-type-selected {border:0}

}

/*for search box in faqs and contact us pages,  make space from the h2 and adjust position */
.wrapper.wrapper-dark.embedded-search-wrapper .form-group {margin-top:-50px; text-align:left; padding-bottom:40px;}

/*for search box in forms and docs page , make space from the h2 and adjust position */
.embedded-search-wrapper .form-group {text-align:center; margin:auto; margin-top:0px; padding-bottom:0px;}


/*addapting new megamenu style so this below for top right search box needs to change */
.nav-mobile-header .col-xs-5 {width:100%; position:absolute;}


@media screen and (max-width: 992px) {

/*for search box top right, position the search button in this media querie*/
#global_search_form_mobile .form-group .input-group .input-group-btn button
{right: 0px; top:3px }

/*for faqs, contact us search box make space from the h2 in this media querie */
.wrapper.wrapper-dark.embedded-search-wrapper .form-group {padding-bottom:60px;}

}


@media screen and (min-width: 600px) {

/*forms & docs search box, close the gap from input field with go button in this media querie */
.embedded-search-wrapper .form-group .global_search_field { margin-right: -5px;}

}