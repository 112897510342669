@import "../mixins.less";

.button-sm-aqua {
  background-color: @primary-color-aqua;
  .button-small;

  &:hover {
    background-color: @primary-color-aqua-hover;
  }
}

.button-sm-rubine {
  background-color: @primary-color-rubine;
  .button-small;

  &:hover {
    background-color: @primary-color-rubine-hover;
  }
}

span.button-sm:hover {
  background-color: @primary-color-orange-hover;
}

// Campaign Banner

.wrapper-campaign-banner {
  height: 320px;
  background-size: cover !important;
  background-position: center top !important;

  .text-image {
    height: 320px;
    vertical-align: middle;
    display: table-cell;
    max-width: 370px;

    h1 {
      font-size: 36px;
      color: @text-color-white !important;
    }

    h2 {
      font-size: 28px;
      margin-top: 40px !important;
      font-family: @font-family-heading !important;
    }
  }
}

//Campaign Navigation

.campaign-nav {
  border-bottom: 2px solid #c8c6c2;

  p, h4 {
    margin-bottom: 0;
  }

  a {
    color: @default-color;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }

    a:focus,
    a:visited {
      text-decoration: none;
    }
  }

  .wrapper-stage1 {
    background-image: url('/content/dam/mlc/images/unchanging/icons/building-wealth-egg.png');
    .campaign-nav-stages;

    &:hover {
      background-image: url('/content/dam/mlc/images/unchanging/icons/starting-out-egg-dark.png');
      .campaign-nav-stages-active;
    }
  }

  .wrapper-stage2 {
    background-image: url('/content/dam/mlc/images/unchanging/icons/managing-your-future-egg.png');
    .campaign-nav-stages;

    &:hover {
      background-image: url('/content/dam/mlc/images/unchanging/icons/gearing-up-egg-dark.png');
      .campaign-nav-stages-active;
    }
  }

  .wrapper-stage3 {
    background-image: url('/content/dam/mlc/images/unchanging/icons/planning-for-retirement-egg.png');
    .campaign-nav-stages;

    &:hover {
      background-image: url('/content/dam/mlc/images/unchanging/icons/making-most-of-it-egg-dark.png');
      .campaign-nav-stages-active;
    }
  }
  
  .wrapper-stage4 {
    background-image: url('/content/dam/mlc/images/unchanging/icons/stage4-egg.png');
    .campaign-nav-stages;

    &:hover {
      background-image: url('/content/dam/mlc/images/unchanging/icons/stage4-dark.png');
      .campaign-nav-stages-active;
    }
  }

  .wrapper-stage1:hover a,
  .wrapper-stage2:hover a,
  .wrapper-stage3:hover a,
  .wrapper-stage4:hover a {
    color: @primary-color-orange-hover;
  }

  .active-stage1,
  .active-stage2,
  .active-stage3,
  .active-stage4 {
    color: @primary-color-orange;
  }

  .active-stage1:hover,
  .active-stage2:hover,
  .active-stage3:hover,
  .active-stage4:hover {
    border-bottom: 0 !important;
  }
}

// Camapign Body

.campaign-body-wrapper {
  background-color: #f0eeea;
  padding-top: 40px;
  padding-bottom: 40px;

  a {
    cursor: pointer;
  }

  .media-left {
    padding-right: 0;
  }

  .campaign-intro-content h4 {
    width:60%;
    margin-left: auto;
    margin-right: auto;
  }

  .main-promo-wrapper {
    margin-bottom: 40px;
    margin-top: 40px;

    .media-left {
      width: 490px;
      height: 490px;
      padding-right: 0;
    }

    .text-image {
      background-color: #ffffff;
      height: 490px;
      vertical-align:middle;
      display: table-cell;
      padding-left: 40px;
      padding-right: 40px;
      background-image:url('/content/dam/mlc/images/unchanging/icons/tile-bookmark.png');
      background-repeat: no-repeat;
      background-position: 40px 0;
      background-size: 36px 46px;
    }

    h2 a small {
      color: @default-color;
    }

    .main-promo-orange,
    .main-promo-aqua,
    .main-promo-rubine {
      a {
       color: @default-color;
       cursor: pointer;

       &:hover {
         text-decoration: none;
       }
      }

      a:visited,
      a:focus {
        text-decoration: none;
      }
    }

    .main-promo-orange a:hover,
    .main-promo-orange a:hover small {
      color: @primary-color-orange;
    }

    .main-promo-aqua a:hover,
    .main-promo-aqua a:hover small {
      color: @primary-color-aqua;
    }

    .main-promo-rubine a:hover,
    .main-promo-rubine a:hover small {
      color: @primary-color-rubine;
    }
  }
}

.provo-3col-orange,
.provo-3col-aqua,
.provo-3col-rubine {
  .provo-body-content-3col;

  a {
    color: @default-color;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  a:visited,
  a:focus {
    text-decoration: none;
  }

  h2 a small {
    color: @default-color;
  }

  .media-left {
    padding-right: 0;
  }
}

.provo-2col-orange,
.provo-2col-aqua,
.provo-2col-rubine {
  .provo-body-content-2col;

  a {
    color: @default-color;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  a:visited,
  a:focus {
    text-decoration: none;
  }

  h2 a small {
      color: @default-color;
  }

  .media-left {
    padding-right: 0;
  }
}

.provo-3col-orange,
.provo-2col-orange {
  a:hover,
  h3 a:hover small {
    color: @primary-color-orange;
  }
}

.provo-3col-rubine,
.provo-2col-rubine {
  a:hover,
  h3 a:hover small {
    color: @primary-color-rubine;
  }
}

.provo-3col-aqua,
.provo-2col-aqua {
  a:hover,
  h3 a:hover small {
    color: @primary-color-aqua;
  }
}

.wrapper-provo-image {
  .media-left {
    display: block;
    width: 100%;
  }
}

// Campaign Footer

.wrapper-cta {
  background-color: #f0eeea;
}

.campaign-get-in-touch-wrapper {
  background-color: #f6f3ef;
  padding-top: 25px;
  padding-bottom: 15px;

  h4 {
    padding-top: 5px;

    a {
      color: @default-color;
      cursor: pointer;
    }
  }
}

// Provocation pages

 .provo-body-wrapper {
  background-color: #efedea;
  padding-top: 40px;
  padding-bottom: 40px;

  .provo-body-content {
    .button-sm-aqua,
    .button-sm,
    .button-sm-rubine {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .campaign-infographic-wrapper {
    margin-top: 40px;
    padding-bottom: 24px;
  }

  .media-left {
    padding-right: 0;
  }
 }

.get-in-touch-aqua,
.get-in-touch-orange,
.get-in-touch-rubine {
  h3 {
    padding-top: 25px;
    margin-bottom: 10px;
  }

  h4 {
   margin-bottom: 0;

    a {
     color: @default-color;
      cursor: pointer;
    }
  }
}

.get-in-touch-aqua {
  border-bottom: 5px solid #01838c;
}

.get-in-touch-orange {
  border-bottom: 5px solid #c95109;
}

.get-in-touch-rubine {
  border-bottom: 5px solid #ce0058;
}

.share-article-wrapper img {
  margin-left: 10px;
  width: 38px;
  height: 38px;
}

a[href^=tel]:link,
a[href^=tel]:visited,
a[href^=tel]:hover {
  pointer-events: none;
  cursor: default;
}

//Videos

.wrapper-campaign-video {
    margin-top: 40px;
}

.campaign-video {
    margin-top: 20px;
    margin-right: -11px;
}

.campaign-video-content {
    margin-top: 20px !important;
    padding: 20px;
    background-color: #ffffff;
    margin-left: -10px;
    min-height: 400px;

}

.campaign-video-content-2col {
    background-color:#ffffff;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
}

.campaign-video-content-2col .text-image {
    width: 80%;
}

.campaign-video-2col {
    margin-bottom: 0 !important;
    margin-top: 20px;
}

// Media queries

@media screen and (min-width:991px) {

  .campaign-get-in-touch-wrapper {
    .contact-us {
      margin-left: 0;

      h4 {
        margin-left: -45px;
      }
    }

    .call-back-form,
    .apply-now {
      h4 {
        margin-left: -25px;
      }
    }

    .apply-now {
      h4 {
       margin-left: -35px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .wrapper-campaign-banner {
    .text-image {
      h1 {
        width: 100% !important;
      }
    }
  }

  .campaign-nav {
    .wrapper-stage1:hover,
    .wrapper-stage2:hover {
      width: 175px;
    }

    .wrapper-stage3:hover {
      width: 245px;
    }
  }

  .campaign-nav-mob {
    display: none;
  }

  .provo-3col-orange,
  .provo-3col-aqua,
  .provo-3col-rubine,
  .provo-2col-orange,
  .provo-2col-aqua,
  .provo-2col-rubine {
    .button-sm,
    .button-sm-aqua,
    .button-sm-rubine {
      position:absolute;
      bottom: 0;
    }
  }

  .wrapper-infographic-mob {
    display: none;
  }
}

@media screen and (min-width:768px) and (max-width:991px) {

  .wrapper-stage1:hover,
  .wrapper-stage2:hover {
    width: 175px;
  }

  .wrapper-stage3:hover {
    width: 240px;
  }

  .campaign-body-wrapper {
    .main-promo-wrapper {
      .media-left {
        width: 360px;
        height:360px;
      }

      .text-image {
        height: 360px;
        padding-left: 20px;
        padding-right: 20px;
        background-position: 20px 0;
        width: auto;

        h2 {
          padding-top: 60px;
          margin-bottom: 10px;
          line-height: 32px;
        }
      }
    }
  }

  .provo-2col-orange,
  .provo-2col-aqua,
  .provo-2col-rubine {
    height: 265px;
  }

  .provo-3col-orange,
  .provo-3col-aqua,
  .provo-3col-rubine {
    height: 315px;
  }

  .campaign-get-in-touch-wrapper {
      padding-top: 15px;
      padding-bottom: 15px;

      .contact-us,
      .call-back-form,
      .apply-now {
        margin-left: -15px;
        margin-right: -15px;
      }

      .media-left {
        display: block;
        text-align: center;
        height: 43px;
      }

      h4 {
        text-align: center;
        padding-top: 5px;
        margin-bottom: 0;
      }
  }
}

@media screen and (max-width: 767px) {
  .wrapper-campaign-banner {
    background-position: 45% 50% !important;
    height: 185px;

    .text-image {
      height: 185px;
      vertical-align: middle;
      display: table-cell;

      h1 {
        font-size: 32px;
      }

      h2 {
        font-size: 28px;
        margin-top: 20px !important;
      }
    }
  }

  .campaign-nav {
    display: none;
  }

  .campaign-nav-mob {
    background-color: #c95109;
    text-align: center;
    padding-top: 10px;
    height: 50px;

    .col-xs-12 {
      padding-left: 0;
      padding-right: 0;
    }

    .dropdown {
      a {
        color: #ffffff;
        font-size: 20px;
        text-decoration: none;
        background-image: url('/content/dam/mlc/images/unchanging/icons/select-life-stage-arrow.png');
        background-repeat: no-repeat;
        background-size: 15px 9px;
        background-position: 100% 10px;
        padding-right: 20px;
      }

      span.caret {
        display: none;
      }

      .dropdown-menu {
        border-radius:0;
        border:0;
        background-color: #f5f5f5;
        margin-top: 15px;
        padding-top: 10px;
        text-align:center;
        padding-bottom: 0;
        z-index: 999;
        position: absolute !important;
        width: 100% !important;

        li {
         border-bottom: 1px solid #c95109;
         padding-bottom: 10px;
         text-align: left;

          &:last-child {
            margin-bottom: 0;
          }

          a {
            color:#c95109 !important;
            font-size: 20px;
            padding-right: 5px;
            display: inline;

            &:hover {
              color: #bc4401 !important;
            }
          }

          a#nav_stage1 {
            background-image: url('/content/dam/mlc/images/unchanging/icons/building-wealth-egg.png');
            .campaign-nav-mob-stages;
          }

          a#nav_stage2 {
            background-image: url('/content/dam/mlc/images/unchanging/icons/managing-your-future-egg.png');
            .campaign-nav-mob-stages;
          }

          a#nav_stage3 {
            background-image: url('/content/dam/mlc/images/unchanging/icons/planning-for-retirement-egg.png');
            .campaign-nav-mob-stages;
          }
          
          a#nav_stage4 {
            background-image: url('/content/dam/mlc/images/unchanging/icons/stage4-egg.png');
            .campaign-nav-mob-stages;
          }
        }
      }
    }

    .dropdown.open a {
      background-image: url('/content/dam/mlc/images/unchanging/icons/select-life-stage-arrow-open.png');
      background-repeat: no-repeat;
      background-size: 15px 9px;
      background-position: 100% 10px;
    }

    .stage1 a#nav_stage1,
    .stage2 a#nav_stage2,
    .stage3 a#nav_stage3,
    .stage4 a#nav_stage4 {
      border-bottom: 1px solid #bc4401;
    }
  }

  .campaign-body-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;

    .campaign-intro-content h4 {
      width: 100%;
    }

    .main-promo-wrapper {
      .media-left {
        display: block;
        width: 100%;
        height: 100%;
      }

      .text-image {
        height: auto;
        padding-left: 20px;
        padding-right: 20px;
        background-position: 20px 0;
        max-width: 505px;

        h2 {
          padding-top: 60px;
        }

        a,
        a:visited,
        a:focus {
          text-decoration: none;
        }
      }

      .button-sm,
      .button-sm-aqua,
      .button-sm-rubine {
        width: 100%;
        padding-top: 12px;
        font-size: 18px;
        height: 45px !important;
      }
    }
  }

  .get-in-touch-aqua,
  .get-in-touch-orange,
  .get-in-touch-rubine {
    padding-top: 20px;
    padding-bottom: 4px;

    .media-left {
      img {
       display: none;
      }
    }

    h3 {
     display: none;
    }

    .call-us {
      background-image:url('/content/dam/mlc/images/unchanging/icons/phone.png');
      .get-in-touch-provo-mob;
      background-size: 32px;
    }

    .request-callback {
      background-image:url('/content/dam/mlc/images/unchanging/icons/request-a-callback.png');
      .get-in-touch-provo-mob;
      background-size: 40px;
    }

    .find-adviser {
      background-image:url('/content/dam/mlc/images/unchanging/icons/locate.png');
      .get-in-touch-provo-mob;
      background-size: 40px;
    }
 }

  .provo-body-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .provo-3col-orange,
  .provo-3col-aqua,
  .provo-3col-rubine,
  .provo-2col-orange,
  .provo-2col-aqua,
  .provo-2col-rubine {
    max-width: 490px;
    height: auto;
    padding-top: 20px;
    margin-bottom: 40px;

    .button-sm,
    .button-sm-aqua,
    .button-sm-rubine {
      width: 100%;
      padding-top: 12px;
      font-size: 18px;
      height: 45px !important;
    }
  }

  .wrapper-infographic {
    display: none;
  }

  .campaign-get-in-touch-wrapper {
    position:fixed;
    bottom:0;
    z-index:9999;
    color:@text-color-white;
    background-color:#3b3b3b;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 9999em;
    padding-right: 9999em;
    margin-left: -9999em;
    margin-right:-9999em;

     .media-left {
       height:40px;
       padding-top: 10px;
       display: block;
       text-align: center;
     }

      .contact-us,
      .call-back-form {
        border-right:1px solid #fff;
      }

      .contact-us,
      .call-back-form,
      .apply-now {
        margin-left: -15px;
        margin-right: -15px;
      }

      .contact-us {
        img {
         content:url("/content/dam/mlc/images/unchanging/icons/phone-mob.png");
        }
      }

      .call-back-form {
        padding-left:15px;

        img {
         content:url("/content/dam/mlc/images/unchanging/icons/request-a-callback-mob.png");
        }
      }

      .apply-now {
        img {
         content:url("/content/dam/mlc/images/unchanging/icons/apply-now-mob.png");
        }
      }

      h4 {
       margin-bottom: 0;
       padding-top: 10px;
       line-height:20px;
       padding-bottom: 5px;
       color:@text-color-white;
       font-size:16px;
       text-align: center;

        a {
         color: @text-color-white;
          font-size: 16px;
        }
      }
  }


    a[href^=tel]:link,
    a[href^=tel]:visited {
      cursor:pointer;
      pointer-events:auto;
    }
}

@media screen and (min-width:600px) and (max-width:1199px) {
  .wrapper-campaign-banner {
    height: 270px;

    .text-image {
      height:270px;
    }
  }
}

@media screen and (min-width: 600px) and (max-width:767px) {
  .wrapper-campaign-banner {
    background-position: 55% 50% !important;

    h1 {
      width: 100% !important;
      margin-top: 0 !important;
    }
  }
}

@media screen and (max-width:599px) {
  .wrapper-campaign-banner {
    .provo-header-container {
      background: none !important;
    }

    .text-image {
      max-width: 240px;

      h1 {
        font-size: 20px;
      }

      h2 {
        font-size: 18px;
        margin-top: 20px !important;
      }
    }
  }

  .campaign-nav-mob {
    .dropdown {
      a {
        font-size: 18px;
        background-position: 100% 8px;
      }
    }

    .dropdown.open a {
      background-position: 100% 8px;
    }
  }

  .provo-body-wrapper {
    padding-top: 0;
    padding-bottom: 20px;
  }

  .campaign-get-in-touch-wrapper {

    .contact-us,
    .call-back-form {
      text-align:center;
    }

    .call-back-form {
      padding-left:15px;
    }

    h4 {
     font-size:14px;

      a {
        font-size: 14px;
      }
    }
  }
}

.no-border {
  border: none;
}

.campaign-nav .hatch-tab {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
}
.campaign-nav .hatch-tab:hover {
    width: 100%
}
@media screen and (max-width: 992px) {
  .campaign-nav h4 {
    font-size: 18px;
  }
}
