@import "../mixins.less";

//Change password + Set password

  .mlc-page-header {
     color: #ffffff;
     margin-bottom: 40px;
     padding-top: 22px;
  }

  .mlc-ltd-set-password {
    margin-bottom:40px;
    margin-top:20px;
    .password_control {
      margin-bottom:20px;
    }

    .button_primary {
     width:35%;
     margin-right:10px;
     margin-top:20px;
    }

    a#cancelResetPassword {
     text-decoration:underline;
    }

    a#cancelResetPassword:hover,
    a#cancelResetPassword:focus,
    a#cancelResetPassword:visited {
     text-decoration:none;
    }

    .help-content {
      background-color:#f0eeea;
      padding: 30px 30px 10px 30px;
      margin-bottom:40px;
      margin-top: 20px;
      font-size:15px;

      ul {
       margin-bottom:16px;
        padding-left:0;
      }

      ul li {
       margin-bottom:5px;
       padding-left:0;
        list-style-type:none;
      }

      ul li:before {
        content:"-";
        padding-right:5px;
      }

      p {
       line-height:20px;
      }
    }
  }

  .mlc-ltd-login .container {
    min-height:0px !important;
  }

  .change-pwd-success {
     background-color: @footer-base-bg;
     min-height: 100vh;
  }

  .wrapper-change-pwd-success {
    margin-top:-40px;
    padding-top:40px;
    min-height:500px;
    .btn-link {
      font-weight: bold;
      text-decoration: underline;
      padding-left:0;
    }
  }

  // Wealth change password page

  .wealth-change-password {
    font-size:14px;
    font-family:Arial,Helvetica,sans-serif;
    .wrapper-dfdfdf {
      background-color:#dfdfdf;
    }
    .wealth-page-header {
      margin-bottom:40px;
      padding-top:30px;
      padding-bottom:8px;
      h2 {
       color:#333333;
       font-size:20px;
       font-family:Arial,Helvetica,sans-serif;
      }
    }
    h3 {
     font-family:Arial,Helvetica,sans-serif;
     font-size: 14px;
     font-weight: bold;
    }
    .button_primary {
      font-size:14px;
      font-family:Arial,Helvetica,sans-serif;
      font-weight:bold;
    }

    .btn-primary {
      border-radius: 0px;
      font-family:Arial,Helvetica,sans-serif;
      font-weight: bold;
      min-width: 120px;
      font-size:14px;
      background-color:@primary-color-orange;
      border:0;
    }
    .btn-primary:hover,
    .btn-primary:focus,
    .btn-primary:visited {
      background-color:@primary-color-orange-hover !important;
    }

    a#cancelResetPassword {
      color:#333333;
    }

    .help-content {
      background-color:#efefef;
    }
    .col-footer {
      margin-top:-1px;
    }
    .wealth-page-footer {
      ul {
        float:right;
        li {
         display:inline;
        }
        a, a:visited, a:focus {
         color:#808080;
         cursor:pointer;
         :hover {
           text-decoration:underline;
         }
        }
      }
    }
  }

  // Badge sites change password page

  .dpm,
  .enevita,
  .blueprint,
  .fc-one,
  .portfolio-focus,
  .premium-choice,
  .st-andrews,
  .top-200 {
    .mlc-ltd-set-password {
      margin-top:20px;
      margin-bottom:40px;
      min-height:40vh;
      .help-content {
        margin-top:40px;
        margin-bottom:40px;
        font-size:12px;
      }
    }

    .reset-password-success {
      color:#666666;
      h4 {
       font-size:17px;
      }
    }

    .wrapper-change-pwd-success {
      padding-top:40px;
      min-height:500px;
      margin-top:0;
      .button_primary {
        width:20%;
        margin-top:10px;
        span {
         display: block;
         padding-top: 4px;
        }
      }
    }
  }

  .enevita .help-content,
  .premium-choice .help-content {
      font-size:13px;
  }