.wrapper.embedform .ui-widget-content {border:0}

.wrapper.embedform label, .wrapper.embedform .ui-widget input, .wrapper.embedform .ui-widget select, .wrapper.embedform .ui-widget textarea, .wrapper.embedform .ui-widget button {font-family:"HelveticaNeueLTStd-Roman", Helvetica, Arial, sans-serif; font-size:15px; font-weight:normal; margin:0px 0 0px 0}

.wrapper.embedform p {font-family:"HelveticaNeueLTStd-Roman", Helvetica, Arial, sans-serif; font-size:13px; line-height:18px; font-weight:normal}

.wrapper.embedform .submitbutton.action_text {text-align:center;}

.wrapper.embedform .submitbutton.action_text a { text-decoration:none; cursor:pointer; padding: 8px 20px; background-color:#c95109; color:#fff !important; font-family: "Charlie-Std-Medium"; font-size: 20px;}

.wrapper.embedform .submitbutton.action_text a:hover {background-color:#bc4401;}


.wrapper.embedform .ui-state-default, .wrapper.embedform .ui-widget-content .ui-state-default{border-color:#ccc; background-color:#fff; background-image:none; padding:10px; margin:5px 0 30px 0; width:80%; }

/*.ui-tooltip {box-shadow: none!important; border:1px #ccc solid !important; border-radius:0!important; display:inline-block; padding:2px 4px !important; max-width:inherit !important;}
.ui-tooltip-content {font-size:10px;}  */

.wrapper.embedform .fieldsummary center {text-align:left; font-size:13px;}

.wrapper.embedform .tooltip-container {width:90% !important}

@media screen and (max-width: 599px) {
.wrapper.embedform .ui-state-default, .wrapper.embedform .ui-widget-content .ui-state-default{width:60%}
}