@import "../mixins.less";

.forgotten_password_submit_button{
  margin-top: 11%;
}

.forgotten_password_cancel_button{
  text-align: center;
}

.mlc-ltd-forgotten-password {
  .container_content {
    width:480px;
  }
  .username_label,
  .password_label {
    font-weight: normal;
  }
}

.forgotten-password-help {
  background-color:rgba(255, 255, 255, 0.8)!important;
  padding: 30px 30px 10px 30px;
  margin-bottom: 40px;
  margin-top: 20px !important;
  font-size: 15px;
  width:90%;
}

.forgotten-password-help p {
  width:82%;
}

// Badge sites - forgotten password

.dpm,
.enevita,
.blueprint,
.fc-one,
.portfolio-focus,
.premium-choice,
.st-andrews,
.top-200 {
.mlc-ltd-forgotten-password {
  margin-top:40px;
  margin-bottom:40px;
  min-height:40vh;
  .forgotten-password-label {
    font-weight:normal;
  }
  .button_primary {
      margin-bottom:20px;
  }
}
}

  .dpm {
    .forgotten_password_cancel_button {
      a,
      a:visited,
      a:focus {
        color:@dpm-link-color;
      }
      a:hover {
        color:@dpm-link-hover;
      }
    }
  }

  .enevita {
    .forgotten_password_cancel_button {
      a,
      a:visited,
      a:focus {
        color:@enevita-link-color;
      }
      a:hover {
        color:@enevita-link-hover;
      }
    }
  }

  .blueprint {
    .forgotten_password_cancel_button {
      a,
      a:visited,
      a:focus {
        color:@blueprint-link-color;
      }
      a:hover {
        color:@blueprint-link-hover;
      }
    }
  }

  .fc-one {
    .forgotten_password_cancel_button {
      a,
      a:visited,
      a:focus {
        color:@fc-one-link-color;
      }
      a:hover {
        color:@fc-one-link-hover;
      }
    }
  }

  .portfolio-focus {
    .forgotten_password_cancel_button {
      a,
      a:visited,
      a:focus {
        color:@portfolio-focus-link-color;
      }
      a:hover {
        color:@portfolio-focus-link-hover;
      }
    }
  }
  .premium-chioce {
    .forgotten_password_cancel_button {
      a,
      a:visited,
      a:focus {
        color:@premium-choice-link-color;
      }
      a:hover {
        color:@premium-choice-link-hover;
      }
    }
  }

  .st-andrews {
    .forgotten_password_cancel_button {
      a,
      a:visited,
      a:focus {
        color:@st-andrews-link-color;
      }
      a:hover {
        color:@st-andrews-link-hover;
      }
    }
  }

  .top-200 {
    .forgotten_password_cancel_button {
      a,
      a:visited,
      a:focus {
        color:@top200-link-color;
      }
      a:hover {
        color:@top200-link-hover;
      }
    }
  }