/* desktop */

.site-selector .nav-pills>li>a {
  border-radius: 0px;
}
.site-selector .nav-pills>li.active>a,
.site-selector .nav-pills>li.active>a:hover,
.site-selector .nav-pills>li.active>a:focus {
  color: @site-selector-link-color;
  background-color: @site-selector-link-bg-active-color;
}
#site-select-desktop .nav>li>a {
  padding: 15px 16px;
}

/* mobile */
#site-select-mobile {
  margin-left: 15px;
}
#site-select-mobile li a {
  padding: 4px 7px;
}

.panel-accordion-light {

  &.reverse-aqua,
  &.aqua-grey-bg {
    overflow: hidden;

    .panel-group {
      margin: 0;
    }

    > .container > .row > .col-custom {

      .panel-default {
        background-color: initial;
        margin-bottom: 0 !important;
      }

      .panel-heading {
        background-color: initial;

        > * {
          padding: 10px 0;
          background-color: @primary-color-aqua;
          line-height: initial;

          * {
            background-color: initial !important;
            color: white;
            position: relative !important;
            z-index: 1;
          }
        }

        &:before, &:after {
          content: " ";
          background-color: @primary-color-aqua;
          position: absolute;
          display: block;
          top: 0;
          height: 100%;
          width: 100%;
        }

        &:before {
          right: 100%;
        }

        &:after {
          left: 100%;
        }
      }
    }
  }

  &.aqua-grey-bg {
    > .container > .row > .col-custom {
      .panel-heading {
        &:before, &:after {
          background-color: @grey-bg;
        }

        > * {
          background-color: @grey-bg;
        }

        h4 > a {
          color: @primary-color-aqua;
          font-size: 17px !important;

          &:before {
            font-size: 17px;
          }
        }
      }
    }
  }

  .panel-heading {
    a, a.collapsed, a.collapsed:hover {
      color: black;
      background-color: white !important; 
      font-size: 1.2em;
      font-family: Charlie-Std-SemiBold;
      background-image: none;
      position: relative;
      padding-left: 1.5em;
    }

    a:hover, a.collapsed:hover {
      text-decoration: underline;
    }

    a {
      &:before {
        content: "\e113";
        display: block;
        height: 20px;
        width: 20px;
        position: absolute;
        left: 0;
        font-family: 'Glyphicons Halflings';
        font-size: 1em;
      }

      &.collapsed:before {
        content: "\e114";
      }
    }
  }

  .panel .media-list .list-item-text {
    padding: 1em 0 0;
  }
}
