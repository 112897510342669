.carousel-inner {
  .item {
    transition: opacity 0.6s ease-in-out;
  }

  .item, .active.left, .active.right {
    opacity: 0;
  }

  .active, .next.left, .prev.right {
    opacity: 1;
  }
}