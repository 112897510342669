@import "../mixins.less";

// Full page Login

.login-page,
.forgotten-password {
  min-height:100vh;
  background-color:@footer-base-bg;
}

  //Top Nav

  .login-top-nav {
    padding-top:15px;
    background-color:#f0eeea !important;
  }

  .login-top-nav a {
    padding-left:25px;
    color:@navbar-default-link-color;
  }

  .login-top-nav a:hover, .login-top-nav a:focus, .login-top-nav a:visited {
    text-decoration:none;
    color:@default-link-color;
    cursor:pointer;
  }

  .wrapper-subheader {
    padding-top:15px;
    padding-bottom:15px;
  }

  //Error state + outage + maintenance message  + reset password success
  .maintenance-message {
     padding-top: 20px;
   }

  .outage-message {
    background-color:@maintenance-msg-bg;
    padding:20px 20px 10px 40px;
    background-image:url('/content/dam/mlc/images/icons/icn-info.png');
    background-repeat:no-repeat;
    background-position:10px 20px;
    margin-bottom:20px;
  }

  .error_section {
    margin-bottom: 10px;
  }

  .error_message {
    color:#990000;
    background-image:url("/content/dam/mlc/images/icons/icn-info-red.png");
    background-repeat:no-repeat;
    line-height: 1.5;
    padding: 0 15px 10px 30px;
    background-position:-5px 0;
  }

  .ng-submitted .ng-invalid {
    border-color:#990000;
  }

  .reset-password-success {
     color: @primary-color-aqua;
     margin-bottom: 30px;
     width: 85%;
  }

  //Login component

  .mlc-heading {
    color:@primary-color-orange;
    margin:40px 0 30px !important;
  }

  .mlc-ltd-login .mlc_heading {
    display:none;
  }

  .mlc-ltd-login-widget .mlc_heading {
    display:inherit;
  }

  .css-form  .form-control {
    height:40px;
    border-radius:0;
  }

  .login-wrapper {
    background-size:cover !important;
    background-position:71% 0 !important;
  }

  .button_primary {
    background: none;
    background-color: @primary-color-orange;
    margin-top: -10px;
    color: @text-color-white;
    border: 0px;
    font-family: @font-family-heading, helvetica, arial;
    font-size: 20px;
    width: 100%;
    border-radius: 0;
    font-size: 20px;
    margin-bottom: 20px;
    height: 45px;
  }

  .button_primary:hover {
    background-color:@primary-color-orange-hover;
  }

  .button_primary:focus {
    background-color:@primary-color-orange-hover;
    outline:1px dotted @primary-color-orange-hover !important;
  }

  .button_primary:disabled {
    opacity:0.6;
    cursor:not-allowed;
  }

  .mlc-ltd-login {
    .username_label,
    .password_label {
      font-weight: normal;
    }
  }

  //Help content

  .mlc-ltd-login .help-content {
    margin-bottom:10px;
    margin-top: 30px;


  }
  //End of Login component

//login widget css
.mlc-ltd-login-widget{
  right: 240px;
  position: absolute;
  width: 300px;
  top: 180px;
  z-index: 100;
  transition: right .35s ease;
  background-color: #004651;

  .container {
    width: 300px;
  }

  .container_content {
    width: 300px;
  }

  .username_control{
    margin-bottom: 0;
  }

  .username_control{
    margin-bottom: 0;
  }

  .mlc_heading{
    color: white;
    padding-left: 5%;
    padding-top: 5%;
    padding-bottom: 1%;
    font-size:22px;
    font-weight: bold;
    margin-bottom:0;
    line-height: 130%;
  }

  .username_label{
    color:@text-color-white;
    padding-left: 5%;
    font-size:15px;
    font-weight:normal;
  }

  .widget-login-button{
    margin: 10px 5% 0 5%;
    width: 90%;
  }

  .password_label{
    color: @text-color-white;
    padding-left: 5%;
    padding-top: 5%;
    font-size:15px;
    font-weight:normal;
  }

  .form-control{
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
  }
}

.close-login-widget {
  height: 52px;
  width: 54px;
  background-color: #004651;
  z-index: 10;
  top: 180px;
  right: 231px;
  transition: right .35s ease;
  position:absolute;
}

.close-login-widget.minimized{
  position: absolute;
  right: 0;
}

.close-login-widget.expanded{
  position: absolute;
  right: 540px;
  transition: right .35s ease-in-out;
}

.mlc-ltd-login-widget.minimized-widget{
  right: -300px;
  position: absolute;
}

.close-login-widget.glyphicon-chevron-right{
  font-size:16px;
  font-weight:100;
  text-align:center;
  z-index: 12;
  color: white;
  padding-top: 19px;
  width: 54px;
  height: 52px;
}

//Login widget Maintenance message  + Error state

  .mlc-ltd-login-widget .ng-submitted {

    .error_message {
      color:@text-color-white;
      background-color:#990000;
      padding-left:33px;
      padding-top:9px;
      padding-bottom:6px;
      background-position:5px 8px;
      background-image:url("/content/dam/mlc/images/icons/icon-exclamation-white.png");
      margin-left:10px;
      margin-right:10px;
      position:relative;
      top:6px;
      a {
       text-decoration:underline;
       color:@text-color-white;
      }
      p:first-child {
       margin-bottom:0;
      }
    }

    .error_message:hover,
    .error_messagea:visited,
    .error_messagea:focus {
      text-decoration:none;
    }

  }

  .mlc-ltd-login-widget {
    .outage-message {
      background-color:transparent;
      color:#ffffff;
      padding:0px 10px 0 10px;
      font-size:14px;
      margin-bottom:0;
      background-repeat:no-repeat;
      background-position:10px 0px;
      padding-left:45px;
      background-image:url('/content/dam/mlc/images/icons/icon-info-aqua.png');
      background-repeat:no-repeat;
      p {
       margin-bottom:10px;
       line-height:20px;
      }
      p:last-child {
        margin-bottom:-15px;
      }
    }
    .white-reverse {
      padding:10px 15px;
      color:@text-color-white;
      a {
        color:@text-color-white;
        text-decoration:underline;
        font-weight:normal;
      }
      a:hover,
      a:focus,
      a:visited {
        cursor:pointer;
      }
    }
    .error_section {
      margin-bottom:15px;
    }
  }

  //Login widget - login disabled

  .mlc-ltd-login .login-disabled {
    padding:10px 15px;
  }

  .mlc-ltd-login .login-disabled p {
    color:#fff;
    font-size:14px;
    margin-bottom:0;
  }

  // Login Widget - forgotten password + Login help links

  .mlc-ltd-login-widget .password_control {
    margin-bottom:5px;
  }

  .mlc-ltd-login-widget .widget-links {
    margin-left:15px;
    font-size:14px;
    margin-bottom:15px;
    padding-top:5px;
  }

  .mlc-ltd-login-widget .widget-links .float-left {
    float:left !important;
  }

  .mlc-ltd-login-widget .widget-links {
    a {
      color:@text-color-white;
      text-decoration:underline;
    }
    a:last-child {
        float:right;
        margin-right:15px;
    }
    a:focus {
      outline:1px dotted  #ffffff !important;
      text-decoration:none;
    }
  }

@media only screen and (max-width: 992px) {
  .close-login-widget{
    display: none;
  }
  .mlc-ltd-login-widget{
    display: none;
  }
}

// Pre-loader (Spinner)

  .common-spinner {
    margin-left:20%;
  }

  .mlc-ltd-login-widget .common-spinner {
    margin-left:30%;
  }

//Footer

  .login-footer {
    padding-top:23px;
    padding-bottom:20px;
  }


// Badge sites - Global

.dpm,
.enevita,
.blueprint,
.fc-one,
.portfolio-focus,
.premium-choice,
.st-andrews,
.top-200 {
  font-family:Verdana, Arial, Helvetica, sans-serif;
  font-size:12px;
  .badge-page-bg;
  h2 {
    font-size:20px;
    font-family:Verdana, Arial, Helvetica, sans-serif;
  }
  .button_primary {
    font-size:18px;
    font-family:Verdana, Arial, Helvetica, sans-serif;
  }
  h4 {
      font-family:Verdana, Arial, Helvetica, sans-serif;
  }
  .form-control {
    font-size:12px;
    font-family:Verdana, Arial, Helvetica, sans-serif;
  }
}

.enevita,
.premium-choice {
  font-family:Arial, Helvetica, sans-serif;
  font-size:13px;
  h2 {
    font-family:Arial, Helvetica, sans-serif;
  }
  .button_primary {
    font-family:Arial, Helvetica, sans-serif;
  }
  h4 {
    font-family:Arial, Helvetica, sans-serif;
  }
  .form-control {
    font-family:Arial, Helvetica, sans-serif;
    font-size:13px;
  }
}

.enevita,
.blueprint,
.fc-one,
.portfolio-focus,
.premium-choice,
.st-andrews,
.top-200 {
  .wrapper-subheader {
    padding-top:10px;
    padding-bottom:10px;
  }
  .username_control {
    margin-bottom:4%;
  }
  .password_control {
    margin-bottom:9%;
  }
}

.dpm,
.enevita,
.blueprint,
.fc-one,
.portfolio-focus,
.premium-choice,
.st-andrews,
.top-200 {
  .badge-page-header {
     .badge-header;
  }
}

.dpm,
.dpm,
.enevita,
.blueprint,
.fc-one,
.portfolio-focus,
.premium-choice,
.st-andrews,
.top-200 {
  .login-container {
    padding-top:30px;
  }
}


.dpm,
.enevita,
.blueprint,
.fc-one,
.portfolio-focus,
.premium-choice,
.st-andrews,
.top-200 {
  .help-content {
    margin-top:10px;
    margin-bottom:60px;
  }
}

.dpm,
.enevita,
.blueprint,
.fc-one,
.portfolio-focus,
.premium-choice,
.st-andrews,
.top-200 {
  .badge-page-footer {
    .badge-footer;
  }
}

.dpm,
.enevita,
.blueprint,
.fc-one,
.portfolio-focus,
.premium-choice,
.st-andrews,
.top-200 {
  .badge-page-footer ul {
    margin-top:0px;
    padding-left:0;
  }
}

.dpm,
.enevita,
.blueprint,
.fc-one,
.portfolio-focus,
.premium-choice,
.st-andrews,
.top-200 {
  .badge-page-footer ul li {
    display:inline;
    margin-right:20px;
    padding-left:0;
  }
}

.dpm,
.enevita,
.blueprint,
.fc-one,
.portfolio-focus,
.premium-choice,
.st-andrews,
.top-200 {
  a {
    cursor:pointer;
  }
}



//Individual Badge site styles

  //DPM

  .dpm {
    color:#000000;
    .wrapper-subheader {
      padding-top:0;
      padding-bottom:0;
    }
  }

  .dpm {
    .button_primary {
      background-color:@dpm-btn-primary;
      margin-bottom:0;
    }
    .button_primary:hover {
        border-color:@dpm-btn-primary-hover !important;
        box-shadow:none !important;
        background-color:@dpm-btn-primary-hover;
    }
    .button_primary:focus {
        outline:1px dotted @dpm-btn-primary-hover !important;
        box-shadow:none !important;
        background-color:@dpm-btn-primary-hover;
    }
  }

  .dpm .help-content a,
  .dpm .help-content a:focus,
  .dpm .help-content a:visited,
  .dpm .badge-page-footer a,
  .dpm .badge-page-footer a:focus,
  .dpm .badge-page-footer a:visited {
    color:@dpm-link-color !important;
  }

  .dpm .help-content a:hover,
  .dpm .badge-page-footer a:hover {
    color:@dpm-link-hover !important;
  }

  .dpm .form-control:focus, .dpm .form-control:hover {
    border-color:@dpm-brand-color !important;
    box-shadow:none !important;
	box-shadow: 0 1px 1px rgba(0,0,0,.075) inset,0 0 8px rgba(6,120,97,.6)!important;
  }

  .dpm .button_primary:focus {
   outline:@dpm-brand-color !important;
   border-color:@dpm-brand-color !important;
   box-shadow:none !important;
  }

  //Enevita
  .enevita {
    color:#666666;
  }

  .enevita {
    .button_primary {
      background-color:@enevita-btn-primary;
      margin-bottom:0;
    }
    .button_primary:hover {
        border-color:@enevita-btn-primary-hover !important;
        box-shadow:none !important;
        background-color:@enevita-btn-primary-hover;
    }
    .button_primary:focus {
        outline:1px dotted @enevita-btn-primary-hover !important;
        box-shadow:none !important;
        background-color:@enevita-btn-primary-hover;
    }
  }

  .enevita .help-content a,
  .enevita .help-content a:focus,
  .enevita .help-content a:visited,
  .enevita .badge-page-footer a,
  .enevita .badge-page-footer a:focus,
  .enevita .badge-page-footer a:visited {
    color:@enevita-link-color !important;
  }

  .enevita .help-content a:hover,
  .enevita .badge-page-footer a:hover {
    color:@enevita-link-hover !important;
  }

  .enevita .form-control:focus,
  .enevita .form-control:hover {
    border-color:@enevita-brand-color !important;
    box-shadow:none !important;
	box-shadow: 0 1px 1px rgba(0,0,0,.075) inset,0 0 8px rgba(4,156,202,.6)!important;
  }

  .enevita .button_primary:focus {
   outline:@enevita-brand-color !important;
   border-color:@enevita-brand-color !important;
   box-shadow:none !important;
  }

  // blueprint

  .blueprint {
    color:#000000;
  }

  .blueprint {
    .button_primary {
      background-color:@blueprint-btn-primary;
      margin-bottom:0;
    }
    .button_primary:hover {
        border-color:@blueprint-btn-primary-hover !important;
        box-shadow:none !important;
        background-color:@blueprint-btn-primary-hover;
    }
    .button_primary:focus {
        outline:1px dotted @blueprint-btn-primary-hover !important;
        box-shadow:none !important;
        background-color:@blueprint-btn-primary-hover;
    }
  }

  .blueprint .help-content a,
  .blueprint .help-content a:focus,
  .blueprint .help-content a:visited,
  .blueprint .badge-page-footer a,
  .blueprint .badge-page-footer a:focus,
  .blueprint .badge-page-footer a:visited {
    color:@blueprint-link-color !important;
  }

  .blueprint .help-content a:hover,
  .blueprint .badge-page-footer a:hover {
    color:@blueprint-link-hover !important;
  }

  .blueprint .form-control:focus,
  .blueprint .form-control:hover {
    border-color:@blueprint-brand-color !important;
    box-shadow:none !important;
	box-shadow: 0 1px 1px rgba(0,0,0,.075) inset,0 0 8px rgba(0,91,156,.6)!important;
  }

  .blueprint .button_primary:focus {
   outline:@blueprint-brand-color !important;
   border-color:@blueprint-brand-color !important;
   box-shadow:none !important;
  }

  //FC One

  .fc-one {
    color:#58595b;
  }

  .fc-one {
    .button_primary {
      background-color:@fc-one-btn-primary;
      margin-bottom:0;
    }
    .button_primary:hover {
      border-color:@fc-one-btn-primary-hover !important;
      box-shadow:none !important;
      background-color:@fc-one-btn-primary-hover;
    }
    .button_primary:focus {
      outline:1px dotted @fc-one-btn-primary-hover !important;
      box-shadow:none !important;
      background-color:@fc-one-btn-primary-hover;
    }
  }

  .fc-one .help-content a,
  .fc-one .help-content a:focus,
  .fc-one .help-content a:visited,
  .fc-one .badge-page-footer a,
  .fc-one .badge-page-footer a:focus,
  .fc-one .badge-page-footer a:visited {
    color:@fc-one-link-color !important;
  }

  .fc-one .help-content a:hover,
  .fc-one .badge-page-footer a:hover {
    color:@fc-one-link-hover !important;
  }

  .fc-one .form-control:focus,
  .fc-one .form-control:hover {
    border-color:@fc-one-brand-color !important;
    box-shadow:none !important;
	box-shadow: 0 1px 1px rgba(0,0,0,.075) inset,0 0 8px rgba(0,85,159,.6)!important;
  }

  .fc-one .button_primary:focus {
   outline:@fc-one-brand-color !important;
   border-color:@fc-one-brand-color !important;
   box-shadow:none !important;
  }

  //Portfolio Focus

  .portfolio-focus {
    color:#666666;
  }

  .portfolio-focus {
    .button_primary {
      background-color:@portfolio-focus-brand-color;
      margin-bottom:0;
    }
    .button_primary:hover {
      border-color:#b1b41a !important;
      box-shadow:none !important;
      background-color:#b1b41a;
    }
    .button_primary:focus {
      outline:1px dotted #b1b41a !important;
      box-shadow:none !important;
      background-color:#b1b41a;
    }
  }

  .portfolio-focus .help-content a,
  .portfolio-focus .help-content a:focus,
  .portfolio-focus .help-content a:visited,
  .portfolio-focus .badge-page-footer a,
  .portfolio-focus .badge-page-footer a:focus,
  .portfolio-focus .badge-page-footer a:visited {
    color:@portfolio-focus-link-color !important;
  }

  .portfolio-focus .help-content a:hover,
  .portfolio-focus .badge-page-footer a:hover {
    color:@portfolio-focus-link-hover !important;
  }

  .portfolio-focus .form-control:focus,
  .portfolio-focus .form-control:hover {
    border-color:@portfolio-focus-brand-color !important;
    box-shadow:none !important;
	box-shadow: 0 1px 1px rgba(0,0,0,.075) inset,0 0 8px rgba(195,199,47,.6)!important;
  }

  .portfolio-focus .button_primary:focus {
   outline:@portfolio-focus-brand-color !important;
   border-color:@portfolio-focus-brand-color !important;
   box-shadow:none !important;
  }

  // PremiumChoice

  .premium-choice {
    color:#666666;
    .button_primary {
      background-color:@premium-choice-btn-primary;
      margin-bottom:0;
    }
    .button_primary:hover {
      border-color:@premium-choice-btn-primary-hover !important;
      box-shadow:none !important;
      background-color:@premium-choice-btn-primary-hover;
    }
    .button_primary:focus {
      outline:1px dotted @premium-choice-btn-primary-hover !important;
      box-shadow:none !important;
      background-color:@premium-choice-btn-primary-hover;
    }
  }

  .premium-choice .help-content a,
  .premium-choice .help-content a:focus,
  .premium-choice .help-content a:visited,
  .premium-choice .badge-page-footer a,
  .premium-choice .badge-page-footer a:focus,
  .premium-choice .badge-page-footer a:visited {
    color:@premium-choice-link-color !important;
  }

  .premium-choice .help-content a:hover,
  .premium-choice .badge-page-footer a:hover {
    color:@premium-choice-link-hover !important;
  }

  .premium-choice .form-control:focus,
  .premium-choice .form-control:hover {
    border-color:@premium-choice-brand-color !important;
    box-shadow:none !important;
	box-shadow: 0 1px 1px rgba(0,0,0,.075) inset,0 0 8px rgba(162,150,137,.6)!important;
  }

  .premium-choice .button_primary:focus {
   outline:@premium-choice-brand-color !important;
   border-color:@premium-choice-brand-color !important;
   box-shadow:none !important;
  }

  // St Andrew's

  .st-andrews {
    color:#666666;
    .button_primary {
      background-color:@st-andrews-btn-primary;
      margin-bottom:0;
    }
    .button_primary:hover {
      border-color:@st-andrews-btn-primary-hover !important;
      box-shadow:none !important;
      background-color:@st-andrews-btn-primary-hover;
    }
    .button_primary:focus {
      outline:1px dotted @st-andrews-btn-primary-hover !important;
      box-shadow:none !important;
      background-color:@st-andrews-btn-primary-hover;
    }
  }

  .st-andrews .help-content a,
  .st-andrews .help-content a:focus,
  .st-andrews .help-content a:visited,
  .st-andrews .badge-page-footer a,
  .st-andrews .badge-page-footer a:focus,
  .st-andrews .badge-page-footer a:visited {
    color:@st-andrews-link-color !important;
  }

  .st-andrews .help-content a:hover,
  .st-andrews .badge-page-footer a:hover {
    color:@st-andrews-link-hover !important;
  }

  .st-andrews .form-control:focus,
  .st-andrews .form-control:hover {
    border-color:@st-andrews-brand-color !important;
    box-shadow:none !important;
	box-shadow: 0 1px 1px rgba(0,0,0,.075) inset,0 0 8px rgba(40,55,124,.6)!important;
  }

  .st-andrews .button_primary:focus {
   outline:@st-andrews-brand-color !important;
   border-color:@st-andrews-brand-color !important;
   box-shadow:none !important;
  }

  //Top 200

  .top-200 {
    color:#666666;
    .button_primary {
        background-color:@top200-btn-primary;
        margin-bottom:0;
      &:hover {
        border-color:@top200-btn-primary-hover !important;
        box-shadow:none !important;
        background-color:@top200-btn-primary-hover;
      }
      &:focus {
        outline:1px dotted @top200-btn-primary-hover !important;
        box-shadow:none !important;
        background-color:@top200-btn-primary-hover;
      }
    }
  }

  .top-200 .help-content a,
  .top-200 .help-content a:focus,
  .top-200 .help-content a:visited,
  .top-200 .badge-page-footer a,
  .top-200 .badge-page-footer a:focus,
  .top-200 .badge-page-footer a:visited {
    color:@top200-link-color !important;
  }

  .top-200 .help-content a:hover,
  .top-200 .badge-page-footer a:hover {
    color:@top200-link-hover  !important;
  }

  .top-200 .form-control:focus, .top-200 .form-control:hover {
    border-color:@top200-brand-color !important;
    box-shadow:none !important;
	box-shadow: 0 1px 1px rgba(0,0,0,.075) inset,0 0 8px rgba(245,126,33,.6)!important;
  }

  .top-200 .button_primary:focus {
   outline:@top200-brand-color !important;
   border-color:@top200-brand-color !important;
   box-shadow:none !important;
  }

  .top-200-heading h2 {
    margin-left: 15px;
    position: relative;
    top: 20px;
  }

  .top-200-heading h2 a {
    color: #fd7f1c;
  }

  .top-200-heading h2 a:hover,
  .top-200-heading h2 a:focus,
  .top-200-heading h2 a:visited {
    text-decoration:none;
  }