/* CSS for Retirement Hub 

  Added in theme.less
*/
/* More headings style */



.lg-Heading h1, .lg-Heading h2, .lg-Heading h3, .lg-Heading h4, .md-Heading h1, .md-Heading h2, .md-Heading h3, .md-Heading h4, .sm-Heading h1, .sm-Heading h2, .sm-Heading h3, .sm-Heading h4, .xs-Heading h1, .xs-Heading h2, .xs-Heading h3, .xs-Heading h4 {line-height: 1.3 !important}


.lg-Heading h1,
.lg-Heading h2,
.lg-Heading h3,
.lg-Heading h4  {
  font-size: 30px !important; 
}

.md-Heading h1,
.md-Heading h2,
.md-Heading h3,
.md-Heading h4 {
  font-size: 26px !important; 
}

.sm-Heading h1,
.sm-Heading h2,
.sm-Heading h3,
.sm-Heading h4  {
  font-size: 20px !important; 
}

.xs-Heading h1,
.xs-Heading h2,
.xs-Heading h3,
.xs-Heading h4  {
  font-size: 18px !important; 
}

.font-Helvetica h1, .font-Helvetica h2, .font-Helvetica h3, .font-Helvetica h4, .font-Helvetica h5, .font-Helvetica h6 {font-family: 'HelveticaNeueLTStd-Roman',Helvetica,Arial,sans-serif !important;}


.font-Charlie-Sb-p p {font-family: 'Charlie-Std-SemiBold', Helvetica,Arial !important; line-height: 1.2; color:#3b3b3b;}
.font-Charlie-Med-p p {font-family: 'Charlie-Std-Medium', Helvetica,Arial !important; line-height: 1.2; color:#3b3b3b;}

.font-Charlie-Med-h1 h1, .font-Charlie-Med-h2 h2, .font-Charlie-Med-h3 h3, .font-Charlie-Med-h4 h4 {font-family: 'Charlie-Std-Medium', Helvetica,Arial !important; line-height: 1.2; color:#3b3b3b;}


.font-15 h1, .font-15 h2, .font-15 h3, .font-15 h4, .font-15 h5, .font-15 h6 {font-size: 15px; margin-bottom: 10px;}

.font-16 h1, .font-16 h2, .font-16 h3, .font-16 h4, .font-16 h5, .font-16 h6 {font-size: 15px;}


/*image half*/
 
.img-half-w {width:50%; height:auto;}
.img-half-h {height:100%; width:auto;}


/*MASTHEAD*/
.masthead-wrapper .font-Charlie-Med-p p {font-size: 24px; width:50%;}


.wrapper-rethub .overview-teaser-wrapper .media h2, .wrapper-rethub .overview-teaser-wrapper .media h3 {margin-bottom:12px;margin-top:10px !important}

.wrapper-rethub .overview-teaser-wrapper .media h2 a, .wrapper-rethub .overview-teaser-wrapper .media h3 a { color: #3b3b3b !important;}


/*disclaimer*/

.font-Helvetica.small h2 {font-size: 14px;}


/*Breadcrumbs*/
.page-header-container .breadcrumb {font-size:15px;}

.carousel-full-width a:hover, .masthead-wrapper a:hover {text-decoration: underline !important;}

/*Accessibility no outline */


/*External Icons */

.extLink .glyphicon {margin-left: 5px; font-size:0.8em;}


a:focus {
  outline-width: 1px !important;
  outline-style: dotted !important;
  outline-color: #3b3b3b !important;
}


/*Button Rubine*/

a:hover .button-sm-rubine, a:focus .button-sm-rubine{ background-color:#60003a; text-decoration: underline !important;}



/*RetHub */

.wrapper-rethub a:hover span {text-decoration: underline !important;}

.wrapper-rethub .product-page-intro p {
  font-size: 17px !important;
}

.overview-teaser-wrapper .media h2 a:hover, .overview-teaser-wrapper .media h3 a:hover, .overview-teaser-wrapper .media h4 a:hover {
  color: #037691 !important;
  text-decoration: underline;
}

.hid-li1 li:first-child, .hid-li-active li.active {display: none;}

.wrapper-bg-faf8f4.nav-right .nav-tabs {
  margin-top: 10px;
}
.wrapper-bg-faf8f4.nav-right .nav-tabs > li {
  width: 100%;
  background-color: transparent;
  border-left: 0;
  border-bottom: 1px solid #DCDAD6;
}
.wrapper-bg-faf8f4.nav-right .nav-tabs > li.active > a {
  background-color: transparent;
  color: #037691;
  border-top: 0;
  text-decoration: none;
}
.wrapper-bg-faf8f4.nav-right .nav-tabs > li.active {
  border-top: 0;
  border-right: 0;
}
.wrapper-bg-faf8f4.nav-right .nav-tabs > li:last-child {
  border-bottom: 0;
}
.wrapper-bg-faf8f4.nav-right .nav-tabs {
  border-bottom: 0;
}
.wrapper-bg-faf8f4.nav-right .nav-tabs > li a {
  position: relative;
  width: 100%;
  text-align: left;
  padding-left: 0;
  padding-right: 0;
}
.wrapper-bg-faf8f4.nav-right .nav-tabs > li a:hover {
  color: #037691;
  text-decoration: none;
  background-color: none;
  border: none;
}
.wrapper-bg-faf8f4.nav-right .nav-tabs > li a:after {
  position: absolute;
  content: "\e605";
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  color: #AAA8A4;
  font-weight: 400;
  font-size: 12px;
  font-variant: normal;
  width: 40px;
  height: 40px;
  left: 100%;
  margin-left: -10px;
  top: 16px;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}
.wrapper-bg-faf8f4.nav-right .nav-tabs > li a:hover:after {
  color: #037691;
}
.wrapper-bg-faf8f4.nav-right .nav > li > a:focus,
.nav > li > a:hover {
  background-color: transparent;
}



.personal-menu .megamenu-primary-nav .dropdown #nav_retirement + .dropdown-menu #megamenu-col-1 {
    background-color: transparent !important;
    padding: 0;
    margin-top: 0;
}


.personal-menu .megamenu-primary-nav .dropdown #nav_retirement + .dropdown-menu #megamenu-col-2 h4 a {font-family:'Charlie-Std-SemiBold' !important;font-size:18px !important;margin-bottom: -10px !important;}

.personal-menu .megamenu-primary-nav .dropdown #nav_retirement + .dropdown-menu #megamenu-col-2 h4:hover span {text-decoration: underline !important}


.personal-menu .megamenu-primary-nav .dropdown #nav_retirement + .dropdown-menu #megamenu-col-2 span.button-md-rubine {margin-top:-10px;font-size:15px;min-width:120px;padding:7px 10px;height:inherit !important;}

.personal-menu .megamenu-primary-nav .dropdown #nav_retirement + .dropdown-menu #megamenu-col-2 span.button-md-rubine:hover {text-decoration:underline !important;}



.newhr {
  border-top: 1px solid #ccc;
}
.newTag {
  background-color: #ce0058;
  color: #fff;
  padding: 2px;
  font-size: 0.8em;
}
.intro-icn img,
.intro-icn2 img,
.intro-icn3 img {
  width: 100%;
}
.intro-icn img {
  max-width: 45px;
  min-width: 30px;
  margin-top: -12px;
  margin-right: 20px;
}
.intro-icn2 img {
  max-width: 30px;
  min-width: 30px;
  margin-top: -4px;
}
.intro-icn3 img {
  max-height: 35px;
  width: auto;
  margin-top: -8px;
}
.tiles-icn img {
  height: 62px;
  width: auto !important;
}
.noheight {
  height: 250px !important;
  border-bottom: none !important;
}
.hasBtLeft .button-sm {
  margin-right: 10px;
  margin-bottom: 5px;
}
.pageLinksDiv {
  padding-right: 30px;
}
.pageLinksWrapper {
  margin-bottom: 20px;
}

.pageLinksWrapper h2, .pageLinksWrapper h3, .pageLinksWrapper h4 {
  margin-bottom: 15px;
}

.pageNavRightWrapper {
  padding: 0 30px;
}
.pageLinks .media-body {
  display: block;
  padding: 10px 0;
}
.pageLinks ul {
  padding: 0;
}
.pageLinks li {
  list-style: none;
}
.pageLinks li {
  border-top: 1px solid #C8C6C2;
  padding: 0;
  margin: 0;
  width: 100%;
}
.pageLinks li:last-child {
  border-bottom: 1px solid #C8C6C2;
}
.pageLinks li a {
  position: relative;
  padding: 10px 20px 10px 10px;
  display: block;
  width: 100%;
  text-decoration: none;
  color: #3b3b3b;
}
.pageLinks li a:hover {
  color: #037691;
}
.pageLinks li a:hover {
  text-decoration: none;
}
.pageLinks li a:after {
  position: absolute;
  content: "\e605";
  color: #AAA8A4;
  top: 50%;
  margin-top: -6px;
  right: 10px;
  font-family: icomoon;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
}
.pageLinks li a:hover:after {
  color: #037691;
}
.wrapper-rethub .table > tbody > tr > th,
.wrapper-rethub .table > thead > tr > th {
  background-color: #585652;
  color: #fff;
}
.wrapper-rethub .table tr:nth-child(even) {
  background-color: #f0eeea;
}
.wrapper-rethub .table tr:nth-child(odd) {
  background-color: #faf8f4;
}
.wrapper-rethub .table tr th,
.wrapper-rethub .table tr td {
  padding: 15px;
  border-bottom: 2px solid #fff !important;
  border-right: 2px solid #fff !important;
  border-top:0 !important;
}

.wrapper-rethub .table-responsive>.table>tbody>tr>td, .wrapper-rethub .table-responsive>.table>tbody>tr>th, .wrapper-rethub .table-responsive>.table>tfoot>tr>td, .wrapper-rethub .table-responsive>.table>tfoot>tr>th, .wrapper-rethub .table-responsive>.table>thead>tr>td, .wrapper-rethub .table-responsive>.table>thead>tr>th {white-space: inherit;}


/*Tables Stacked One */
.table_stacked1 {
  width: 100%;
}
.table_stacked1.table {
  width: 100%;
  max-width: 100%;
  border: 0px !important;
}
.table_stacked1 .center {
  margin: 0 auto;
  width: 80%;
}
.table_stacked1.table {
  border-collapse: collapse;
}
.table_stacked1.table tr {
  border-bottom: 1px solid #f0f0f0;
}
.table_stacked1.table thead tr {
  border-bottom: 2px solid #f0f0f0;
}
.table_stacked1.table td,
.table_stacked1.table th {
  padding: 15px 20px;
  border: 2px solid #fff !important;
}
.table_stacked1.table th {
  background-color: #585652;
  color: #fff;
  text-align: left;
}
.table_stacked1.table tr:nth-child(even) {
  background-color: #f0eeea;
}
.table_stacked1.table tr:nth-child(odd) {
  background-color: #faf8f4;
}
.table_stacked1.table p {
  margin-bottom: 0;
}
/*Tables Stacked Two */
.table_stacked2 {
  width: 100%;
}
.table_stacked2.table {
  width: 100%;
  max-width: 100%;
  border: 0px !important;
}
.table_stacked2 .center {
  margin: 0 auto;
  width: 80%;
}
.table_stacked2.table {
  border-collapse: collapse;
}
.table_stacked2.table tr {
  border-bottom: 1px solid #f0f0f0;
}
.table_stacked2.table thead tr {
  border-bottom: 2px solid #f0f0f0;
}
.miscTable .table_stacked2.table tr:nth-child(odd):not(:nth-child(1)) th[scope="row"] {
  background-color: #faf8f4;
  color: #3b3b3b;
}
.miscTable .table_stacked2.table tr:nth-child(even) th[scope="row"] {
  background-color: #f0eeea;
  color: #3b3b3b;
}
.table_stacked2.table tr td {
  border-bottom: 1px solid #fff !important;
}
.table_stacked2.table td,
.table_stacked2.table th {
  padding: 15px 20px;
  border: 1px solid #fff !important;
}
.tableTot .table_stacked2.table td,
.tableTot .table_stacked2.table th {
  padding: 15px 20px;
  border-left: 1px solid #fff !important;
  border-right: 1px solid #fff !important;
}
.tableTot .table_stacked2.table td.tdTot {
  border-right: 0px solid #fff !important;
  border-top: 2px solid #585652 !important;
}
.tableTot .table_stacked2.table tr:last-child td.tdTot {
  border-left: 0px solid #fff !important;
}
.table_stacked2.table th {
  background-color: #585652;
  color: #fff;
  text-align: left;
}
.table_stacked2.table tr:nth-child(even) {
  background-color: #f0eeea;
}
.table_stacked2.table tr:nth-child(odd) {
  background-color: #faf8f4;
}
.table_stacked2.table p {
  margin-bottom: 0;
}

.ynImgTable .table_stacked2.table tr td {vertical-align: middle;}

.ynImgTable .table_stacked2 img {
  height: 11px;
  width: auto !important;
}


@media screen and (min-width: 600px) and (max-width: 1199px) {

/*MASTHEAD*/
.masthead-wrapper .font-Charlie-Med-p p {font-size: 22px; width:50%;}
	
}

@media screen and (min-width: 768px) {
	.channel-news-insights-wrapper .media.news-insights-panel>.media-body h4, .channel-news-insights-wrapper .media.news-insights-panel>.media-body p {height: inherit !important;}
	
	.channel-news-insights-wrapper .media.news-insights-panel>.media-body {min-height: 280px;}
	
}


@media screen and (max-width: 992px) {
  .megamenu-primary-nav li:nth-child(3) .megamenu-col:nth-child(2) {display: none;}
	  
  .megamenu-cta li:nth-child(3) .megamenu-col:nth-child(2) {display: none;}  
	
}

@media screen and (max-width: 991px) {
  .wrapper-bg-faf8f4.nav-right {
    display: block;
  }
}
@media screen and (min-width: 768px) {
  .table_stacked1.table td,
  .table_stacked1.table th {
    width: 50%;
  }
  /*.table_stacked2.table td, .table_stacked1.table th {width:50%;}*/
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .pageLinksDiv:last-child {
    padding-left: 20px;
    padding-right: 0px;
  }
  .pageLinksDiv:first-child {
    padding-right: 20px;
  }
	.pageNavRightWrapper {padding-left: 20px; padding-right: 10px;}
	

.wrapper-rethub .overview-teaser-col {height:270px}
	
	
/*Overview thumbnails */
	.thumbnails-overview  {padding-bottom:100px;}

.thumbnails-overview .overview-teaser-col {border-bottom:none;}

	
}


@media screen and (max-width: 773px) {
  .wrapper-rethub .overview-teaser-wrapper .overview-teaser-col img,
  .wrapper-rethub .overview-teaser-wrapper .overview-teaser-col p {
    display: inherit;
  }
  .overview-teaser-wrapper .overview-teaser-col h4 {
    font-size: 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 773px) {
	.overview-teaser-wrapper .overview-teaser-col {border-bottom: none;}
}


@media screen and (max-width: 767px) {
  .pageLinksDiv {
    padding-left: 20px;
    padding-right: 20px;
  }
  .intro-icn img {
    margin-right: inherit;
  }
  /*Tiles*/
  .wrapper-rethub .overview-teaser-wrapper .overview-teaser-col img,
  .wrapper-rethub .overview-teaser-wrapper .overview-teaser-col p {
    display: inherit;
  }
  /*Table stacked one */
	
	.wrapper-rethub .table_stacked1.table tr td, .wrapper-rethub .table_stacked1.table tr th {border-bottom: 0 !important;}
	
	
  .table_stacked1.table {
    margin: 0 auto;
    width: 100%;
    border-spacing: 0;
  }
  .table_stacked1.table thead {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
  }
  .table_stacked1.table tbody,
  .table_stacked1.table tr,
  .table_stacked1.table th,
  .table_stacked1.table td {
    display: block;
    padding: 0;
    text-align: left;
    white-space: normal;
  }
  .table_stacked1.table tr td,
  .table_stacked1.table tr th {
    padding: 2em 1em;
    vertical-align: middle;
    overflow: hidden;
    position: relative;
    vertical-align: top;
    border: 1px solid #EDF0F1;
    border-top: none;
    width: 100%;
    white-space: inherit !important;
  }
  .table_stacked1.table th[scope="row"] {
    width: 100%;
    text-align: center;
    display: block;
    background-color: #2e82b3;
    margin: 0 auto;
    padding: .5em 0;
  }
  .table_stacked1.table td[headers]:before {
    content: attr(headers);
    display: block;
    float: none;
    width: 100%;
    color: #3b3b3b;
    font-weight: bold;
    text-align: left;
    margin-bottom: 5px;
  }
  .table_stacked1.table td[headers]:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    border-right: 0px solid #EDF0F1;
    padding-bottom: 200%;
    display: block;
  }
  .table_stacked1 .center {
    width: 100%;
  }
  .table_stacked1.table th {
    display: none;
  }
  .table_stacked1 tr {
    margin-bottom: 20px;
    border: 0 !important;
  }
  .table_stacked1.table tr:nth-child(even) {
    background-color: #faf8f4;
  }
  .table_stacked1.table tr:nth-child(odd) {
    background-color: #f0eeea;
  }
  .table_stacked1.table td,
  .table_stacked1.table th {
    border: 0 solid #fff !important;
    padding: 15px 20px!important;
  }
  .table_stacked1.table td:nth-child(2) {
    padding-top: 0 !important;
  }
  /*Table stacked two */
  .table > tbody > tr > td,
  .table > tbody > tr > th,
  .table > tfoot > tr > td,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > thead > tr > th {
    width: 15%;
  }
  .table_stacked2.table {
    margin: 0 auto;
    width: 100%;
    border-spacing: 0;
  }
  .table_stacked2.table thead {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
  }
  .table_stacked2.table tbody,
  .table_stacked2.table tr,
  .table_stacked2.table th,
  .table_stacked2.table td {
    display: block;
    padding: 0;
    text-align: left;
    white-space: normal;
  }
  .table_stacked2.table tr td,
  .table_stacked2.table tr th {
    padding: 2em 1em;
    vertical-align: middle;
    overflow: hidden;
    position: relative;
    vertical-align: top;
    border: 1px solid #EDF0F1;
    border-top: none;
    width: 100%;
    white-space: inherit !important;
  }
  .tableTot .table_stacked2.table tr td.tdTot {
    width: 50%;
    float: left;
    background-color: #fff;
    color: #3b3b3b;
  }
  .table_stacked2.table th[scope="row"] {
    width: 100%;
    text-align: center;
    display: block;
    background-color: #2e82b3;
    margin: 0 auto;
    padding: .5em 0;
  }
  .miscTable .table_stacked2.table tr th[scope="row"] {
    background-color: red;
  }
  .miscTable .table_stacked2.table tr th[scope="row"] {
    background-color: #585652 !important;
    color: #fff !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .table_stacked2.table td[headers]:before {
    content: attr(headers);
    display: block;
    float: left;
    width: 50%;
    color: #3b3b3b;
    font-weight: bold;
    text-align: left;
    margin-bottom: 5px;
  }
  .table_stacked2.table td[headers] > * {
    display: block;
    width: 50%;
    float: right;
    clear: right;
    padding-left: 1em;
    margin-top: 0;
  }
  .table_stacked2.table td[headers]:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    border-right: 0px solid #EDF0F1;
    /*padding-bottom: 200%;*/
    display: block;
  }
  .table_stacked2 .center {
    width: 100%;
  }
  .table_stacked2.table th {
    display: none;
  }
  .table_stacked2 tr {
    margin-bottom: 20px;
    border: 0 !important;
  }
  .table_stacked2.table td:nth-child(odd) {
    background-color: #faf8f4;
  }
  .table_stacked2.table td:nth-child(even) {
    background-color: #f0eeea;
  }
  .table_stacked2.table td:nth-child(1) {
    background-color: #585652;
    color: #fff;
  }
  .table_stacked2.table td[headers]:nth-child(1):before {
    color: #fff;
  }
  .table_stacked2.table td,
  .table_stacked2.table th {
    padding: 15px 20px!important;
  }
  .table_stacked2.table td {
    border-bottom: 1px solid #fff !important;
  }
  .ynImgTable .table_stacked2 img {
    margin-right: 25% !important;
    margin-top: 5px !important;
  }
  .grayDivImg img {
    margin-bottom: 30px;
  }
  .noheight {
    border-bottom: 1px solid #c8c6c2 !important;
    height: auto !important;
  }
  .noheight:last-child {
    border-bottom: none !important;
  }
	
	.intro-icn img {margin-top:-7px}
	
	.channel-news-insights-wrapper .media.news-insights-panel>.media-body {min-height: inherit;}
	
	
	.thumbnails-overview  {padding-bottom:30px;}

.thumbnails-overview .overview-teaser-col {border-bottom:none;}
	
	
}

@media screen and (max-width: 599px) {
	
.lg-Heading h1, .lg-Heading h2, .lg-Heading h3, .lg-Heading h4, .md-Heading h1, .md-Heading h2, .md-Heading h3, .md-Heading h4, .sm-Heading h1, .sm-Heading h2, .sm-Heading h3, .sm-Heading h4, .xs-Heading h1, .xs-Heading h2, .xs-Heading h3, .xs-Heading h4 {margin-top: 0 !important;}
	

/*MASTHEAD*/
.masthead-wrapper .font-Charlie-Med-p p { color: #fff;line-height: 1.2;width: 90%;font-size: 13px;margin-left: 2%;margin-bottom: 15px;}
	
}


@media screen and (min-width: 480px) and (max-width: 767px) {
  .grayDivImg img {
    max-width: 200px;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 479px) {
  .hasBtLeft .button-sm {
    width: 100%;
  }
}