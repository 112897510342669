
@font-face {
  font-family:"Charlie-Std-SemiBold";
  src:
    url("../resources/fonts/charlie/Charlie-Std-SemiBold.eot?iefix") format("eot"),
    url("../resources/fonts/charlie/Charlie-Std-SemiBold.woff") format("woff"),
    url("../resources/fonts/charlie/Charlie-Std-SemiBold.ttf") format("truetype"),
    url("../resources/fonts/charlie/Charlie-Std-SemiBold.svg") format("svg");
}
@font-face {
  font-family:"Charlie-Std-SemiBold-Italic";
  src:
    url("../resources/fonts/charlie/Charlie-Std-SemiBold-Italic.eot?iefix") format("eot"),
    url("../resources/fonts/charlie/Charlie-Std-SemiBold-Italic.woff") format("woff"),
    url("../resources/fonts/charlie/Charlie-Std-SemiBold-Italic.ttf") format("truetype"),
    url("../resources/fonts/charlie/Charlie-Std-SemiBold-Italic.svg") format("svg");
}
@font-face {
	font-family:"Charlie-Std-Regular";
	src:
	  url("../resources/fonts/charlie/Charlie-Std-Regular.eot?iefix") format("eot"),
	  url("../resources/fonts/charlie/Charlie-Std-Regular.woff") format("woff"),
	  url("../resources/fonts/charlie/Charlie-Std-Regular.ttf") format("truetype"),
	  url("../resources/fonts/charlie/Charlie-Std-Regular.svg") format("svg");
}
@font-face {
  font-family:"Charlie-Std-Regular-Italic";
  src:
    url("../resources/fonts/charlie/Charlie-Std-Regular-Italic.eot?iefix") format("eot"),
    url("../resources/fonts/charlie/Charlie-Std-Regular-Italic.woff") format("woff"),
    url("../resources/fonts/charlie/Charlie-Std-Regular-Italic.ttf") format("truetype"),
    url("../resources/fonts/charlie/Charlie-Std-Regular-Italic.svg") format("svg");
}
@font-face {
  font-family:"Charlie-Std-Medium";
  src:
    url("../resources/fonts/charlie/Charlie-Std-Medium.eot?iefix") format("eot"),
    url("../resources/fonts/charlie/Charlie-Std-Medium.woff") format("woff"),
    url("../resources/fonts/charlie/Charlie-Std-Medium.ttf") format("truetype"),
    url("../resources/fonts/charlie/Charlie-Std-Medium.svg") format("svg");
}

@font-face{
	font-family:"glyphicons-halflings";
	src:url("../resources/fonts/glyphicons/glyphicons-halflings-regular.eot?#iefix");
	src:url("../resources/fonts/glyphicons/glyphicons-halflings-regular.eot?#iefix") format("eot"),
	url("../resources/fonts/glyphicons/glyphicons-halflings-regular.woff") format("woff"),
	url("../resources/fonts/glyphicons/glyphicons-halflings-regular.ttf") format("truetype"),
	url("../resources/fonts/glyphicons/glyphicons-halflings-regular.svg#glyphicons-halflings-regular") format("svg");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'icomoon';
	src:url('../resources/fonts/icomoon/icomoon.eot?-xmm1dw');
	src:url('../resources/fonts/icomoon/icomoon.eot?#iefix-xmm1dw') format('embedded-opentype'),
		url('../resources/fonts/icomoon/icomoon.woff?-xmm1dw') format('woff'),
		url('../resources/fonts/icomoon/icomoon.ttf?-xmm1dw') format('truetype'),
		url('../resources/fonts/icomoon/icomoon.svg?-xmm1dw#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
src: url("../resources/fonts/nab/NABImpact.ttf")format("truetype"),
  url("../resources/fonts/nab/NABImpact.woff")format("woff"),
  url("../resources/fonts/nab/NABImpact.svg")format("svg");
font-family: 'NabImpact';
}

@font-face {
src: url("../resources/fonts/nab/NAB-Script.ttf") format("truetype"),
    url("../resources/fonts/nab/NAB-Script.woff") format("woff"),
    url("../resources/fonts/nab/NAB-Script.svg") format("svg");
font-family: 'NabScript';
}

@font-face {
  font-family:CorpidLight;
  src:
    url("../resources/fonts/corpid/CorpidC1_3.eot?iefix") format("eot"),
    url("../resources/fonts/corpid/CorpidC1_3.woff") format("woff"),
    url("../resources/fonts/corpid/CorpidC1_3.ttf") format("truetype"),
    url("../resources/fonts/corpid/CorpidC1_3.svg") format("svg");
}
@font-face {
  font-family:CorpidHeavy;
  src:
    url("../resources/fonts/corpid/CorpidC1_8.eot?iefix") format("eot"),
    url("../resources/fonts/corpid/CorpidC1_8.woff") format("woff"),
    url("../resources/fonts/CorpidC1_8.ttf") format("truetype"),
    url("../resources/fonts/corpid/CorpidC1_8.svg") format("svg");
}

@font-face {
	font-family: 'Open Sans';
	src: url('../resources/fonts/opensans/OpenSans-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../resources/fonts/opensans/OpenSans-Italic.ttf') format('truetype');
	font-style: italic;
}


@font-face {
	font-family: 'Open Sans';
	src: url('../resources/fonts/opensans/OpenSans-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../resources/fonts/opensans/OpenSans-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Merriweather';
	src: url('../resources/fonts/merriweather/Merriweather-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Merriweather';
	src: url('../resources/fonts/merriweather/Merriweather-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Merriweather';
	src: url('../resources/fonts/merriweather/Merriweather-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Merriweather';
	src: url('../resources/fonts/merriweather/Merriweather-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-mlc-hamburger-menu:before {
	content: "\e600";
}

.icon-mlc-mobile_sidekick:before {
	content: "\e601";
}

.icon-mlc-link:before {
	content: "\e602";
}

.icon-mlc-video:before {
	content: "\e603";
}

.icon-mlc-close:before {
	content: "\e604";
}

.icon-mlc-chevron:before {
	content: "\e605";
}

.icon-mlc-cross:before {
	content: "\e606";
}

.icon-mlc-calculators:before {
	content: "\e607";
}

.icon-mlc-find-your-super:before {
	content: "\e608";
}

.icon-mlc-easy-order:before {
	content: "\e609";
}

.icon-mlc-locate-an-advice-centre:before {
	content: "\e60a";
}

.icon-mlc-news-and-insights:before {
	content: "\e60b";
}

.icon-mlc-dot:before {
	content: "\e60c";
}

.icon-mlc-document:before {
	content: "\e60d";
}

.icon-mlc-what-is-stronger-super:before {
	content: "\e60e";
}

.icon-mlc-login-to-secure-site:before {
	content: "\e60f";
}

.icon-mlc-locate-an-adviser:before {
	content: "\e610";
}

.icon-mlc-partnering-with-us:before {
	content: "\e611";
}

.icon-mlc-ask-a-question-live-chat:before {
	content: "\e612";
}

.icon-mlc-need-advice:before {
	content: "\e613";
}

.icon-mlc-find-and-locate-an-adviser:before {
	content: "\e614";
}

.icon-mlc-have-an-adviser-call-you:before {
	content: "\e615";
}

.icon-mlc-case-study:before {
	content: "\e616";
}

.icon-mlc-prices-and-performances:before {
	content: "\e617";
}

.icon-mlc-best-doctors-login:before {
	content: "\e618";
}

.icon-mlc-call-contactus:before {
	content: "\e619";
}

.icon-mlc-insurance-for-you:before {
	content: "\e61a";
}

.icon-mlc-get-advice-on-your-insurance:before {
	content: "\e61b";
}

.icon-mlc-best-doctors-hotline:before {
	content: "\e61c";
}

.icon-mlc-insurance-for-your-family:before {
	content: "\e61d";
}

.icon-mlc-insurance-for-your-business:before {
	content: "\e61e";
}

.icon-mlc-open-an-mlc-super-account:before {
	content: "\e61f";
}

.icon-mlc-choose-the-right-investment:before {
	content: "\e620";
}

.icon-mlc-logout-of-securesite:before {
	content: "\e621";
}

.icon-mlc-register-for-online-access:before {
	content: "\e622";
}

.icon-mlc-employer-central:before {
	content: "\e623";
}

.icon-mlc-investment-protection:before {
	content: "\e624";
}

.icon-mlc-transfer-your-uk-pension:before {
	content: "\e625";
}

.icon-mlc-government-co-contributions:before {
	content: "\e626";
}

.icon-mlc-spousal-contributions:before {
	content: "\e627";
}

.icon-mlc-salary-sacrifice:before {
	content: "\e628";
}

.icon-mlc-tools:before {
	content: "\e629";
}

.icon-mlc-important-information:before {
	content: "\e62a";
}

.icon-mlc-arrow:before {
	content: "\e62b";
}

.icon-mlc-email-us:before {
	content: "\e62c";
}

.icon-mlc-open-a-super-account:before {
	content: "\e62d";
}

.icon-mlc-write-to-us:before {
	content: "\e62e";
}

.icon-mlc-compliant-resolutions:before {
	content: "\e62f";
}

.icon-mlc-other-contact-info:before {
	content: "\e630";
}

.icon-mlc-featured:before {
	content: "\e631";
}

.icon-mlc-diy:before {
	content: "\e632";
}

.icon-mlc-changing-jobs:before {
	content: "\e633";
}

.icon-mlc-starting-out:before {
	content: "\e634";
}

.icon-mlc-income-protection:before {
	content: "\e635";
}

.icon-mlc-retirement:before {
	content: "\e636";
}

.icon-mlc-invest-personal-assets:before {
	content: "\e637";
}

.icon-mlc-consolidate-your-super:before {
	content: "\e638";
}

.icon-mlc-my-super-is-coming:before {
	content: "\e639";
}

.icon-mlc-governance:before {
	content: "\e63a";
}

.icon-mlc-my-super:before {
	content: "\e63b";
}

.icon-mlc-super-stream:before {
	content: "\e63c";
}

.icon-mlc-newsletter-articles:before {
	content: "\e63d";
}

.icon-mlc-client-asn-referral-letters:before {
	content: "\e63e";
}

.icon-mlc-campaign-planning:before {
	content: "\e63f";
}

.icon-mlc-video-presentations:before {
	content: "\e640";
}

.icon-mlc-strategy-guide:before {
	content: "\e641";
}

.icon-mlc-presentations:before {
	content: "\e642";
}

/*
This CSS resource incorporates links to font software which is the valuable copyrighted
property of Monotype Imaging and/or its suppliers. You may not attempt to copy, install,
redistribute, convert, modify or reverse engineer this font software. Please contact Monotype
Imaging with any questions regarding Web Fonts:  http://webfonts.fonts.com
*/