// MLC Warmup Campaign START
.merriweather {
    font-family: @font-family-merriweather;
}

.mlc-gold {
    color: @mlc-brand-primary;
}

.btn-gold-round, .btn-lightgrey-round {
    padding: 14px 24px;
    border-radius: 100px;
    display: inline-block;
    font-family: @font-family-open-sans;
}

.btn-gold-round {
    background-color: @mlc-brand-btn-primary;
    color: @mlc-brand-btn-primary-text;
    &:hover {
        background-color: @mlc-brand-btn-primary-hover;
    }
}

.btn-lightgrey-round {
    color: @mlc-brand-btn-secondary;
    border: 1px solid @mlc-brand-btn-secondary-border;
    &:hover {
        background-color: @mlc-brand-btn-secondary-hover;
    }
}

.bg-gold {
    background-color: @mlc-brand-primary;
}

.bg-lightgrey {
    background-color: @mlc-brand-background-level-0;
}

// Page level selector
.mlc-warmup-campaign {
    .row-body, .row-footer {
        font-family: @font-family-open-sans;
        h1, h2, h3, h4, h5 {
            font-family: @font-family-open-sans;
        }

    }
}
  // MLC Warmup Campaign END